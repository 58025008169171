import React, { useState } from 'react';
import { Modal, 
    View, 
    StyleSheet, 
    Dimensions, 
    Text,
    Pressable } from 'react-native';
import DashGraphMetrics from './DashGraphModal/DashGraphMetrics';

const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;
const responsiveTextSmall = windowWidth * 0.02

const DashMetricModal = (props) => {

    const theme = props.theme
    const visibleModal = props.visible
    const setVisible = props.setVisible
    const mainGraphHandler = props.mainGraphHandler
    const uniqueDimensions = props.uniqueDimensions
    const currentReport = props.currentReport
    const reportIndex = props.reportIndex
    const dashConfig = props.dashConfig
    const currentPanel = props.currentPanel
    const panelNumber = props.panelNumber
    const uniqueMetrics = ['impressions', 'clicks', 'spend', 'cpc', 'regs', 'ftds', 'cpr', 'cpa']


    return (
        <Modal
            visible={visibleModal}
            style={styles.modalParent}
            transparent={true}
            dashConfig={dashConfig}
            >
        <Pressable 
            style={styles.modalContainer}
            onPress={() => setVisible(false)}
            >
            <View style={[styles.modalWindow , {
                backgroundColor : theme.backgroundColor,
                color: theme.color
            }]}>
                <DashGraphMetrics
                    theme={theme}
                    uniqueMetrics={uniqueMetrics}
                    uniqueDimensions={uniqueDimensions}
                    mainGraphHandler={mainGraphHandler}
                    currentMetric={currentPanel}
                    panelNumber={panelNumber}
                    dashConfig={dashConfig}
                    currentReport={currentReport}
                    reportIndex={reportIndex}
                    setVisible={setVisible}
                />

            </View>


        </Pressable>


        </Modal>
    )
}


const styles = StyleSheet.create({
    modalParent: {
        padding: 20,
        borderRadius: 20,
    },
    modalContainer: {
        width: windowWidth * 1,
        height: windowHeight * 1,
        backgroundColor: 'rgba(0, 0, 0, 0.7)',
        alignItems: 'center',
        justifyContent: 'center',
    },
    modalWindow: {
        margin: 10,
        height: '55%',
        width: '32.5%',
        borderColor: 'white',
        borderWidth: 1,
        borderRadius: 15,
        padding: '1%',
        alignItems: 'center',
        justiftContent: 'center',
    }
});


export default DashMetricModal