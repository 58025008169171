import React, { useState, useEffect } from 'react';
import { View , StyleSheet , Dimensions, Text} from 'react-native'
import { useSelector } from 'react-redux';
import AppBarRN from '../Components/AppBarRN';
import TemporaryDrawer from "../Components/Drawer";
import PPCKwPanel from '../PPC/PPCKwPanel';
import s3Kw from '../Data/s3Kw';


const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;

const PPCKwBubble = (props) => {

    const configArray = props.configArray 
    const configHandler = configArray.configHandler
    const clientHandler = configArray.clientHandler
    const clientID = configArray.clientID
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState(null);
    const theme = props.theme
    const homeTitle = "KW Density";
    const role = 'Admin'


    const stateBase = {
        top: false,
        left: false,
        bottom: false,
        right: false,
      };

    const [state, setState] = useState(stateBase);
    const anchor = 'left'

    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
          return;
        }
    
        setState({ ...state, [anchor]: open });

      };

    function handleDrawer (anchor) {

        const stateChange = {
            top: false,
            left: true,
            bottom: false,
            right: false,
          };
          setState(stateChange)
    
    }
  
    async function fetchS3Data() {
        try {
          const data = await s3Kw(configArray);
    
          return data;
        } catch (error) {
          console.error('Error fetching data:', error);
          return null;
        }
      }
    
      useEffect(() => {
        fetchS3Data().then((result) => {
          setData(result);
          setLoading(false);
        });
      }, [clientID]);
    
      if (loading) {
        return (
          <Text>Loading...</Text>
        );
      }
    
    const dataset = data.kw
    const getUniqueValues = (arr, key) => {
        const uniqueSet = new Set(arr.map(item => item[key]));
        return [...uniqueSet];
      };
  
      const uniqueBrands = ["YoBingo"];
      const uniqueChannels = getUniqueValues(dataset, 'channel');
      const uniqueTypes = getUniqueValues(dataset, 'campaign');

    return (
      <View style={[styles.container, {backgroundColor: theme.backgroundColor}]}>
        <AppBarRN 
            handleDrawer={handleDrawer}
            homeTitle={homeTitle}
            theme={theme}
            configHandler={configHandler}
            clientHandler={clientHandler}
        />
        <TemporaryDrawer 
            toggleDrawer={toggleDrawer}
            state={state}
            anchor={anchor}
            theme={theme}
        />
        <PPCKwPanel
            uniqueBrands={uniqueBrands}
            uniqueChannels={uniqueChannels}
            uniqueTypes={uniqueTypes}
            theme={theme}
            data={data}
        />
        </View>
        
    )
}

const styles = StyleSheet.create({
    container: {
      borderWidth: 0,
      borderColor: 'blue',
      paddingTop: 0,
      flexDirection: 'column',
      justifyContent: 'flex-start',
      width: windowWidth,
      height: '100%',
      flex: 1,
    },
})

export default PPCKwBubble