import React from "react";
import { Chart } from "react-google-charts";
import { View, StyleSheet, Dimensions } from 'react-native'

const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;

export function KwTree( props ) {

    const theme = props.theme
    const dataProp = props.data.agg_kw_result

    const options = {
        wordtree: {
          format: "implicit", 
          
        },
        fontName: 'Electrolize',
        colors: [theme.backgroundColor2, "orange", "green"],
        backgroundColor: theme.backgroundColor
      };

    const kwList = dataProp.map((obj) => obj['keyword']);
    const transformSlice = kwList.slice(0, 30)
    const transformedArray = [['Phrases'], ...transformSlice.map(item => [item])];

    function transformKw (data, brand) {
        const filteredData = data.filter((item) => item.kw_type.includes(brand));        
        const kwList = filteredData.map((obj) => obj['keyword']);
        const transformedArray = [['Phrases'], ...kwList.map(item => [item])];     
        const transformSlice = transformedArray.slice(0, 30)

        return transformSlice
    }

    const transformYob = transformKw(dataProp, 'YOB')
    const transformYoc = transformKw(dataProp, 'YOC')
    const transformYos = transformKw(dataProp, 'YOS')
    
    
  return (
    <View style={[styles.container , {backgroundColor : theme.backgroundColor}]}>
    <View style={[styles.containerColumn , {
        borderColor: theme.color,
        borderWidth: 1
    }]}>
    <Chart
      chartType="WordTree"
      width='100%'
      height='100%'
      data={transformYob}
      options={options}
    />
    </View>
    <View style={[styles.containerColumn , {
        borderColor: theme.color,
        borderWidth: 1
    }]}>
    <Chart
      chartType="WordTree"
      width='100%'
      height='100%'
      data={transformYoc}
      options={options}
    />
    </View>
    <View style={[styles.containerColumn , {
        borderColor: theme.color,
        borderWidth: 1
    }]}>
    <Chart
      chartType="WordTree"
      width='100%'
      height='100%'
      data={transformYos}
      options={options}
    />
    </View>
    </View>
  );
}

const styles = StyleSheet.create({
    container: {
      borderWidth: 0,
      borderColor: 'blue',
      paddingTop: 0,
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      height: '95%',

    },
    containerColumn: {
        width: '33%',
        height: '100%',
        borderWidth: 0,
        borderColor: 'blue',
    }
})
