import React, { useEffect } from 'react';
import { Modal, 
    View, 
    StyleSheet, 
    Dimensions, 
    Text,
    Pressable } from 'react-native';
import WebFont from 'webfontloader';
import OptionsModal from '../Options/OptionsModal';

const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;

const ModalTheme = (props) => {

    const modalHandler = props.modalHandler
    const visibleModal = props.visibleModal
    const configHandler = props.configHandler
    const theme = props.theme


    return (
        <Modal
            visible={visibleModal}
            style={styles.modalParent}
            transparent={true}
            >
        <Pressable 
            style={styles.modalContainer}
            onPress={() => modalHandler(false)}
            >
            <View style={[styles.modalWindow , {
                backgroundColor : theme.backgroundColor2
                }]}>

                <OptionsModal 
                    style={{flex: 1}}
                    theme={theme}
                    configHandler={configHandler}
                    modalHandler={modalHandler}
                    >
                    

                </OptionsModal>

            </View>


        </Pressable>


        </Modal>
    )

}

const styles = StyleSheet.create({
    modalParent: {
        padding: 20,
        borderRadius: 20,
    },
    modalContainer: {
        justifyContent: 'center',
        alignItems: 'center',
        width: windowWidth * 1,
        height: windowHeight * 1,
        backgroundColor: 'rgba(0, 0, 0, 0.3)',
    },
    modalWindow: {
        margin: 10,
        height: '30%',
        width: '25%',
        borderColor: 'white',
        borderWidth: 1,
        borderRadius: 15,
        padding: '1%',
        alignItems: 'center',
        justiftContent: 'center'
    }
});


export default ModalTheme