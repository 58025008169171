import React, { useRef , useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import Chart from 'chart.js/auto';
import WebFont from 'webfontloader';

const LineChart = (props) => {
  const chartRef = useRef(null);
  const dataProp = props.dataProp
  const labels = props.labels
  const timeFrame = props.timeFrame
  const theme = props.theme

  useEffect(() => {
    WebFont.load({
      google: {
        families: ['Electrolize']
      }
    });
   }, []);


  const data = {
    labels: labels,
    datasets: [
      {
        label: 'Line Chart',
        data: dataProp,
        fill: true,
        borderColor: theme.backgroundColor2,
        backgroundColor: theme.backgroundColor2+30,
        lineTension: 0.5,
        radius: 0
      },
    ],
  };

  const options = {
    scales: {
      y: {
        beginAtZero: true,
        grid: {
          display: false, // Remove the gridlines on the y-axis
        },
        ticks: {
          color : theme.color,
          font: {
            size: 10, // Set the font size for y-axis labels
            weight: 'bold', // Set the font weight for y-axis labels
            family : 'Electrolize',
          },
        },
      },
      x: {
        grid: {
          display: false, // Remove the gridlines on the x-axis
        },
        ticks: {
          color : theme.color,
          font: {
            size: 12, // Set the font size for x-axis labels
            family : 'Electrolize'
          },
        },
      },
    },
    plugins: {
      legend: {
        display: true,
        text: 'Chart Title',
        labels: {
          color : theme.color,
          // This more specific font property overrides the global property
          font: {
              size: 12,
              family : 'Electrolize'
          }
      }
      },
      tooltip: {
        enabled: false,
      },
      title: {
        display: false,
        text: 'Chart Title',
        font: {
          size: 24, // Set the font size for the chart title
          weight: 'bold', // Set the font weight for the chart title
  
        },
      },
    },
  };

  return <Line ref={chartRef} data={data} options={options} />;
};

export default LineChart;
