import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import clsx from 'clsx';
import { DataGrid, 
    GridToolbarContainer,
    GridToolbarColumnsButton,
    GridToolbarFilterButton,
    GridToolbarDensitySelector,
    GridToolbarExport 
   } from '@mui/x-data-grid';
import { Dimensions } from 'react-native'
import WebFont from 'webfontloader';
const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;

export default function AnalyticsDataGrid(props) {

    const dataProp = props.dataProp
    const colNum = props.colNum
    const columnWidth = props.columnWidth
    const theme = props.theme
    const currentFilter = 'PPC'


    const [columnVisibilityModel, setColumnVisibilityModel] = React.useState({
        id: false,
        clicks: false,
        impressions: false,
        regs: true,
        channel: true,
        reg_start: false,
        spend: false,
        cpa: false
    });

    useEffect(() => {
        WebFont.load({
            google: {
                families: ['Electrolize']
            }
        });
    }, []);

    let uniqueIdCounter = 1;

    // Use forEach to add a unique ID to each record
    dataProp.forEach((record) => {
        record.id = uniqueIdCounter++;
    });

    const labels = Object.keys(dataProp[0]).map((key, index) => {
        return {
            field: key,
            headerName: key,
            minWidth: windowWidth * columnWidth * 1.25,
            flex: 1,
            headerAlign: 'center',
            headerClassName: 'table-header',
            align: 'center',
            renderCell: (params) => {
                const { value, field } = params;
                const heatmapColor = calculateHeatmapColor(value, { field });
                if (index <= 0) {
                    return (
                        <div
                            className="heatmap-cell"
                            style={{
                                backgroundColor: heatmapColor,
                                padding: '10%',
                                color: theme.color,
                                width: '100%'
                            }}
                        >
                            {value}
                        </div>
                    );
                }

                return (
                    <div
                        className="heatmap-cell"
                        style={{
                            backgroundColor: heatmapColor,
                            padding: '10%',
                            width: '100%'

                        }}
                    >
                        {value}
                    </div>
                );
            },

        };
    });

    const columnRanges = {};
    Object.keys(dataProp[0]).forEach((column) => {
        const values = dataProp.map((record) => record[column]);
        const min = Math.min(...values);
        const max = Math.max(...values);
        columnRanges[column] = { min, max };
    });

    function calculateHeatmapColor(value, column) {
        const { min, max } = columnRanges[column.field];
        const range = max - min;

        // Define input colors for the heatmap in RGB format
        const fromColor = { r: theme.comboStopColor1ARRAY[0], g: theme.comboStopColor1ARRAY[1], b: theme.comboStopColor1ARRAY[2] };
        const toColor = { r: theme.comboStopColor2ARRAY[0], g: theme.comboStopColor2ARRAY[1], b: theme.comboStopColor2ARRAY[2] };;
      


        const relativeValue = (value - min) / range;
        const red = Math.round((1 - relativeValue) * fromColor.r + relativeValue * toColor.r);
        const green = Math.round((1 - relativeValue) * fromColor.g + relativeValue * toColor.g);
        const blue = Math.round((1 - relativeValue) * fromColor.b + relativeValue * toColor.b);

        return `rgba(${red},${green},${blue},0.7)`;
    }

    function CustomToolbar() {
        return (
          <GridToolbarContainer sx={{color: theme.color}}>
            <GridToolbarColumnsButton sx={{color: theme.color}}/>
              <GridToolbarFilterButton sx={{color: theme.color}}/>
              <GridToolbarDensitySelector sx={{color: theme.color}}/>
              <GridToolbarExport sx={{color: theme.color}}/>
          </GridToolbarContainer>
        );
      }

    return (
        <Box
            style={{
                width: '100%',
                fontSize: 50,
                color: 'black',
                fontFamily: 'Electrolize'

            }}
            sx={{
                '& .table-header': { 
                headerAlign: 'center', 
                align : 'center' , 
                fontFamily : 'Electrolize' , 
                color: theme.color, 
                fontSize: 25, },
                width: '100%'
            }}
        >
            <DataGrid
                rows={dataProp}
                columns={labels}

                slots={{ toolbar: CustomToolbar  }}
                columnVisibilityModel={columnVisibilityModel}
                onColumnVisibilityModelChange={(newModel) =>
                    setColumnVisibilityModel(newModel)
                }
                initialState={{
                    pagination: {
                        paginationModel: { pageSize: 10 }
                    },
                    
                    sorting: {
                        sortModel: [{ field: 'ftds', sort: 'desc' }],
                    },
                }}

            />
        </Box>
    );
}