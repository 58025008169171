import React, { useState, useEffect } from 'react';
import {
    View,
    StyleSheet,
    Dimensions,
    Text,
} from 'react-native'
import WebFont from 'webfontloader';
import moment from 'moment-timezone';
import parseBudgetFunction from '../Data/parseBudgetFunction'
import BudgetPanel from '../Budgets/BudgetPanel';


const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;

const BudgetPanelV2 = (props) => {

    const data = props.data.budget
    const theme = props.theme
    const currentDate2 = moment.tz('Europe/Malta');
    const yesterday2 = currentDate2.clone().subtract(1, 'day');
    const yesterdayProp = yesterday2.format('YYYY-MM-DD');
    const homeTitle = "Daily Summary";
    const uniqueBrands = props.uniqueBrands
    const uniqueChannels = props.uniqueChannels
    const uniqueTypes = props.uniqueTypes

    const currentDate = new Date();

    // Set the timezone offset to 0 to work with local time
    currentDate.setMinutes(0);
    const previousDate = new Date(currentDate);
    previousDate.setDate(currentDate.getDate() - 1);
    previousDate.setDate(1)

    // Get the start date (e.g., the beginning of the current month)
    const startDate = new Date(previousDate.getFullYear(), previousDate.getMonth(), 1);

    // Get the end date (e.g., the end of the current month)
    const endDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);

    // Convert the start and end dates to strings in YYYY-MM-DD format
    const startDateString = previousDate.toISOString().slice(0, 10);
    const endDateString = endDate.toISOString().slice(0, 10);

    const filterBase = {
      start: startDateString,
      end: yesterdayProp,
      channel: "Total",
      brand: 'Total',
      kw_type: "Total",
    };

    const [filters, setFilters] = useState(filterBase);

    var dataset = parseBudgetFunction(data, filters)

    const uniqueData = data
    const agg_daily_result = dataset.agg_daily_result
    const agg_type_result = dataset.agg_type_result
    const agg_brand_result_raw = dataset.agg_brand_result


    const getUniqueValues = (arr, key) => {
        const uniqueSet = new Set(arr.map(item => item[key]));
        return [...uniqueSet];
      };
    
    const barBrands = getUniqueValues(agg_brand_result_raw, 'brand');
    const barChannels = getUniqueValues(uniqueData, 'channel');
    const barTypes = getUniqueValues(uniqueData, 'kw_type');
    console.log(uniqueBrands)

    const agg_brand_result = agg_brand_result_raw.filter(item => item.brand !== "");

    


    const dataProp = {
        agg_daily_result,
        agg_type_result,
        agg_brand_result,
        uniqueBrands,
        uniqueChannels,
        uniqueTypes,
      };

      const propObjectBase = {
        data: dataProp,
        start: startDateString,
        end: yesterdayProp,
        theme: theme,
        brand: "Total",
        channel: "Total",
        kw_type: "Total",
      };
    
      const [propObject, setpropObject] = useState(propObjectBase);

      const handleStateChange = (prop) => {

        setpropObject(prop);
  
        const filterUpdate = {
            start: prop.start,
            end: prop.end,
            channel: prop.channel,
            brand: prop.brand,
            kw_type: prop.kw_type,
          };
  
        setFilters(filterUpdate)
  
      };

      const dateLabels = agg_daily_result.map((obj) => {
        const date = new Date(obj['calendar_date']);
        return date.getDate().toString();
      });


    useEffect(() => {
        WebFont.load({
            google: {
                families: ['Droid Sans', 'Electrolize']
            }
        });
    }, []);

    const panelMain = {

        width: windowWidth * 1,
        height: windowHeight * 0.93,
        borderWidth: 0.1,
        borderColor: 'grey',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        fontSize: 40,
        fontFamily: 'Electrolize',
        borderRadius: 5
    }

    return (
        <View style={styles.container}>

            <View style={{width : '100%' , height : '100%'}}>
            <BudgetPanel 
                style={{flex : 1 }}
                theme={theme}
                dataProp={dataProp}
                dateLabels={dateLabels}
                propObject={propObject}
                handleStateChange={handleStateChange}
                setHomeTitle={homeTitle}
                timeFrame={"Daily"}
            />
            </View>           
        </View>

    )

}

export default BudgetPanelV2

const styles = StyleSheet.create({
    container: {
        borderWidth: 0,
        borderColor: 'blue',
        height: windowHeight *0.93,
        width: '100%',
        flexDirection: 'column',
        fontFamily: 'Electrolize',
    },
    lineChart: {
        flex: 1,
        width: '100%',
        alignItems: 'center',
        padding: '1%',
        fontFamily: 'Electrolize'
    }
})