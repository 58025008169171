export default function DashCubeQuery(cubeFilters, table, start, end, chartType) {

    const startDate = new Date(start);
    const endDate = new Date(end);

    // Calculate the difference in days
    const differenceInDays = Math.abs((endDate - startDate) / (24 * 60 * 60 * 1000));

    // Set 'granularity' based on the difference
    const granularity = differenceInDays > 60 ? 'month' : 'day';

    return {
        "timeDimensions": [
            {
                "dimension": `${table}.timestamp`,
                "granularity" : granularity,
                "dateRange": [
                    start,
                    end
                ]
            }
        ],
        "order": [
            [
                `${table}.spend`,
                "desc"
            ]
        ],
        "measures": [
            `${table}.impressions`,
            `${table}.clicks`,
            `${table}.spend`,
            `${table}.regs`,
            `${table}.ftds`,
        ],
        "dimensions": [
            `${table}.brand`,
            `${table}.channel`,
            `${table}.kw_type`,
            `${table}.date`
        ],
        "limit": 1000,
        "filters": cubeFilters
    }

}