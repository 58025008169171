import React, { useState , useEffect } from 'react';
import { View , 
    StyleSheet , 
    Dimensions , 
    Text,
    Pressable
} from 'react-native'
import BarYears from '../ChartsDesktop/BarYears';
import BarChart from '../ChartsDesktop/BarChart';
import MixedChart from '../ChartsDesktop/MixedChart';
import LineYears from '../ChartsDesktop/LineYears';
import WebFont from 'webfontloader';

const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;

const YearlyPanelsChildren = (props) => {

    const dataProp = props.dataProp

    const agg_brand_result = dataProp.agg_brand_result
    const agg_yearly_result = dataProp.agg_yearly_result
    const uniqueBrands = dataProp.uniqueBrands
    const dateLabels = props.dateLabels
    const currentData = dataProp.agg_monthly_result
    const [selectedComponent, setSelectedComponent] = useState("Row1_Line1");
    const theme = props.theme
    const timeFrame = "Yearly"



    const handleComponentClick = (componentName) => {
        setSelectedComponent(componentName);
    };

    useEffect(() => {
        WebFont.load({
          google: {
            families: ['Droid Sans', 'Electrolize']
          }
        });
       }, []);

    const renderComponentContent = () => {
        if (selectedComponent === 'Row1_Line1') {
        return <Row1_Line1 style={panelMain}/>;
        } else if (selectedComponent === 'Row1_Line2') {
        return <Row1_Line2 style={panelMain}/>;
        } else if (selectedComponent === 'Row1_Line3') {
        return <Row1_Line3 style={panelMain}/>;
        } else if (selectedComponent === 'Row2_Bar1') {
        return <Row2_Bar1 style={panelMain}/>;
        } else if (selectedComponent === 'Row2_Mixed1') {
        return <Row2_Mixed1 style={panelMain}/>;
        } else if (selectedComponent === 'Row2_Bar2') {
        return <Row2_Bar2 style={panelMain}/>;
        } else {
        return null;
        }
    };


    const panelMain = {

        width : '100%',
        height : windowHeight * 0.82,
        borderWidth: 0,
        borderColor: 'green',
        alignItems : 'center',
        justifyContent : 'center',
        textAlign : 'center',
        fontSize : 40,
        fontFamily : 'Electrolize',
        borderRadius : 5
    }
    
    const panelChild = {
        width: '33.3333333%',
        height : '100%',
        borderWidth: 0.1,
        borderColor: 'grey',
        alignItems : 'center',
        justifyContent : 'center',
        textAlign : 'center',
        fontSize : 20,
        fontFamily : 'Electrolize',
        borderRadius : 5,
    }


    const Row1_Line1 = () => {

        return (
        <View style={styles.lineChart}>
            <LineYears
            agg_daily_result={currentData}
            dateLabels={dateLabels}
            theme={theme}
            metric={"spend"}
            timeFrame={timeFrame}
        />
        </View>)
    }

    const Row1_Line2 = () => {

        return (
        <View style={styles.lineChart}>
            <LineYears
            agg_daily_result={currentData}
            dateLabels={dateLabels}
            theme={theme}
            metric={"ftds"}
            timeFrame={timeFrame}
        />
        </View>)
    }


    const Row1_Line3 = () => {

        return (
        <View style={styles.lineChart}>
            <LineYears
            agg_daily_result={currentData}
            dateLabels={dateLabels}
            theme={theme}
            metric={"cpa"}
            timeFrame={timeFrame}
        />
        </View>)
    }

    const Row2_Bar1 = () => {
        return(
        <View style={{flex : 1 , width : '100%', alignItems : 'center' , justifyContent : 'center'}}>
            <BarYears
            agg_daily_result={currentData}
            dateLabels={dateLabels}
            theme={theme}
            metric={"spend"}
            timeFrame={timeFrame}
        />
        </View>)
    }

    const Row2_Mixed1 = () => {
        return(
        <View style={{flex : 1 , width : '100%', alignItems : 'center' , justifyContent : 'center'}}>
            <BarYears
            agg_daily_result={currentData}
            dateLabels={dateLabels}
            theme={theme}
            metric={"ftds"}
            timeFrame={timeFrame}
        />
        </View>)
    }

    const Row2_Bar2 = () => {
        return(
            <View style={{flex : 1 , width : '100%', alignItems : 'center' , justifyContent : 'center'}}>
                <BarYears
            agg_daily_result={currentData}
            dateLabels={dateLabels}
            theme={theme}
            metric={"cpa"}
            timeFrame={timeFrame}
        />
            </View>)
        }

    return (
        <View style={styles.container}>
        <View style={{flexDirection : 'row' }}>
        <View style={panelMain}>
        <View style={{width : windowWidth, flexDirection : 'flex-start' , fontFamily : 'Electrolize'}}>
                </View>
                
        {renderComponentContent()}        
        </View>
  
        </View>
        <View style={{flexDirection : 'row', flex : 1 , width : '100%'}}>
        <Pressable style={panelChild} onPress={() => handleComponentClick('Row1_Line1')}>
        <View style={styles.lineChart}>
        <LineYears
            agg_daily_result={currentData}
            dateLabels={dateLabels}
            theme={theme}
            metric={"spend"}
            timeFrame={timeFrame}
        />
        </View>
        </Pressable>
        <Pressable style={panelChild} onPress={() => handleComponentClick('Row1_Line2')}>
        <View style={styles.lineChart}>
        <LineYears
            agg_daily_result={currentData}
            dateLabels={dateLabels}
            theme={theme}
            metric={"ftds"}
            timeFrame={timeFrame}
        />
        </View>
        </Pressable>
        <Pressable style={panelChild} onPress={() => handleComponentClick('Row1_Line3')}>
        <View style={styles.lineChart}>
        <LineYears
            agg_daily_result={currentData}
            dateLabels={dateLabels}
            theme={theme}
            metric={"cpa"}
            timeFrame={timeFrame}
        />
        </View>
        </Pressable>

        </View>
        
        <View style={{flexDirection : 'row', flex : 1, width : '100%'}}>
        <Pressable style={panelChild} onPress={() => handleComponentClick('Row2_Bar1')}>
        <BarYears
            agg_daily_result={currentData}
            dateLabels={dateLabels}
            theme={theme}
            metric={"impressions"}
            timeFrame={timeFrame}
        />
        </Pressable>
        <Pressable style={panelChild} onPress={() => handleComponentClick('Row2_Mixed1')}>
        <BarYears
            agg_daily_result={currentData}
            dateLabels={dateLabels}
            theme={theme}
            metric={"clicks"}
            timeFrame={timeFrame}
        />
        </Pressable>
        <Pressable style={panelChild} onPress={() => handleComponentClick('Row2_Bar2')}>
        <BarYears
            agg_daily_result={currentData}
            dateLabels={dateLabels}
            theme={theme}
            metric={"regs"}
            timeFrame={timeFrame}
        />
        </Pressable>

        </View>
        
        </View>
        
    )
}

export default YearlyPanelsChildren

const styles = StyleSheet.create({
    container: {
      borderWidth: 0,
      borderColor: 'blue',
      height : (windowHeight * 0.93) * 1.5,
      width: '100%',
      flexDirection : 'column',
      fontFamily : 'Electrolize',
      

    },
    lineChart : {
        flex : 1,
        width : '100%',
        alignItems : 'center',
        padding : '1%',
        fontFamily : 'Electrolize'
    },
    lineChart2 : {
        flex : 1,
        width : '100%',
        alignItems : 'center',
        padding : '1%',
        fontFamily : 'Electrolize'
    }
})