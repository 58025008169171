export default function parseAdRgoupFunction(prop, filters) {

    var dataset = prop


    if (!dataset || dataset.length === undefined) {
        return null;
    }

    dataset = dataset.filter((obj) => {
        const date = new Date(obj.calendar_date);
        const startDate = new Date(filters.start);
        const endDate = new Date(filters.end);
        return (
            date >= startDate &&
            date <= endDate &&
            Object.entries(filters)

                .filter(([key, value]) => key !== 'start' && key !== 'end')
                .every(([key, value]) => {
                    if (value === 'Total') {
                        return true;
                    }
                    if (key === 'brand') {
                        return obj[key] === value;
                    }
                    if (key === 'channel') {
                        return obj[key] === value;
                    }
                    if (key === 'kw_type') {
                        return obj[key] === value;
                    }

                    return false;
                })
        );
    });

    const agg_result = dataset


    var agg_daily_result = [];
    dataset.reduce(function (res, value) {
        if (!res[value.calendar_date]) {
            res[value.calendar_date] = {
                calendar_date: value.calendar_date,
                actual_spend: 0,
                budget: 0,
                yesterday_spend: 0,
                ftd: 0,
                days_in_month: parseInt(value.days_in_month),
                current_dom: parseInt(value.current_dom),
                days_remaining: parseInt(value.days_remaining)
            };
            agg_daily_result.push(res[value.calendar_date]);
        }
        res[value.calendar_date].actual_spend += parseInt(value.actual_spend);
        res[value.calendar_date].budget += parseInt(value.budget);
        res[value.calendar_date].ftd += parseInt(value.ftd);
        res[value.calendar_date].yesterday_spend += parseInt(value.yesterday_spend);
        return res;
    }, {});

    agg_daily_result = agg_daily_result.map((obj) => {
        const actual_spend = obj.actual_spend;
        const ftd = obj.ftd;
        const cpa = actual_spend === 0 || ftd === 0 || isNaN(ftd) ? 0 : actual_spend / ftd;
        return {
            ...obj,
            cpa: parseInt(cpa),
        };
    });

    if (!agg_daily_result) {
        return null;
    }

    var agg_channel_result = [];

    dataset.reduce(function (res, value) {
        if (!res[value.channel]) {
            res[value.channel] = {
                channel: value.channel,
                actual_spend: 0,
                budget: 0,
                yesterday_spend: 0,
                ftd: 0,
                days_in_month: parseInt(value.days_in_month),
                current_dom: parseInt(value.current_dom),
                days_remaining: parseInt(value.days_remaining)
            };
            agg_channel_result.push(res[value.channel]);
        }
        res[value.channel].actual_spend += parseInt(value.actual_spend);
        res[value.channel].budget += parseInt(value.budget);
        res[value.channel].ftd += parseInt(value.ftd);
        res[value.channel].yesterday_spend += parseInt(value.yesterday_spend);

        return res;
    }, {});


    agg_channel_result = agg_channel_result.map((obj) => {
        const actual_spend = obj.actual_spend;
        const ftd = obj.ftd;
        const cpa = actual_spend === 0 || ftd === 0 || isNaN(ftd) ? 0 : actual_spend / ftd;
        return {
            ...obj,
            cpa: parseInt(cpa),
        };
    });

    var agg_brand_result = [];

    dataset.reduce(function (res, value) {
        if (!res[value.brand]) {
            res[value.brand] = {
                brand: value.brand,
                actual_spend: 0,
                budget: 0,
                yesterday_spend: 0,
                ftd: 0,
                days_in_month: parseInt(value.days_in_month),
                current_dom: parseInt(value.current_dom),
                days_remaining: parseInt(value.days_remaining)
            };
            agg_brand_result.push(res[value.brand]);
        }
        res[value.brand].actual_spend += parseInt(value.actual_spend);
        res[value.brand].budget += parseInt(value.budget);
        res[value.brand].ftd += parseInt(value.ftd);
        res[value.brand].yesterday_spend += parseInt(value.yesterday_spend);
        return res;
    }, {});

    agg_brand_result = agg_brand_result.map((obj) => {
        const actual_spend = obj.actual_spend;
        const ftd = obj.ftd;
        const cpa = actual_spend === 0 || ftd === 0 || isNaN(ftd) ? 0 : actual_spend / ftd;
        return {
            ...obj,
            cpa: parseInt(cpa),
        };
    });

    var agg_type_result = [];

    dataset.reduce(function (res, value) {
        if (!res[value.kw_type]) {
            res[value.kw_type] = {
                kw_type: value.kw_type,
                channel: value.channel,
                actual_spend: 0,
                budget: 0,
                yesterday_spend: 0,
                ftd: 0,
                days_in_month: parseInt(value.days_in_month),
                current_dom: parseInt(value.current_dom),
                days_remaining: parseInt(value.days_remaining)
            };
            agg_type_result.push(res[value.kw_type]);
        }
        res[value.kw_type].actual_spend += parseInt(value.actual_spend);
        res[value.kw_type].budget += parseInt(value.budget);
        res[value.kw_type].ftd += parseInt(value.ftd);
        res[value.kw_type].yesterday_spend += parseInt(value.yesterday_spend);
        return res;
    }, {});

    agg_type_result = agg_type_result.map((obj) => {
        const actual_spend = obj.actual_spend;
        const ftd = obj.ftd;
        const budget = obj.budget;
        const days_in_month = obj.days_in_month;
        const current_dom = obj.current_dom;
        const cpa = actual_spend === 0 || ftd === 0 || isNaN(ftd) ? 0 : actual_spend / ftd;
        const spend_rate = (actual_spend / budget).toFixed(2)
        return {
            ...obj,
            cpa: parseInt(cpa),
            spend_rate: (isFinite(spend_rate) ? spend_rate : 0)
        };
    });

    return {
        agg_daily_result,
        agg_result,
        agg_brand_result,
        agg_type_result,
        agg_channel_result
    }

}