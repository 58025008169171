import React, { useState, useEffect } from 'react';
import {
    View,
    StyleSheet,
    Dimensions,
    Text,
} from 'react-native'
import YearlyFilters from '../Yearly/YearlyFilters';
import WebFont from 'webfontloader';
import moment from 'moment-timezone';
import parseYearlyFunction from '../Data/parseYearlyFunction';
import YearlyPanelsChildren from './YearlyPanelsChildren';


const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;

const YearlyPanel = (props) => {

    const data = props.data.daily
    const theme = props.theme
    const currentDate2 = moment.tz('Europe/Malta');
    const yesterday2 = currentDate2.clone().subtract(1, 'day');
    const yesterdayProp = yesterday2.format('YYYY-MM-DD');
    const uniqueBrands = props.uniqueBrands
    const uniqueChannels = props.uniqueChannels
    const uniqueTypes = props.uniqueTypes


    const filterBase = {
      start: "2022-07-01",
      end: yesterdayProp,
      channel: "Total",
      brand: 'Total',
      kw_type: "Total",
    };

    const [filters, setFilters] = useState(filterBase);

    var dataset = parseYearlyFunction(data, filters)
    const agg_yearly_result = dataset.agg_yearly_result
    const agg_monthly_result = dataset.agg_monthly_result

    const dataProp = {
        agg_yearly_result,
        agg_monthly_result,
        uniqueBrands,
        uniqueChannels,
        uniqueTypes
      };

      const propObjectBase = {
        data: dataProp,
        start: "2022-07-01",
        end: yesterdayProp,
        theme: theme,
        brand: "Total",
        channel: "Total",
        kw_type: "Total",
      };
    
      const [propObject, setpropObject] = useState(propObjectBase);

      const handleStateChange = (prop) => {

        setpropObject(prop);
  
        const filterUpdate = {
            start: prop.start,
            end: prop.end,
            channel: prop.channel,
            brand: prop.brand,
            kw_type: prop.kw_type,
          };
  
        setFilters(filterUpdate)
  
      };

      const dateLabels = agg_monthly_result.map((obj) => {
        const date = new Date(obj['date']);
        return date.getDate().toString();
      });

    useEffect(() => {
        WebFont.load({
            google: {
                families: ['Droid Sans', 'Electrolize']
            }
        });
    }, []);

    const panelMain = {

        width: windowWidth * 1,
        height: windowHeight * 0.93,
        borderWidth: 0.1,
        borderColor: 'grey',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        fontSize: 40,
        fontFamily: 'Electrolize',
        borderRadius: 5
    }

    return (
        <View style={styles.container}>
        <View style={{width : '100%'}}>
            <YearlyFilters
                            style={{
                                height: '10%',
                                borderColor: 'red',
                                borderWidth: 1,
                                width: windowWidth,
                                fontFamily: 'Electrolize',
                            }}
                            uniqueBrands={uniqueBrands}
                            uniqueChannels={uniqueChannels}
                            uniqueTypes={uniqueTypes}
                            handleStateChange={handleStateChange}
                            propObject={propObject}
                            theme={theme}
                        />
            </View>
            <View style={{width : '100%'}}>
            <YearlyPanelsChildren
                theme={theme}
                dataProp={dataProp}
                dateLabels={dateLabels}
            >
            </YearlyPanelsChildren>   
            </View>           
        </View>

    )

}

export default YearlyPanel

const styles = StyleSheet.create({
    container: {
        borderWidth: 0,
        borderColor: 'blue',
        height: '100%',
        width: '100%',
        flexDirection: 'column',
        fontFamily: 'Electrolize',
    },
    lineChart: {
        flex: 1,
        width: '100%',
        alignItems: 'center',
        padding: '1%',
        fontFamily: 'Electrolize'
    }
})