import React, { useRef , useEffect} from 'react';
import { Line } from 'react-chartjs-2';
import WebFont from 'webfontloader';

const BudgetArea = (props) => {

    const agg_daily_result = props.agg_daily_result || []
    const labels = props.dateLabels    
    const theme = props.theme
    const metric1 = props.metric1 || "actual_spend";
    const metric2 = props.metric2 || "budget";
    const timeFrame = props.timeFrame
    const dailySpend = agg_daily_result.map((obj) => obj[metric1]);
    const dailyBudget = agg_daily_result.map((obj) => obj[metric2]);

    useEffect(() => {
        WebFont.load({
            google: {
                families: ['Electrolize']
            }
        });
    }, []);

    function toProperCase(str) {
        return str.replace(/\w\S*/g, function (txt) {
          return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        });
      }

    const data = {
        labels: labels,
        datasets: [
            {
                label: timeFrame +" " + toProperCase(metric2),
                data: dailyBudget,
                borderColor: theme.color, // Color of the line
                fill: false, // Do not fill the area under the line
                type: 'line', // Use 'line' chart type
                radius: 0,
            },
            {
                label: timeFrame +" " + toProperCase(metric1),
                data: dailySpend,
                fill: true,
                borderColor: theme.backgroundColor2,
                backgroundColor: theme.backgroundColor2+30,
                lineTension: 0.5,
                radius: 0,
                type: 'line', // Use 'bar' chart type to create an area chart
            },
        ],
    };

    const options = {
        scales: {
          y: {
            beginAtZero: true,
            grid: {
              display: false, // Remove the gridlines on the y-axis
            },
            ticks: {
              color : theme.color,
              font: {
                size: 10, // Set the font size for y-axis labels
                weight: 'bold', // Set the font weight for y-axis labels
                family : 'Electrolize',
              },
            },
          },
          x: {
            grid: {
              display: false, // Remove the gridlines on the x-axis
            },
            ticks: {
              color : theme.color,
              font: {
                size: 12, // Set the font size for x-axis labels
                family : 'Electrolize'
              },
            },
          },
        },
        plugins: {
          legend: {
            display: true,
            text: 'Chart Title',
            labels: {
              color : theme.color,
              // This more specific font property overrides the global property
              font: {
                  size: 12,
                  family : 'Electrolize'
              }
          }
          },
          tooltip: {
            enabled: false,
          },
          title: {
            display: false,
            text: 'Chart Title',
            font: {
              size: 24, // Set the font size for the chart title
              weight: 'bold', // Set the font weight for the chart title
      
            },
          },
        },
      };

    return (

        <Line data={data} options={options} />

    )

};

export default BudgetArea;
