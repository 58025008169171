export default function DimensionsCube (table) {

    if (table.includes("_publisher")) {
        return {
            "order": {
                [`${table}.cost`]: "desc",
            },
            "limit": 100,
            "dimensions": [
            `${table}.kw_type`,
            `${table}.brand`,
            `${table}.channel`,
            ],
            "timeDimensions": [
              {
                "dimension": `${table}.timestamp`,
                "dateRange": "This month"
              }
            ]
          }
    } else if (table.includes("_daily")) {
      return {
          "order": {
              [`${table}.cost`]: "desc",
          },
          "limit": 100,
          "dimensions": [
          `${table}.kw_type`,
          `${table}.brand`,
          `${table}.channel`,
          ],
          "timeDimensions": [
            {
              "dimension": `${table}.timestamp`,
              "dateRange": "This month"
            }
          ]
        }
    } return {
        "order": {
          "rank_attribution_export.date": "asc"
        },
        "limit": 50,
        "dimensions": [
          "rank_attribution_export.kw_type",
          "rank_attribution_export.brand",
          "rank_attribution_export.channel"
        ],
        "timeDimensions": [
          {
            "dimension": "rank_attribution_export.timestamp",
            "dateRange": "This month"
          }
        ]
      }
}