import React, {useEffect} from 'react';
import { Dimensions } from 'react-native'
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import WebFont from 'webfontloader';

const windowWidth = Dimensions.get('window').width;

export default function FilterChannel(props) {
  const [age, setAge] = React.useState('');
  const uniqueChannelsRaw = props.uniqueChannels
  const total = "Total"
  const uniqueChannels = [total, ...uniqueChannelsRaw];
  const filterWidth = windowWidth * 0.666 * 0.20
  const handleStateChange = props.handleStateChange
  const propObject = props.propObject
  const theme = props.theme

  useEffect(() => {
    WebFont.load({
      google: {
        families: ['Droid Sans', 'Electrolize']
      }
    });
   }, []);

  const handleChange = (event) => {
    setAge(event.target.value);
    handleUpdate(event.target.value)
  };

  const handleUpdate = (prop) =>{
  
  const propUpdate = {
    start: propObject.start,
    end: propObject.end,
    channel: prop,
    brand: propObject.brand,
    kw_type: propObject.kw_type,
  }

  handleStateChange(propUpdate)

}

  return (
    <Box sx={{ width: '100%' , height : '100%' , fontFamily : 'Electrolize' , color : theme.color}}>
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label" style={{fontFamily : 'Electrolize' , color : theme.color}}>Channel</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={age}          
          onChange={handleChange}
          MenuProps={{
            MenuListProps: {
              disablePadding: true
          }}}
          style={{width : '100%' , 
                  height: '100%',
                  left : 0, 
                  fontFamily : 'Electrolize', 
                  color : theme.color , 
                  backgroundColor : theme.backgroundColor, 
                  border: `1px solid ${theme.backgroundColor2}`}}
        >
          {uniqueChannels.map((option, index) => (
          <MenuItem 
            key={index}
            style={{width : '100%', fontFamily : 'Electrolize', color : theme.color , backgroundColor : theme.backgroundColor, border: `1px solid grey`,}}
            value={option}
             >
            {option}
          </MenuItem>
        ))}
        </Select>
      </FormControl>
    </Box>
  );
}