import React, { useState } from 'react';
import { View , 
    StyleSheet , 
    Text,
} from 'react-native'
import FilterBrand from '../Filters/FilterBrand';
import FilterChannel from '../Filters/FilterChannel'
import FilterType from '../Filters/FilterType';
import FilterDate from '../Filters/FilterDate';


const PPCKwFilter = (props) => {

    const uniqueBrands = props.uniqueBrands
    const uniqueChannels = props.uniqueChannels
    const uniqueTypes = props.uniqueTypes
    const propObject = props.propObject
    const handleStateChange = props.handleStateChange
    const theme = props.theme

    return (
        <View style={styles.container}>
        <View style={styles.filterContainer}>
        <FilterBrand 
            uniqueBrands={uniqueBrands}
            propObject={propObject}
            handleStateChange={handleStateChange}
            theme={theme}
        />
        </View>
        <View style={styles.filterContainer}>
        <FilterChannel
            uniqueChannels={uniqueChannels}
            propObject={propObject}
            handleStateChange={handleStateChange}
            theme={theme}
        />
        </View>
        <View style={styles.filterContainer}>
        <FilterType
            uniqueTypes={uniqueTypes}
            propObject={propObject}
            handleStateChange={handleStateChange}
            theme={theme}
            />
        </View>

        </View>
    )

}

export default PPCKwFilter

const styles = StyleSheet.create({
    container: {
        borderWidth: 0,
        borderColor: 'blue',
        flexDirection : 'row',
        height : '10%',
        width : '100%',
        justifyContent: 'center',
        alignItems: 'center'
        

    },
    filterContainer : {
        height : '100%',
        width : '33%',
        alignItems : 'center',
        borderWidth: 0,
        padding: '1%',
        borderColor: 'red',
        paddingLeft : '0.5%',

    }
})