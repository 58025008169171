import React, {useEffect} from 'react';
import { View, Text, StyleSheet, Dimensions } from 'react-native';
import WebFont from 'webfontloader';

const windowWidth = Dimensions.get('window').width;

const AnalyticsText = (props) => {

    const theme = props.theme
    const responsiveLarge = windowWidth *0.025
    const responsiveSmall = windowWidth *0.01

    useEffect(() => {
      WebFont.load({
        google: {
          families: ['Droid Sans', 'Electrolize']
        }
      });
     }, []);

    const valueText = {
        fontSize: responsiveLarge,
        color : theme.color,
        textAlign : 'center',
        padding : '1%',
        fontFamily: 'Electrolize'

      }
    const subHeaderText = {
        fontSize : responsiveSmall,
        color : theme.color,
        fontWeight : 100,
        fontFamily: 'Electrolize'
        
      }

    const container = {
        flex: 1,
        width: '100%',
        height: '100%',
        flexDirection: 'column',
        backgroundColor : theme.backgroundColor
      }

    const dataProp = props.dataProp
    const agg_daily_result = dataProp.agg_daily_result
    
    let totalSpend = 0;
      for (let i in agg_daily_result) {
        totalSpend += agg_daily_result[i].spend
      }

      let totalReg = 0;
      for (let i in agg_daily_result) {
        totalReg += agg_daily_result[i].regs
      }

      let totalFTD = 0;
      for (let i in agg_daily_result) {
        totalFTD += agg_daily_result[i].ftds
      }

      let totalNewUsers = 0;
      for (let i in agg_daily_result) {
        totalNewUsers += agg_daily_result[i].new_users
      }

      let totalSessions = 0;
      for (let i in agg_daily_result) {
        totalSessions += agg_daily_result[i].sessions
      }

      let totalPageViews = 0;
      for (let i in agg_daily_result) {
        totalPageViews += agg_daily_result[i].pageviews
      }

      let totalNgr = 0;
      for (let i in agg_daily_result) {
        totalNgr += agg_daily_result[i].ngr
      }

      let totalRegStart = 0;
      for (let i in agg_daily_result) {
        totalRegStart += agg_daily_result[i].reg_start
      }


      let totalCPA = ((totalSpend / totalFTD).toFixed(2))
      let totalCPR = ((totalSpend / totalReg).toFixed(2))
      let spendTotal = ((totalSpend.toFixed(0)))



  return (
    <View style={container}>
      <View style={styles.row}>
        <View style={styles.column}>
          <View style={styles.section}>
            <Text
            style={valueText}>
            {totalSessions}
            </Text>
            <Text style={subHeaderText}>
            Total Sessions
            </Text>
          </View>
        </View>
        <View style={styles.column}>
          <View style={styles.section}>
            <Text style={valueText}>
            {totalNewUsers}
            </Text>
            <Text style={subHeaderText}>
            Total New Users
            </Text>
          </View>
        </View>
      </View>
      <View style={styles.row}>
        <View style={styles.column}>
          <View style={styles.section}>
          <Text style={valueText}>
            {totalRegStart}
            </Text>
            <Text style={subHeaderText}>
            Reg Starts</Text>
          </View>
        </View>
        <View style={styles.column}>
          <View style={styles.section}>
          <Text style={valueText}>
            {totalReg}
            </Text>
            <Text style={subHeaderText}>
            NDCs</Text>
          </View>
        </View>
      </View>
      <View style={styles.row}>
        <View style={styles.column}>
          <View style={styles.section}>
          <Text style={valueText}>
            {totalFTD}
            </Text>
            <Text style={subHeaderText}>
            FTDs</Text>
          </View>
        </View>
        <View style={styles.column}>
          <View style={styles.section}>
          <Text style={valueText}>
            {totalNgr}
            </Text>
            <Text style={subHeaderText}>
            NGR</Text>
          </View>
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  
  row: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  column: {
    flex: 1,
    alignItems: 'center',

    borderRadius : 10,

  },
  section: {
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    borderWidth: 0.1,
    borderColor: 'grey',
    padding : '1%',
    borderRadius : 5
  },
  
});

export default AnalyticsText;
