import React, { useState } from 'react';
import {View, 
    Dimensions, 
    Text,
    Pressable } from 'react-native';

const windowWidth = Dimensions.get('window').width;
const responsiveTextSmall = windowWidth * 0.015

const DashGraphType = (props) => {

    const theme = props.theme
    const mainGraphHandler = props.mainGraphHandler
    const currentType = props.currentType
    const setVisible = props.setVisible
    const [pressedElement, setPressedElement] = useState(currentType);
    
    const handlePress = (type) => {
        setPressedElement(type);
        mainGraphHandler('type', type)

      };

    const container = {
        height: '15%',
        width: '100%',
        flexDirection: 'row',
        borderWidth: 6,
        borderColor: theme.color,
    }
    const typeItem = {
        height: '100%',
        width: '25%',
        flexDirection: 'column',
        borderWidth: 1,
        borderColor: theme.color,
        alignItems: 'center',
        justifyContent: 'center'
    }

    const text = {
        fontSize : responsiveTextSmall,
        fontFamily: 'Electrolize',
        textAlign: 'center',
        color: theme.color
    }

    const selectedChild = {
        borderWidth: 1,
        borderColor: theme.color,
        backgroundColor: theme.backgroundColor2,
        alignItems: 'center',
        justifyContent: 'center'
      }
    const selectedText = {
        color: theme.color2, // Change this to your desired text color
        fontSize : responsiveTextSmall,
        fontFamily: 'Electrolize',
        textAlign: 'center',
      }

    return (
        <View style={container}>
            <Pressable 
                style={[typeItem, pressedElement === 'line' && selectedChild]}
                onPress={() => handlePress("line")}
                >
                    <Text
                        style={[text, pressedElement === 'line' && selectedText]}
                        >
                        Line
                    </Text>
            </Pressable>
            <Pressable 
                style={[typeItem, pressedElement === 'bar' && selectedChild]}
                onPress={() => handlePress("bar")}
                >
                    <Text
                        style={[text, pressedElement === 'bar' && selectedText]}
                        >
                        Bar
                    </Text>
            </Pressable>
            <Pressable 
                style={[typeItem, pressedElement === 'mixed' && selectedChild]}
                onPress={() => handlePress("mixed")}
                >
                    <Text
                        style={[text, pressedElement === 'mixed' && selectedText]}
                        >
                        Mixed
                    </Text>
            </Pressable>
            <Pressable 
                style={[typeItem, pressedElement === 'table' && selectedChild]}
                onPress={() => handlePress("table")}
                >
                    <Text
                        style={[text, pressedElement === 'table' && selectedText]}
                        >
                        Table
                    </Text>
            </Pressable>
        </View>
    )
}

export default DashGraphType