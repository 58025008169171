export default function ParseMain (cubeResponse, xAxisValue, currentMetric1, currentMetric2, table) {

    var dataset = cubeResponse
    const xAxis = `${table}.${xAxisValue}`
    const impressions = `${table}.impressions`
    const clicks = `${table}.clicks`
    const spend = `${table}.spend`
    const regs = `${table}.regs`
    const ftds = `${table}.ftds`

    var agg_result = [];
dataset.reduce(function (res, value) {
  if (!res[0]) {
    res[0] = {
      [xAxisValue]: 'Aggregated Values', // You can set any label you prefer
      impressions: 0,
      clicks: 0,
      spend: 0,
      regs: 0,
      ftds: 0,
    };
    agg_result.push(res[0]);
  }
  res[0].impressions += parseInt(value[impressions]) || 0;
  res[0].clicks += parseInt(value[clicks]) || 0;
  res[0].spend += parseInt(value[spend]) || 0;
  res[0].regs += parseInt(value[regs]) || 0;
  res[0].ftds += parseInt(value[ftds]) || 0;

  return res;
}, {});

  agg_result = agg_result.map((obj) => {
    const impressions = obj.impressions
    const clicks = obj.clicks
    const spend = obj.spend;
    const regs = obj.regs
    const ftds = obj.ftds;
    const ctr = impressions === 0 || clicks === 0 || isNaN(clicks) ? 0 : clicks / impressions;
    const cpc = spend === 0 || clicks === 0 || isNaN(clicks) ? 0 : spend / clicks;
    const cpa = spend === 0 || ftds === 0 || isNaN(ftds) ? 0 : spend / ftds;
    const cpr = spend === 0 || regs === 0 || isNaN(regs) ? 0 : spend / regs;
    return {
      ...obj,
      ctr: parseFloat(ctr).toFixed(2),
      cpc: parseFloat(cpc).toFixed(2),
      cpa: parseInt(cpa),
      cpr: parseInt(cpr),
    };
  });

      const keysToKeep = [xAxisValue]

      const filteredResult = agg_result.map(item => {
        const filteredItem = {};
      
        keysToKeep.forEach(key => {
          if (item.hasOwnProperty(key)) {
            filteredItem[key] = item[key];
          }
        });
      
        return filteredItem;
      });

      const output = (filteredResult)

      return output

}