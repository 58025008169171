import  AWS  from 'aws-sdk';

const s3Kw = async (props) => {


  AWS.config.update({
    accessKeyId: 'AKIA5VQVRAUKYCUSHUM3',
    secretAccessKey: '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',
    region: 'eu-central-1'
  });
  
  const s3 = new AWS.S3();
  const clientID = props.clientID
  const clientLower = clientID.toLowerCase();
  
  const inputArray = [
    { name: 'kw', key: clientID+'/kw_touch.json' },  
  ];
    const output = {};
  
  for (const object of inputArray) {
    const params = {
      Bucket: 'stack-visualisation',
      Key: object.key
    };
  
    try {
      
      const s3Response = await s3.getObject(params).promise();
  
  
      const content = s3Response.Body.toString();
      const parsedContent = JSON.parse(content);
  
      // Store the parsed content in the output object using the object's name as the key
      output[object.name] = parsedContent;

  
  
      
    } catch (error) {
      console.log(`Error retrieving object ${object.name}:`, error);
    }
  }
   
  return(output) 
  
  }



export default s3Kw

