import React, {useEffect} from 'react';
import { View, Text, StyleSheet, Dimensions } from 'react-native';
import WebFont from 'webfontloader';

const windowWidth = Dimensions.get('window').width;

const BudgetsText = (props) => {

    const theme = props.theme
    const responsiveLarge = windowWidth *0.025
    const responsiveSmall = windowWidth *0.01

    useEffect(() => {
      WebFont.load({
        google: {
          families: ['Droid Sans', 'Electrolize']
        }
      });
     }, []);

    const valueText = {
        fontSize: responsiveLarge,
        color : theme.color,
        textAlign : 'center',
        padding : '1%',
        fontFamily: 'Electrolize'

      }

      const negativeValueText = {
        fontSize: responsiveLarge,
        color : 'white',
        textAlign : 'center',
        padding : '1%',
        fontFamily: 'Electrolize'

      }
    const subHeaderText = {
        fontSize : responsiveSmall,
        color : theme.color,
        fontWeight : 100,
        fontFamily: 'Electrolize'
        
      }
      const nagativeSubHeaderText = {
        fontSize : responsiveSmall,
        color : 'white',
        fontWeight : 100,
        fontFamily: 'Electrolize'
        
      }

    const container = {
        flex: 1,
        width: '100%',
        height: '100%',
        flexDirection: 'column',
        backgroundColor : theme.backgroundColor
      }

    const dataProp = props.dataProp
    const agg_daily_result = dataProp.agg_daily_result
    
    let totalSpend = 0;
      for (let i in agg_daily_result) {
        totalSpend += agg_daily_result[i].actual_spend
      }

    let totalFTD = 0;
      for (let i in agg_daily_result) {
        totalFTD += agg_daily_result[i].ftd
      }

      let totalBudget = 0;
      for (let i in agg_daily_result) {
        totalBudget += agg_daily_result[i].budget
      }

      let yesterdaySum = 0
      for (let i in agg_daily_result) {
        yesterdaySum += agg_daily_result[i].yesterday_spend;
      }


      let formatCPA = ((totalSpend / totalFTD).toFixed(2))
      let formatSpend = ((totalSpend.toFixed(0)))
      let daysInMonth = (agg_daily_result[0].days_in_month);
      let currentDoM = (agg_daily_result[0].current_dom);
      let daysRemaining = (agg_daily_result[0].days_remaining);
      let totalCPA = ("€"+(totalSpend / totalFTD).toFixed(0))
      let spendRate = (((totalSpend / totalBudget) * 100).toFixed(2)+"%")
      let spendTotal = ("€"+(totalSpend.toFixed(0)))
      let formatYest = ("€"+(yesterdaySum.toFixed(0)))

      const spendRateRaw = ((totalSpend / totalBudget) * 100)
      let targetDailyRaw = ((totalBudget - totalSpend) / (parseInt(daysRemaining)+1)).toFixed(0)

      let targetDaily = ("€"+((totalBudget) / (parseInt(currentDoM))).toFixed(0))
      let yestRate = ((yesterdaySum/(((totalBudget) / (parseInt(currentDoM)))) * 100).toFixed(2)+"%")

  return (
    <View style={container}>
      <View style={styles.row}>
        <View style={styles.column}>
          <View style={spendRateRaw > 97 ? styles.positiveColumn : styles.negativeColumn}>
            <Text style={spendRateRaw > 97 ? valueText : negativeValueText}>
            {spendTotal}
            </Text>
            <Text style={spendRateRaw > 97 ? subHeaderText : nagativeSubHeaderText}>
            Total Spend
            </Text>
          </View>
        </View>
        <View style={styles.column}>
          <View style={spendRateRaw > 97 ? styles.positiveColumn : styles.negativeColumn}>
          <Text style={spendRateRaw > 97 ? valueText : negativeValueText}>
            {spendRate}
            </Text>
            <Text style={spendRateRaw > 97 ? subHeaderText : nagativeSubHeaderText}>
            MTD Spend Rate
            </Text>
          </View>
        </View>
      </View>
      <View style={styles.row}>
        <View style={styles.column}>
        <View style={targetDailyRaw < yesterdaySum ? styles.positiveColumn : styles.negativeColumn}>
        <Text style={targetDailyRaw < yesterdaySum ? valueText : negativeValueText}>
          {targetDaily}
            </Text>
            <Text style={targetDailyRaw < yesterdaySum ? subHeaderText : nagativeSubHeaderText}>
            Avg Daily Target</Text>
          </View>
        </View>
        <View style={styles.column}>
        <View style={yestRate < 95 ? styles.positiveColumn : styles.negativeColumn}>
          <Text style={yestRate < 95 ? valueText : negativeValueText}>
            {yestRate}
            </Text>
            <Text style={yestRate < 95 ? subHeaderText : nagativeSubHeaderText}>
            Yesterday Spend Rate</Text>
          </View>
        </View>
      </View>
      <View style={styles.row}>
        <View style={styles.column}>
        <View style={daysRemaining > 5 ? styles.positiveColumn : styles.negativeColumn}>
          <Text style={daysRemaining > 5 ? valueText : negativeValueText}>
          {daysRemaining}
            </Text>
          <Text style={daysRemaining > 5 ? subHeaderText : nagativeSubHeaderText}>
            Days Remaining</Text>
          </View>
        </View>
        <View style={styles.column}>
        <View style={daysInMonth > 28 ? styles.positiveColumn : styles.negativeColumn}>
          <Text style={daysInMonth > 28 ? valueText : negativeValueText}>
            {daysInMonth}
            </Text>
            <Text style={daysInMonth > 28 ? subHeaderText : nagativeSubHeaderText}>
            Days In Month</Text>
          </View>
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  
  row: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  column: {
    flex: 1,
    alignItems: 'center',
    borderRadius : 10,

  },
  section: {
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    borderWidth: 0.1,
    borderColor: 'grey',
    padding : '1%',
    borderRadius : 5
  },
  positiveColumn: {
    backgroundColor: 'rgba(103, 204, 142, 0.5)', // Background color for values > 1
    color: 'white', 
    flex: 1,
    alignItems: 'center',
    borderColor: 'white',
    borderWidth: 0.1,
    justifyContent: 'center',        // Text color for values > 1
    width : '100%',
    padding: '10%'
    // Other styles...
  },
  negativeColumn: {
    backgroundColor: 'rgba(250, 35, 62, 0.7)',   // Background color for values <= 1
    color: 'white',  
    flex: 1,
    alignItems: 'center',
    borderColor: 'white',
    borderWidth: 0.1,
    justifyContent: 'center',        // Text color for values > 1
    width : '100%'
    // Other styles...
  }
  
});

export default BudgetsText;
