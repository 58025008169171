import { configureStore } from '@reduxjs/toolkit';
import dailyDataReducer from './dataReducer';
import themeReducer from './themeReducer';


const store = configureStore({
  reducer: {
    dailyData : dailyDataReducer,
    theme : themeReducer
  },
});

export default store;