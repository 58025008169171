import React, {useEffect} from 'react';
import { AdapterMoment } from '@mui/x-date-pickers-pro/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDateRangePicker } from '@mui/x-date-pickers-pro/DesktopDateRangePicker';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import { lime, purple } from '@mui/material/colors';
import { View } from 'react-native'
import moment from 'moment'
import WebFont from 'webfontloader';


export default function FilterDate(props) {

    const handleStateChange = props.handleStateChange
    const propObject = props.propObject
    const theme = props.theme
    const endDateProp = props.propObject.end
    const startDateProp = props.propObject.start
    const themeColor = theme.backgroundColor2

    useEffect(() => {
      WebFont.load({
        google: {
          families: ['Droid Sans', 'Electrolize']
        }
      });
     }, []);

     const themeCustom = createTheme({
      palette: {
        primary: purple,
        secondary: purple,
        main: purple,
      },
    });


    const handleTest = (value) => {
        const startDate = new Date(value[0]);
        const endDate = new Date(value[1]);

        const startOptions = { timeZone: 'CET', year: 'numeric', month: '2-digit', day: '2-digit' };
        const endOptions = { timeZone: 'CET', year: 'numeric', month: '2-digit', day: '2-digit' };

        const start = startDate.toLocaleDateString('en-US', startOptions);
        const end = endDate.toLocaleDateString('en-US', endOptions);

        const prop = {
            start,end
        }

        handleUpdate(prop)
    }

    const handleUpdate = (prop) =>{
  
        const propUpdate = {
          start: prop.start,
          end: prop.end,
          channel: propObject.channel,
          brand: propObject.brand,
          kw_type: propObject.kw_type,
        }
      
        handleStateChange(propUpdate)
      
      }

  return (
    <View style={{
        borderColor : 'yellow', 
        borderWidth : 0 , 
        padding : '1%',
        flex : 1,
        fontFamily : 'Electrolize',
        
        
        }}
        sx={{
          fontFamily : 'Electrolize',
          backgroundColor : theme.backgroundColor2,
          
          }}>
    <LocalizationProvider dateAdapter={AdapterMoment}>
        <ThemeProvider theme={themeCustom}>
          <DesktopDateRangePicker
            onChange={handleTest}
            defaultValue = {[moment(startDateProp), moment(endDateProp)]}
            sx={{
                width: "100%",
                "& .MuiInputLabel-root.Mui-focused": { color: "#979797" }, //styles the label
                "& .MuiOutlinedInput-root": {
                "&:hover > fieldset": { borderColor: "#C7C8CD" },

                },
              }}
            style={{fontFamily : 'Electrolize' , backgroundColor : theme.backgroundColor2}}
            slotProps={{
              textField: () => ({
                color: 'main',
                inputProps: {
                  
                  style: {
                    color: theme.color, // Replace with your custom text color
                    border: `1px solid ${theme.backgroundColor2}`,
                    borderRadius: 5,
                    fontFamily : 'Electrolize',
                    "&:hover > fieldset": { borderColor: "#C7C8CD" },
                  },
                },
                focused: false,
              })
            }}

          />
          </ThemeProvider>
    </LocalizationProvider>
    </View>
  );
}