import React, { useState } from 'react';
import {
    Modal,
    View,
    StyleSheet,
    Dimensions,
    Text,
    Pressable
} from 'react-native';
import TimelineIcon from '@mui/icons-material/Timeline';
import NotesIcon from '@mui/icons-material/Notes';

const windowWidth = Dimensions.get('window').width;

const Template4 = (props) => {

    const theme = props.theme
    const responsiveText = windowWidth * 0.07
    const responsiveTextSmall = windowWidth * 0.02

    const container = {
        height: '100%',
        width: '100%',
        flexDirection: 'column',
        backgroundColor: theme.backgroundColor,
        borderRadius: 10
    }
    
    const titleRow = {
        height: '15%',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        borderWidth: 1,
        borderColor: theme.backgroundColor2,
        borderTopRightRadius: 10,
        borderTopLeftRadius: 10,
    }

    const panel = {
        height: '85%',
        width: '100%',
        flexDirection: 'column'
    }

    const panelTopRow = {
        height: '66.66666%',
        width: '100%',
        flexDirection: 'row',
        borderWidth: 1,
        borderColor: theme.backgroundColor2,
        alignItems: 'center',
        justifyContent: 'center'
    }

    const panelBottomRow = {
        height: '33.33333%',
        width: '100%',
        flexDirection: 'row',
        
    }

    const panelBottomRowItem = {
        height: '100%',
        width: '33.33333%',
        borderWidth: 1,
        borderColor: theme.backgroundColor2,
        alignItems: 'center',
        justifyContent: 'center'

    }

    const graphTopRow = {
        width: '66.66666%',
        height: '100%',
        borderWidth: 1,
        borderColor: theme.backgroundColor2,
        alignItems: 'center',
        justifyContent: 'center'

    }

    const panelsContainer = {
        width: '33.333333%',
        height: '100%',
        borderWidth: 0,
        borderColor: theme.backgroundColor2,
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column'
    }

    const panelsRow = {
        height: '33.333333%',
        width: '100%',
        flexDirection: 'row',
        
    }

    const panelsRowItem = {
        height: '100%',
        width: (100/6)+'%',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        borderWidth: 1,
        borderColor: theme.backgroundColor2,
    }

    const largeIcon = {
        fontSize: responsiveText,
        color: theme.backgroundColor2,
        textAlign: 'center'
    }

    const smallIcon = {
        fontSize: responsiveTextSmall,
        color: theme.backgroundColor2,
        fontFamily: 'Electrolize',
    }

    const header = {
        fontSize: responsiveTextSmall * 0.6,
        color: theme.backgroundColor2,
        fontFamily: 'Electrolize',
        fontWeight: 600
    }

    return (
        <View style={container}>
            <View style={titleRow}>
                <Text style={header}> Template 4</Text>
            </View>
            <View style={panel}>
                <View style={panelTopRow}>
                <TimelineIcon sx={largeIcon}/>
                </View>

                <View style={panelsRow}>
                    <View style={[panelsRowItem , {borderBottomLeftRadius: 10}]}>
                        <NotesIcon sx={smallIcon}/>
                    </View>
                    <View style={panelsRowItem}>
                        <NotesIcon sx={smallIcon}/>
                    </View>
                    <View style={panelsRowItem}>
                        <NotesIcon sx={smallIcon}/>
                    </View>
                    <View style={panelsRowItem}>
                        <NotesIcon sx={smallIcon}/>
                    </View>
                    <View style={panelsRowItem}>
                        <NotesIcon sx={smallIcon}/>
                    </View>
                    <View style={[panelsRowItem , {borderBottomRightRadius: 10}]}>
                        <NotesIcon sx={smallIcon}/>
                    </View>

                </View>
                
            </View>
        </View>
    )
}

export default Template4