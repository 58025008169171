import React, { useState, useEffect } from 'react';
import { View , StyleSheet , Dimensions, Text} from 'react-native'
import { useSelector } from 'react-redux';
import AppBarRN from '../Components/AppBarRN';
import TemporaryDrawer from "../Components/Drawer";
import BudgetPanelV2 from '../Budgets/BudgetPanelV2';
import s3Budget from '../Data/s3Budget';
import LoadingComponent from '../Components/Loader'
import { config } from 'aws-sdk';


const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;

const Budgets = (props) => {

    const [loading, setLoading] = useState(true);
    const [data, setData] = useState(null);
    const theme = props.theme
    const homeTitle = "Budget Summary";
    const configArray = props.configArray
    const role = configArray.role
    const configHandler = configArray.configHandler
    const clientHandler = configArray.clientHandler
    const clientID = configArray.clientID
    
    const stateBase = {
        top: false,
        left: false,
        bottom: false,
        right: false,
      };

    const [state, setState] = useState(stateBase);
    const anchor = 'left'

    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
          return;
        }
    
        setState({ ...state, [anchor]: open });

      };

    function handleDrawer (anchor) {

        const stateChange = {
            top: false,
            left: true,
            bottom: false,
            right: false,
          };
          setState(stateChange)
    
    }
  
    async function fetchS3Data() {
        try {
          const data = await s3Budget(configArray);
    
          return data;
        } catch (error) {
          console.error('Error fetching data:', error);
          return null;
        }
      }
    
      useEffect(() => {
        fetchS3Data().then((result) => {
          setData(result);
          setLoading(false);
        });
      }, [clientID]);
    
      if (loading) {
        return (
          <LoadingComponent theme={theme}/>
        );
      }
    
    const dataset = data.budget
    const getUniqueValues = (arr, key) => {
        const uniqueSet = new Set(arr.map(item => item[key]));
        return [...uniqueSet];
      };
  
      const uniqueBrands = getUniqueValues(dataset, 'brand');;
      const uniqueChannels = getUniqueValues(dataset, 'channel');
      const uniqueTypes = getUniqueValues(dataset, 'kw_type');

    return (
      <View style={[styles.container, {backgroundColor: theme.backgroundColor}]}>
        <AppBarRN 
            handleDrawer={handleDrawer}
            homeTitle={homeTitle}
            theme={theme}
            configHandler={configHandler}
            clientHandler={clientHandler}
        />
        <TemporaryDrawer 
            toggleDrawer={toggleDrawer}
            state={state}
            anchor={anchor}
            theme={theme}
        />
        <BudgetPanelV2 
            style={styles.panel}
            uniqueBrands={uniqueBrands}
            uniqueChannels={uniqueChannels}
            uniqueTypes={uniqueTypes}
            theme={theme}
            data={data}
        />
        </View>
        
    )
}

const styles = StyleSheet.create({
    container: {
      borderWidth: 0,
      borderColor: 'yellow',
      paddingTop: 0,
      flexDirection: 'column',
      justifyContent: 'flex-start',
      width: '100%',
      height: '100%',
      flex: 1,
    },
    panel: {
        width : '100%'
    }
})

export default Budgets