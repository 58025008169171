import React, { useEffect} from "react";
import { useAuth0 } from "@auth0/auth0-react";
import WebFont from 'webfontloader';

const LogoutButton = () => {
  const { logout } = useAuth0();

  useEffect(() => {
    WebFont.load({
        google: {
            families: ['Droid Sans', 'Electrolize']
        }
    });
}, []);

  return (
    <button 
    style={{width: '100%', height: '3%', fontFamily: 'Electrolize', }}
    onClick={() => logout({ logoutParams: { returnTo: 'http://localhost:3000/' } })}>
      Log Out
    </button>
  );
};

export default LogoutButton;