import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import clsx from 'clsx';
import { DataGrid, GridToolbarContainer, GridToolbarExport  } from '@mui/x-data-grid';
import { Dimensions } from 'react-native'
import WebFont from 'webfontloader';
const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;

export default function TableLarge(props) {

  const dataProp = props.dataProp
  const colNum = props.colNum
  const columnWidth = props.columnWidth
  const theme = props.theme
  const currentSettings = props.currentSettings
  const inputArray = ["brand", "kw_type", "channel", "ctr" , "clicks", "spend", "regs", "impressions" , "ftds"];
  console.log(props)

  let modifiedSettings;

  if (currentSettings === null) {
    modifiedSettings = ["brand", "kw_type", "channel", "ctr" , "clicks", "spend", "regs", "impressions" , "ftds"];
  } else {
    modifiedSettings = [...currentSettings];
    
    if (!modifiedSettings.includes('brand') && !modifiedSettings.includes('channel') && !modifiedSettings.includes('kw_type')) {
      modifiedSettings.unshift('brand');
    }
  }

  function aggregateData(inputArray, dataset) {
    const dimensions = inputArray.filter(key => !["impressions", "clicks", "spend", "regs", "ftds", "cpa", "cpr", "cpc" , "ctr"].includes(key));
    const values = ["impressions", "clicks", "spend", "regs", "ftds"];
    
    const result = {};
    
    dataset.forEach((value) => {
      const dimensionKey = dimensions.map(key => value[key]).join("_");
    
      if (!result[dimensionKey]) {
        result[dimensionKey] = { ...dimensions.reduce((obj, key) => {
          obj[key] = value[key];
          return obj;
        }, {}), ...values.reduce((obj, key) => {
          obj[key] = 0; // Initialize all value fields to 0
          return obj;
        }, {}) };
      }
    
      values.forEach((key) => {
        result[dimensionKey][key] += parseInt(value[key]);
      });
    });
    
    return Object.values(result);
  }
  
  const aggregatedResult = aggregateData(modifiedSettings, dataProp);

  var finalResult = aggregatedResult

  finalResult = finalResult.map((obj) => {
    const spend = parseFloat(obj.spend);
    const ftds = parseInt(obj.ftds);
    const regs = parseInt(obj.regs)
    const clicks = parseInt(obj.clicks)
    const impressions = parseInt(obj.impressions)
    const cpa = spend === 0 || ftds === 0 || isNaN(ftds) ? 0 : spend / ftds;
    const cpr = spend === 0 || regs === 0 || isNaN(regs) ? 0 : spend / regs;
    const ctr = impressions === 0 || clicks === 0 || isNaN(clicks) ? 0 : clicks / impressions;
    const cpc = spend === 0 || clicks === 0 || isNaN(clicks) ? 0 : spend / clicks;
    return {
      ...obj,
      cpa: parseInt(cpa),
      cpr : parseInt(cpr),
      ctr : parseFloat(ctr.toFixed(2)),
      cpc : parseFloat(cpc.toFixed(2))
    };
  });

  const columnVisibility = {
    id : modifiedSettings.includes('id'),
    impressions : modifiedSettings.includes('impressions'),
    clicks : modifiedSettings.includes('clicks'),
    spend : modifiedSettings.includes('spend'),
    regs : modifiedSettings.includes('regs'),
    ftds : modifiedSettings.includes('ftds'),
    cpa : modifiedSettings.includes('cpa'),
    cpr : modifiedSettings.includes('cpr'),
    ctr : modifiedSettings.includes('ctr'),
    cpc : modifiedSettings.includes('cpc'),
  }

  useEffect(() => {
    WebFont.load({
      google: {
        families: ['Electrolize']
      }
    });
   }, []);

  const idRow = [{
    field: 'id', 
    headerName: 'ID', 
    maxWidth: 5, 

    headerAlign: 'center', 
    headerClassName: 'table-header', 
    align: 'center' , 
    cellClassName : 'super-app-theme--cell'}]

    const minMaxValues = {};
    Object.keys(finalResult[0]).forEach((key) => {
      const values = finalResult.map((row) => row[key]);
      minMaxValues[key] = { min: Math.min(...values), max: Math.max(...values) };
    });

  const labels = Object.keys(finalResult[0]).map((key) => {
    return { 
    field: key, 
    headerName: key, 
    minWidth: windowWidth * columnWidth * 1.25, 
    flex: 1, 
    headerAlign: 'center', 
    headerClassName: 'table-header', 
    align: 'center' , 
    cellClassName: (params) => {
      if (params.value == null) {
        return '';
      }
      const { min, max } = minMaxValues[key];
      return clsx('super-app', {
        c10: params.value >= (max - min) * 0.9,
        c20: params.value >= (max - min) * 0.8 && params.value < (max - min) * 0.9,
        c30: params.value >= (max - min) * 0.7 && params.value < (max - min) * 0.8,
        c40: params.value >= (max - min) * 0.6 && params.value < (max - min) * 0.7,
        c50: params.value >= (max - min) * 0.5 && params.value < (max - min) * 0.6,
        c60: params.value >= (max - min) * 0.4 && params.value < (max - min) * 0.5,
        c70: params.value >= (max - min) * 0.3 && params.value < (max - min) * 0.4,
        c80: params.value >= (max - min) * 0.2 && params.value < (max - min) * 0.3,
        c90: params.value >= (max - min) * 0.1 && params.value < (max - min) * 0.2,
        c100: params.value >= (max - min) * 0 && params.value < (max - min) * 0.1,
        
      });
    },};
  });  
  const columnFix = [...idRow, ...labels];

  const rows = finalResult.map((row, index) => ({ id: index + 1, ...row, cellClassName: 'table-cells'}));

  const columns = columnFix

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarExport sx={{color: theme.color}}/>
      </GridToolbarContainer>
    );
  }

  
  return (
    <Box
    style={{
      width: '100%',
      fontSize: 50,
      color: theme.color,
      fontFamily : 'Electrolize',
      
    }}
      sx={{
        width: '100%',
        
        '& .table-header': { headerAlign: 'center', align : 'center' , fontFamily : 'Electrolize' , color: theme.color },
        '& .table-cells': { align: 'center' , fontFamily : 'Electrolize'},
        '& .super-app': { align: 'center' , fontFamily : 'Electrolize', color: theme.color},
        '& .super-app.c10': {
          backgroundColor: theme.gradc10,
          color: 'white',
          fontWeight: '600',
          fontFamily : 'Electrolize'
          
        },
        '& .super-app.c20': {
          backgroundColor: theme.gradc20,
          color: 'white',
          fontWeight: '100',
          fontFamily : 'Electrolize'
        },
        '& .super-app.c30': {
          backgroundColor: theme.gradc30,
          color: 'white',
          fontWeight: '100',
          fontFamily : 'Electrolize'
        },
        '& .super-app.c40': {
          backgroundColor: theme.gradc40,
          color: 'white',
          fontWeight: '100',
          fontFamily : 'Electrolize'
        },
        '& .super-app.c50': {
          backgroundColor: theme.gradc50,
          color: 'white',
          fontWeight: '100',
          fontFamily : 'Electrolize'
        },
        '& .super-app.c60': {
          backgroundColor: theme.gradc60,
          color: 'white',
          fontWeight: '100',
          fontFamily : 'Electrolize'
        },
        '& .super-app.c70': {
          backgroundColor: theme.gradc70,
          color: 'white',
          fontWeight: '100',
          fontFamily : 'Electrolize'
        },
        '& .super-app.c80': {
          backgroundColor: theme.gradc80,
          color: 'white',
          fontWeight: '100',
          fontFamily : 'Electrolize'
        },
        '& .super-app.c90': {
          backgroundColor: theme.gradc90,
          color: 'white',
          fontWeight: '100',
          fontFamily : 'Electrolize'
        },
        '& .super-app.c100': {
          backgroundColor: theme.gradc100,
          fontFamily : 'Electrolize',
          fontWeight: '100',
          color: 'white',
        },


      }}
    >
      <DataGrid 
        rows={rows}
        columns={columns} 
        columnVisibilityModel={columnVisibility}
        slots={{ toolbar: CustomToolbar }}
        initialState={{
          pagination: {
            paginationModel: { pageSize: parseInt(colNum) },
          },
        }}
        />
    </Box>
  );
}