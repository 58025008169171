import React, { useState, useEffect } from 'react';
import { Checkbox, FormGroup, FormControlLabel } from '@mui/material';

const CheckboxLabels = (props) => {
  const { metric, checked, handleCheckBoxChange } = props;
  const theme = props.theme

  const handleChange = (event) => {
    handleCheckBoxChange(metric.key, event.target.checked);
  };

  return (
    <FormGroup
    sx={{border: '0px solid red' , paddingLeft : '20%'}}>
    <FormControlLabel
        control={<Checkbox checked={checked} sx={{color: theme.color}} onChange={handleChange} />}
        label={metric.key}
    />
</FormGroup>

  );
};

const MetricList = (props) => {
  const { currentSetting, handleCheckBoxChange } = props;
  var metricList = props.metricList
  const [currentSettings, setcurrentSettings] = useState([]);
  const theme = props.theme

  useEffect(() => {
    // Initialize the currentSettings state with the currentSetting prop
    setcurrentSettings(currentSetting || ["brand" , "spend" , "ftds" , "cpa"]);
  }, [currentSetting]);

  const handleCheckBoxChangeInternal = (value, isChecked) => {
    // Create a new array with the updated checked items
    const updatedCurrentSettings = isChecked
      ? [...currentSettings, value]
      : currentSettings.filter((item) => item.toString() !== value.toString());

    // Update the state with the new array of checked items
    setcurrentSettings(updatedCurrentSettings);

    // Pass the updatedCurrentSettings to the parent component using the handleCheckBoxChange prop
    handleCheckBoxChange(updatedCurrentSettings);
  };

  return (
    <div style={{ width: '100%' , height : '100%' , border: '0.1px solid grey' , borderRadius : 5 , color: theme.color  }}>
      {metricList.map((metricItem) => (
        <CheckboxLabels
          key={metricItem.key}
          metric={metricItem}
          checked={currentSettings.includes(metricItem.key)}
          handleCheckBoxChange={handleCheckBoxChangeInternal}
          sx={{color: theme.color}}
          theme={theme}
        />
      ))}
    </div>
  );
};

export default MetricList;
