import React, { useState } from 'react';
import { Stack, TextInput, IconButton } from "@react-native-material/core";
import SendIcon from '@mui/icons-material/Send';

const TextBox = (props) => {

    const inputHandler = props.inputHandler
    const [inputText, setinputText] = useState('');
    const theme = props.theme


return(
  <Stack spacing={2} style={{ margin: 16 }}>
    <TextInput
    variant="outlined"
    onChangeText={setinputText}
    trailing={props => (
    <IconButton 
    onPress={() => inputHandler(inputText)}
        icon={props => <SendIcon 
          sx={{color:theme.backgroundColor2}}{...props} />} {...props} />
      )}
    />
  </Stack>
  )
};

export default TextBox;