import React, { useState, useEffect } from 'react';
import {
    View,
    StyleSheet,
    Dimensions,
    Text,
} from 'react-native'
import PPCKwFilter from '../PPC/PPCKwFilter';
import WebFont from 'webfontloader';
import moment from 'moment-timezone';
import parseKwFunction from '../Data/parseKwFunction';
import BubbleChart from '../ChartsDesktop/BubbleChart';

const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;

const PPCKwPanel = (props) => {

    const data = props.data.kw
    const theme = props.theme
    const currentDate2 = moment.tz('Europe/Malta');
    const yesterday2 = currentDate2.clone().subtract(1, 'day');
    const yesterdayProp = yesterday2.format('YYYY-MM-DD');
    const uniqueBrands = props.uniqueBrands
    const uniqueChannels = props.uniqueChannels
    const uniqueTypes = props.uniqueTypes

    const filterBase = {
      start: "2023-01-01",
      end: yesterdayProp,
      channel: "Total",
      brand: 'Total',
      kw_type: "Total",
    };

    const [filters, setFilters] = useState(filterBase);

    var dataset = parseKwFunction(data, filters)
    const agg_kw_result = dataset.agg_kw_result

    const dataProp = {
        agg_kw_result,
        uniqueBrands,
        uniqueChannels,
        uniqueTypes
      };

      const propObjectBase = {
        data: dataProp,
        start: "2022-07-01",
        end: yesterdayProp,
        theme: theme,
        brand: "Total",
        channel: "Total",
        kw_type: "Total",
      };
    
  
      const [propObject, setpropObject] = useState(propObjectBase);

      const handleStateChange = (prop) => {

        setpropObject(prop);
  
        const filterUpdate = {
            start: prop.start,
            end: prop.end,
            channel: prop.channel,
            brand: prop.brand,
            kw_type: prop.kw_type,
          };
  
        setFilters(filterUpdate)
  
      };

    useEffect(() => {
        WebFont.load({
            google: {
                families: ['Droid Sans', 'Electrolize']
            }
        });
    }, []);

    const panelMain = {

        width: windowWidth * 1,
        height: windowHeight * 0.93,
        borderWidth: 0.1,
        borderColor: 'grey',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        fontSize: 40,
        fontFamily: 'Electrolize',
        borderRadius: 5
    }
console.log(propObject)
        

    return (
        <View style={styles.container}>
            <View style={{ flexDirection: 'row' }}>
                <View style={panelMain}>
                    <View style={{ width: '100%', fontFamily: 'Electrolize', borderColor: 'red',
                                borderWidth: 0, paddingTop: '1%' }}>
                        <PPCKwFilter
                            style={{
                                height: '10%',
                                borderColor: 'red',
                                borderWidth: 1,
                                width: '100%',
                                fontFamily: 'Electrolize',
                            }}
                            uniqueBrands={uniqueBrands}
                            uniqueChannels={uniqueChannels}
                            uniqueTypes={uniqueTypes}
                            handleStateChange={handleStateChange}
                            propObject={propObject}
                            theme={theme}
                        />
                    </View>
                            <BubbleChart
                                propObject={propObject}
                                dimension={'keyword'}
                                metric1={'spend'}
                                metric2={'ftds'}
                                radius={'ftds'}
                                agg_kw_result={agg_kw_result}
                                theme={theme}
                            />
                </View>

            </View>
        </View>

    )

}

export default PPCKwPanel

const styles = StyleSheet.create({
    container: {
        borderWidth: 0,
        borderColor: 'blue',
        height: '100%',
        width: windowWidth,
        flexDirection: 'column',
        fontFamily: 'Electrolize',


    },
    lineChart: {
        flex: 1,
        width: '100%',
        alignItems: 'center',
        padding: '1%',
        fontFamily: 'Electrolize'
    }
})