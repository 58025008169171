import React, { useRef , useEffect} from 'react';
import { Line , Tooltip} from 'react-chartjs-2';
import Chart from 'chart.js/auto';
import WebFont from 'webfontloader';

const DashLine = (props) => {
  const chartRef = useRef(null);
  const agg_daily_result = props.agg_daily_result || []
  const labels = props.labels
  const theme = props.theme
  const metric = props.metric;
  const timeFrame = props.timeFrame
  const dailyFTDs = agg_daily_result.map((obj) => obj[metric]);

  useEffect(() => {
    WebFont.load({
      google: {
        families: ['Electrolize']
      }
    });
   }, []);

   function toProperCase(str) {
    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }

  const data = {
    labels: labels,
    datasets: [
      {
        label: timeFrame+ " " +metric,
        data: dailyFTDs,
        fill: true,
        borderColor: theme.backgroundColor2,
        backgroundColor: theme.backgroundColor2+30,
        lineTension: 0.5,
        radius: 1
      },
    ],
  };

  const options = {
    scales: {
      y: {
        beginAtZero: true,
        grid: {
          display: false, // Remove the gridlines on the y-axis
        },
        ticks: {
          color : theme.color,
          font: {
            size: 10, // Set the font size for y-axis labels
            weight: 'bold', // Set the font weight for y-axis labels
            family : 'Electrolize',
          },
        },
      },
      x: {
        grid: {
          display: false, // Remove the gridlines on the x-axis
        },
        ticks: {
          color : theme.color,
          font: {
            size: 12, // Set the font size for x-axis labels
            family : 'Electrolize'
          },
        },
      },
    },
    tooltips: {
      enabled: true,
    },
    maintainAspectRatio: false,
    
    plugins: {
      legend: {
        display: true,
        text: 'Chart Title',
        labels: {
          color : theme.color,
          // This more specific font property overrides the global property
          font: {
              size: 12,
              family : 'Electrolize'
          }
      }
      },
      tooltips: {
        enabled: true,
        
      },
      title: {
        display: false,
        text: 'Chart Title',
        font: {
          size: 24, // Set the font size for the chart title
          weight: 'bold', // Set the font weight for the chart title
  
        },
      },
    },
  };


  return <Line ref={chartRef} data={data} options={options} />;
};

export default DashLine;
