import React, { useState, useEffect } from 'react';
import { View, StyleSheet, Dimensions, Text } from 'react-native'
import { useSelector } from 'react-redux';
import AppBarRN from '../Components/AppBarRN';
import TemporaryDrawer from "../Components/Drawer";
import PPTpanels from '../PPT/PPTpanels';
import s3PPTconfig from '../Data/s3PPTconfig';
import LoadingComponent from '../Components/Loader'


const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;

const PPT = (props) => {

  const configArray = props.configArray
  const configHandler = configArray.configHandler
  const clientHandler = configArray.clientHandler
  const clientID = configArray.clientID
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const theme = props.theme
  const homeTitle = "Performance PPT";

  const stateBase = {
    top: false,
    left: false,
    bottom: false,
    right: false,
  };

  const [state, setState] = useState(stateBase);
  const anchor = 'left'

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });

  };

  function handleDrawer(anchor) {

    const stateChange = {
      top: false,
      left: true,
      bottom: false,
      right: false,
    };
    setState(stateChange)

  }

  async function fetchS3Data() {
    try {
      const data = await s3PPTconfig(configArray);

      return data;
    } catch (error) {
      console.error('Error fetching data:', error);
      return null;
    }
  }

  useEffect(() => {
    fetchS3Data().then((result) => {
      setData(result);
      setLoading(false);
    });
  }, [clientID]);

  if (loading) {
    return (
      <LoadingComponent theme={theme} />
    );
  }

  const dataset = data.docs
  console.log(dataset)
  const getUniqueValues = (arr, key) => {
    const uniqueSet = new Set(arr.map(item => item[key]));
    return [...uniqueSet];
  };

  const uniqueBrands = getUniqueValues(dataset, 'brand');;
  const uniqueChannels = getUniqueValues(dataset, 'channel');
  const uniqueTypes = getUniqueValues(dataset, 'kw_type');

  return (
    <View style={[styles.container, { backgroundColor: theme.backgroundColor }]}>
      <AppBarRN
        handleDrawer={handleDrawer}
        homeTitle={homeTitle}
        theme={theme}
        configHandler={configHandler}
        clientHandler={clientHandler}
      />
      <TemporaryDrawer
        toggleDrawer={toggleDrawer}
        state={state}
        anchor={anchor}
        theme={theme}
      />
      <PPTpanels
        theme={theme}
        data={data}
      />
    </View>

  )
}

const styles = StyleSheet.create({
  container: {
    borderWidth: 0,
    borderColor: 'yellow',
    paddingTop: 0,
    flexDirection: 'column',
    justifyContent: 'flex-start',
    width: '100%',
    height: '100%',
    flex: 1,
  },
  panel: {
    width: '100%'
  }
})

export default PPT