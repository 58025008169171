import React, { useEffect, useRef } from 'react';
import { Bar } from 'react-chartjs-2';
import WebFont from 'webfontloader';

const AttributionBar = (props) => {

    const theme = props.theme
    const cubeResponse = props.cubeResponse
    const chartType = props.chartType
    const cubeTable = props.cubeTable

    const options = {
        scales: {
          y: {
            beginAtZero: true,
            grid: {
              display: false, // Remove the gridlines on the y-axis
            },
            ticks: {
              color : theme.color,
              font: {
                size: 10, // Set the font size for y-axis labels
                weight: 'bold', // Set the font weight for y-axis labels
                family : 'Electrolize',
              },
            },
          },
          x: {
            grid: {
              display: false, // Remove the gridlines on the x-axis
            },
            ticks: {
              color : theme.color,
              font: {
                size: 12, // Set the font size for x-axis labels
                family : 'Electrolize'
              },
            },
          },
        },
        tooltips: {
          enabled: true,    
        },
        plugins: {
          legend: {
            display: true,
            text: 'Chart Title',
            labels: {
              color : theme.color,
              // This more specific font property overrides the global property
              font: {
                  size: 12,
                  family : 'Electrolize'
              }
          }
          },
          tooltip: {
            enabled: true,
          },
          title: {
            display: false,
            text: 'Chart Title',
            font: {
              size: 24, // Set the font size for the chart title
              weight: 'bold', // Set the font weight for the chart title
      
            },
          },
        },
      };  

    if (chartType === 'comparison') {
        return <ComparisonBar
                    theme={theme}
                    cubeResponse={cubeResponse}
                    options={options}
                    cubeTable={cubeTable}
                    />
    } else if (chartType === 'single') {
        return <SingleBar
                    theme={theme}
                    cubeResponse={cubeResponse}
                    options={options}
                    cubeTable={cubeTable}
                    />
    } else return null
}

const ComparisonBar = (props) => {
    const chartRef = useRef(null);
    const theme = props.theme
    const cubeResponse = props.cubeResponse
    const options = props.options
    const cubeTable = props.cubeTable

    function filterObjectsWithNonZeroFTD(data) {
        return data.filter((item) => {
          // Check if all ftd metrics are equal to 0
          return (
            item[`${cubeTable}.ftd_custom`] !== 0 ||
            item[`${cubeTable}.ftd_ft`] !== 0 ||
            item[`${cubeTable}.ftd_lt`] !== 0
          );
        });
      }

      const cubeResponseFiltered = filterObjectsWithNonZeroFTD(cubeResponse)
      console.log(cubeResponseFiltered)

    function calculateMetrics (cubeResponse, positions) {

    const labels = [];
    const valuesByPosition = [[], [], []]; // One array for each value position

    cubeResponse.forEach((item) => {
    const values = Object.values(item);
    
    labels.push(values[positions]); // Assuming the label is in the first position

    for (let i = 1; i <= 3; i++) {
        // Assuming ftd_custom, ftd_ft, and ftd_lt are in positions 1, 2, and 3
        valuesByPosition[i - 1].push(values[i]);
    }
    });

    return labels

    }

    const labels = calculateMetrics(cubeResponseFiltered, 0)
    const ftdCustom = calculateMetrics(cubeResponseFiltered, 1)
    const ftdFT = calculateMetrics(cubeResponseFiltered, 2)
    const ftdLT = calculateMetrics(cubeResponseFiltered, 3)

    const data = {
        labels: labels,
        datasets: [
          {
            label: "FTD Custom",
            backgroundColor: theme.blockColor,
            data: ftdCustom
          },
          {
            label: "FTD FT",
            backgroundColor: theme.backgroundColor2,
            data: ftdFT
          },
          {
            label: "FTD LT",
            backgroundColor: theme.comboStopColor2,
            data: ftdLT
          }
        ]
      }
  
    useEffect(() => {
      WebFont.load({
        google: {
          families: ['Electrolize']
        }
      });
     }, []);

      return <Bar ref={chartRef} data={data} options={options} style={{fontFamily : 'Electrolize'}} />;

}

const SingleBar = (props) => {
    const chartRef = useRef(null);
    const theme = props.theme
    const cubeResponse = props.cubeResponse
    const options = props.options
    const cubeTable = props.cubeTable


    function filterObjectsWithNonZeroFTD(data) {
        return data.filter((item) => {
          // Check if all ftd metrics are equal to 0
          return (
            item[`${cubeTable}.ftd_custom`] !== 0
          );
        });
      }

    const cubeResponseFiltered = filterObjectsWithNonZeroFTD(cubeResponse)
    

    function calculateMetrics (cubeResponse, positions) {

        const labels = [];
        const valuesByPosition = [[], [], []]; // One array for each value position
    
        cubeResponse.forEach((item) => {
        const values = Object.values(item);
        
        labels.push(values[positions]); // Assuming the label is in the first position
    
        for (let i = 1; i <= 3; i++) {
            // Assuming ftd_custom, ftd_ft, and ftd_lt are in positions 1, 2, and 3
            valuesByPosition[i - 1].push(values[i]);
        }
        });
    
        return labels
    
        }
    
        const labels = calculateMetrics(cubeResponseFiltered, 0)
        const ftdCustom = calculateMetrics(cubeResponseFiltered, 1)

    const data = {
        labels: labels,
        datasets: [
          {
            label: "FTD Custom",
            backgroundColor: theme.comboStopColor1,
            data: ftdCustom
          }
        ]
      }
  
    useEffect(() => {
      WebFont.load({
        google: {
          families: ['Electrolize']
        }
      });
     }, []);
     
      return <Bar ref={chartRef} data={data} options={options} style={{fontFamily : 'Electrolize'}} />;

}

export default AttributionBar