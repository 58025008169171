export default function ProgCubeQuery (cubeFilters, table, start, end, chartType) {

    if (chartType === 'site_url') {
        return {
            "timeDimensions": [
              {
                "dimension": `${table}.timestamp`,
                "dateRange": [
                    start,
                    end
                ]
              }
            ],
            "order": [
              [
                `${table}.cost`,
                "desc"
              ]
            ],
            "measures": [
                `${table}.impressions`,
                `${table}.clicks`,
                `${table}.cost`,
                `${table}.regs`,
                `${table}.ftds`,
                `${table}.ngr`
            ],
            "dimensions": [
                `${table}.brand`,
                `${table}.channel`,
                `${table}.kw_type`,
                `${table}.site_url`
            ],
            "limit": 1000,
            "filters": cubeFilters
          }
        }

    if (chartType === 'kw_type') {
        return {
            "timeDimensions": [
              {
                "dimension": `${table}.timestamp`,
                "dateRange": [
                    start,
                    end
                ]
              }
            ],
            "order": [
              [
                `${table}.cost`,
                "desc"
              ]
            ],
            "measures": [
                `${table}.impressions`,
                `${table}.clicks`,
                `${table}.cost`,
                `${table}.regs`,
                `${table}.ftds`,
                `${table}.ngr`
            ],
            "dimensions": [
                `${table}.brand`,
                `${table}.channel`,
                `${table}.kw_type`,
            ],
            "limit": 100,
            "filters": cubeFilters
          }
    }

    if (chartType === 'channel') {
        return {
            "timeDimensions": [
              {
                "dimension": `${table}.timestamp`,
                "dateRange": [
                    start,
                    end
                ]
              }
            ],
            "order": [
              [
                `${table}.cost`,
                "desc"
              ]
            ],
            "measures": [
                `${table}.impressions`,
                `${table}.clicks`,
                `${table}.cost`,
                `${table}.regs`,
                `${table}.ftds`,
                `${table}.ngr`
            ],
            "dimensions": [
                `${table}.brand`,
                `${table}.channel`,
            ],
            "limit": 1000,
            "filters": cubeFilters
          }
    }

    }