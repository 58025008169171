import React, { useEffect, useState } from "react";
import { AppBar } from "@react-native-material/core";
import { Text } from 'react-native';
import MenuIcon from '@mui/icons-material/Menu';
import IconButton from '@mui/material/IconButton';
import AccountCircle from '@mui/icons-material/AccountCircle';
import WebFont from 'webfontloader';
import { View, Pressable } from 'react-native'
import SettingsBrightnessOutlinedIcon from '@mui/icons-material/SettingsBrightnessOutlined';
import ModalTheme from "../Modals/ModalTheme";
import ModalClient from "../Modals/ModalClient";

const AppBarRN = (props) => {

  const handleDrawer = props.handleDrawer
  const homeTitle = props.homeTitle
  const theme = props.theme
  const configHandler = props.configHandler
  const clientHandler = props.clientHandler
  const [visibleModal, setVisible] = useState(false);
  const [visibleClientModal, setClientVisible] = useState(false);

  function modalHandler (props){
    setVisible(props)
  }

  function modalClientHandler (props) {
    setClientVisible(props)
  }

  useEffect(() => {
    WebFont.load({
      google: {
        families: ['Droid Sans', 'Electrolize']
      }
    });
  }, []);

  return (
    <AppBar
      title={<Text
        style={{
          fontFamily: 'Electrolize',
          color: theme.color,
          fontSize: 30
        }}>
        {homeTitle}
      </Text>}
      color={theme.backgroundColor}
      sx={{
        fontFamily: 'Electrolize',
        backgroundColor: theme.backgroundColor,
        border: `1px solid ${theme.color}`,
        borderRadius: 5
      }}

      leading={props => (
        <IconButton
          size="large"
          edge="end"
          color="primary"
          aria-label="menu"
          sx={{ mr: 4, color: theme.backgroundColor2 }}
          onClick={() => handleDrawer()}
        >
          <MenuIcon />
        </IconButton>
      )}
      trailing={props => (

        <View style={{ flexDirection: 'row' }}>
          <Pressable 
            onPress={() => modalHandler(true)}
            >
            <SettingsBrightnessOutlinedIcon

              edge="start"
              color="primary"
              aria-label="menu1"
              sx={{ mr: 2, color: theme.backgroundColor2, fontSize: 40 }}
            >
            </SettingsBrightnessOutlinedIcon>
          </Pressable>
          
          <Pressable 
            onPress={() => modalClientHandler(true)}
            >
            <AccountCircle

              edge="start"
              color="primary"
              aria-label="menu1"
              sx={{ mr: 2, color: theme.backgroundColor2, fontSize: 40 }}
            >

            </AccountCircle>
          </Pressable>
          <ModalTheme
            modalHandler={modalHandler}
            configHandler={configHandler}
            visibleModal={visibleModal}
            theme={theme}
            />
          <ModalClient
            modalClientHandler={modalClientHandler}
            visibleClientModal={visibleClientModal}
            clientHandler={clientHandler}
            theme={theme}
            />
        </View>

      )}
    />

  )

};

export default AppBarRN;