import React, { useEffect } from 'react';
import { Bubble } from 'react-chartjs-2';
import { View, StyleSheet, Dimensions, Text } from 'react-native'
import WebFont from 'webfontloader';


const BubbleChart = (props) => {

    const dataset = props.agg_kw_result
    const dataSlice = dataset.slice(0, 25)
    const dimension = props.dimension
    const metric1 = props.metric1
    const metric2 = props.metric2
    const radius = props.radius
    const theme = props.theme
    const maxY = Math.max(...dataset.map(item => item[metric2]));

    useEffect(() => {
        WebFont.load({
            google: {
                families: ['Electrolize']
            }
        });
    }, []);

    const bubbleData = {
        datasets: dataSlice.map(item => ({
            label: item[dimension],
            data: [
                {
                    x: item[metric1],
                    y: item[metric2],
                    r: (item[radius] / 10) // Adjust the radius value as needed
                }
            ]
        }))
    };

    return (
        <View style={styles.container}>
            <BubbleComponent
                data={bubbleData}
                theme={theme}
            />
        </View>
    )

}

const BubbleComponent = (props) => {
    const bubbleData = props.data
    const theme = props.theme
    return (
        <Bubble
            data={bubbleData}
            options={{
                scales: {
                    x: {
                        title: {
                            display: true,
                            text: 'Spend',
                            color : theme.color,
                        },
                        beginAtZero: true,
                        grid: {
                            display: false, // Remove the gridlines on the y-axis
                        },
                        ticks: {
                            color: theme.color,
                            font: {
                                size: 10, // Set the font size for y-axis labels
                                weight: 'bold', // Set the font weight for y-axis labels
                                family: 'Electrolize',
                            },
                        },
                    },
                    y: {
                        title: {
                            display: true,
                            text: 'FTDs',
                            color : theme.color,
                        },
                        beginAtZero: true,
                        grid: {
                            display: false, // Remove the gridlines on the y-axis
                        },
                        ticks: {
                            color: theme.color,
                            font: {
                                size: 10, // Set the font size for y-axis labels
                                weight: 'bold', // Set the font weight for y-axis labels
                                family: 'Electrolize',
                            },
                        },
                    },
                    
                }
            }}
        />
    );
};

const styles = StyleSheet.create({
    container: {
        borderWidth: 0,
        borderColor: 'blue',
        paddingTop: 0,
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '94.5%',
        flex: 1,
    },
})

export default BubbleChart