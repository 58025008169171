export default function ProgParse(dataProp, table , chartType) {

    const brand = `${table}.brand`
    const channel = `${table}.channel`
    const kw_type = `${table}.kw_type`
    const site_url = `${table}.site_url`
    const impressions = `${table}.impressions`
    const clicks = `${table}.clicks`
    const cost = `${table}.cost`
    const regs = `${table}.regs`
    const ftds = `${table}.ftds`
    const ngr = `${table}.ngr`

    console.log(chartType)

    if (chartType === 'site_url') {

    var agg_result = [];
    dataProp.reduce(function (res, value) {
        if (!res[value[site_url]]) {
            res[value[site_url]] = {
                site_url: value[site_url],
                channel: value[channel],
                brand: value[brand],
                kw_type: value[kw_type],
                impressions: 0,
                clicks: 0,
                cost: 0,
                regs: 0,
                ftds: 0,
                ngr: 0
            };
            agg_result.push(res[value[site_url]]);
        }
        res[value[site_url]].impressions += parseInt(value[impressions]) || 0;
        res[value[site_url]].clicks += parseInt(value[clicks]) || 0;
        res[value[site_url]].cost += parseInt(value[cost]) || 0;
        res[value[site_url]].regs += parseInt(value[regs]) || 0;
        res[value[site_url]].ftds += parseInt(value[ftds]) || 0;
        res[value[site_url]].ngr += parseInt(value[ngr]) || 0;

        return res;
    }, {});

    agg_result = agg_result.map((obj) => {
        const spend = obj.cost;
        const ftds = obj.ftds;
        const cpa = spend === 0 || ftds === 0 || isNaN(ftds) ? 0 : spend / ftds;
        return {
            ...obj,
            cpa: parseInt(cpa),
        };
    });
    }

    else if (chartType === 'kw_type') {

        var agg_result = [];
        dataProp.reduce(function (res, value) {
            if (!res[value[kw_type]]) {
                res[value[kw_type]] = {
                    channel: value[channel],
                    brand: value[brand],
                    kw_type: value[kw_type],
                    impressions: 0,
                    clicks: 0,
                    cost: 0,
                    regs: 0,
                    ftds: 0,
                    ngr: 0
                };
                agg_result.push(res[value[kw_type]]);
            }
            res[value[kw_type]].impressions += parseInt(value[impressions]) || 0;
            res[value[kw_type]].clicks += parseInt(value[clicks]) || 0;
            res[value[kw_type]].cost += parseInt(value[cost]) || 0;
            res[value[kw_type]].regs += parseInt(value[regs]) || 0;
            res[value[kw_type]].ftds += parseInt(value[ftds]) || 0;
            res[value[kw_type]].ngr += parseInt(value[ngr]) || 0;
    
            return res;
        }, {});
    
        agg_result = agg_result.map((obj) => {
            const spend = obj.cost;
            const ftds = obj.ftds;
            const cpa = spend === 0 || ftds === 0 || isNaN(ftds) ? 0 : spend / ftds;
            return {
                ...obj,
                cpa: parseInt(cpa),
            };
        });
        }

        else if (chartType === 'channel') {

            var agg_result = [];
            dataProp.reduce(function (res, value) {
                if (!res[value[channel]]) {
                    res[value[channel]] = {
                        channel: value[channel],
                        brand: value[brand],
                        impressions: 0,
                        clicks: 0,
                        cost: 0,
                        regs: 0,
                        ftds: 0,
                        ngr: 0
                    };
                    agg_result.push(res[value[channel]]);
                }
                res[value[channel]].impressions += parseInt(value[impressions]) || 0;
                res[value[channel]].clicks += parseInt(value[clicks]) || 0;
                res[value[channel]].cost += parseInt(value[cost]) || 0;
                res[value[channel]].regs += parseInt(value[regs]) || 0;
                res[value[channel]].ftds += parseInt(value[ftds]) || 0;
                res[value[channel]].ngr += parseInt(value[ngr]) || 0;
        
                return res;
            }, {});
        
            agg_result = agg_result.map((obj) => {
                const spend = obj.cost;
                const ftds = obj.ftds;
                const cpa = spend === 0 || ftds === 0 || isNaN(ftds) ? 0 : spend / ftds;
                return {
                    ...obj,
                    cpa: parseInt(cpa),
                };
            });
            }

    return agg_result

}