import React, { useState } from 'react';
import { View, StyleSheet, Dimensions } from 'react-native'
import FilterPanel from '../Components/FilterPanel';
import Fab from '@mui/material/Fab';
import NavigationIcon from '@mui/icons-material/Navigation';
import ModalChartType from '../Modals/ModalChartType';
import ChartPanelLarge from './ChartPanelLarge';
import AddchartTwoToneIcon from '@mui/icons-material/AddchartTwoTone';

const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;
const responsiveText = windowWidth * 0.006
const responsiveImage = windowWidth * 0.0125

const ChartPanel = (props) => {

    const theme = props.theme
    const windowHeight = props.windowHeight
    const dataset = props.dataProp
    const handleStateChange = props.handleStateChange
    const propObject = props.propObject
    const barBrands = props.barBrands
    const barChannels = props.barChannels
    const barTypes = props.barTypes
    
    const [chartFilterVisible, setmodalVisible] = useState(false);
    const [modalType, setModalType] = useState('chart')
    const [chartClick, setChartClick] = useState('Daily Line')
    const [axisClick, setAxisClick] = useState('Time')
    const [metric1Click, setMetric1] = useState('Spend')
    const [metric2Click, setMetric2] = useState('FTDs')

    const chartConfig = {
        type: chartClick,
        xAxis: axisClick,
        metric1: metric1Click,
        metric2: metric2Click
    }

    const getUniqueValues = (arr, key) => {
        const uniqueSet = new Set(arr.map(item => item[key]));
        return [...uniqueSet];
    };

    const uniqueBrands = getUniqueValues(dataset.agg_brand_result, 'brand');
    const uniqueChannels = getUniqueValues(dataset.agg_channel_result, 'channel');
    const uniqueTypes = getUniqueValues(dataset.agg_type_result, 'kw_type');

    function chartClickHandler(props) {
        setChartClick(props)
        handleModalButton(false)
    }

    function axisClickHandler(props) {
        setAxisClick(props)
        handleModalButton(false)
    }

    function metric1Handler(props) {
        setMetric1(props)
        handleModalButton(false)
    }

    function metric2Handler (props) {
        setMetric2(props)
        handleModalButton(false)
    }


    const handleModalButton = (prop) => {
        setmodalVisible(false)
    }

    return (
        <View style={[styles.container, { backgroundColor: theme.backgroundColor }]}>
            <FilterPanel
                style={{
                    height: '10%',
                    borderColor: theme.color,
                    borderWidth: 1,
                    width: '100%',
                    fontFamily: 'Electrolize'
                }}
                uniqueBrands={barBrands}
                uniqueChannels={barChannels}
                uniqueTypes={barTypes}
                handleStateChange={handleStateChange}
                propObject={propObject}
                theme={theme}
            />
            <TopPanel
                theme={theme}
                chartClickHandler={chartClickHandler}
                axisClickHandler={axisClickHandler}
                metric1Handler={metric1Handler}
                metric2Handler={metric2Handler}
                handleModalButton={handleModalButton}
                chartFilterVisible={chartFilterVisible}
                setmodalVisible={setmodalVisible}
                setModalType={setModalType}
                chartConfig={chartConfig}
                dataset={dataset}
                modaltype={modalType}
                uniqueBrands={uniqueBrands}
                uniqueChannels={uniqueChannels}
                uniqueTypes={uniqueTypes}
            />
        </View>
    )

}

const TopPanel = (props) => {

    const theme = props.theme
    const chartClickHandler = props.chartClickHandler
    const axisClickHandler = props.axisClickHandler
    const metric1Handler = props.metric1Handler
    const metric2Handler = props.metric2Handler
    const handleModalButton = props.handleModalButton
    const chartFilterVisible = props.chartFilterVisible
    const setmodalVisible = props.setmodalVisible
    const modaltype = props.modaltype
    const setModalType = props.setModalType
    const chartConfig = props.chartConfig
    const dataset = props.dataset
    const uniqueBrands = props.uniqueBrands
    const uniqueChannels = props.uniqueChannels
    const uniqueTypes = props.uniqueTypes

    console.log(chartConfig)

    let typeDisabled = false;

    if (chartConfig.type !== 'Mixed') {
    typeDisabled = true;
    }

    let groupingDisabled = true;

    if (chartConfig.type !== 'Daily Bar' && chartConfig.type !== 'Daily Line' && chartConfig.type !== 'Monthly Line' && chartConfig.type !== 'Monthly Bar') {
    groupingDisabled = false;
    }

    return (
        <View style={[styles.topPanelParent , {borderColor: theme.color}]}>
            <View style={styles.topPanelChildLarge}>
                <ChartPanelLarge
                    modaltype={modaltype}
                    chartConfig={chartConfig}
                    dataset={dataset}
                    theme={theme}
                    uniqueBrands={uniqueBrands}
                    uniqueChannels={uniqueChannels}
                    uniqueTypes={uniqueTypes}
                    
                />

            </View>
            <View style={styles.topPanelChildSmall}>

                <FabBox
                    buttonText={"Select Chart Type"}
                    theme={theme}
                    disabled={false}
                    setmodalVisible={setmodalVisible}
                    handleModalButton={handleModalButton}
                    chartFilterVisible={chartFilterVisible}
                    chartClickHandler={chartClickHandler}
                    axisClickHandler={axisClickHandler}
                    metric1Handler={metric1Handler}
                    metric2Handler={metric2Handler}
                    modaltype={'chart'}
                    setModalType={setModalType}
                    modalView={modaltype}
                >
                </FabBox>

                <FabBox
                    buttonText={"Select Grouping"}
                    theme={theme}
                    disabled={groupingDisabled}
                    setmodalVisible={setmodalVisible}
                    handleModalButton={handleModalButton}
                    chartFilterVisible={chartFilterVisible}
                    chartClickHandler={chartClickHandler}
                    axisClickHandler={axisClickHandler}
                    metric1Handler={metric1Handler}
                    metric2Handler={metric2Handler}
                    modaltype={'axis'}
                    setModalType={setModalType}
                    modalView={modaltype}
                />
                <FabBox
                    buttonText={"Select Metric 1"}
                    theme={theme}
                    disabled={false}
                    setmodalVisible={setmodalVisible}
                    handleModalButton={handleModalButton}
                    chartFilterVisible={chartFilterVisible}
                    chartClickHandler={chartClickHandler}
                    axisClickHandler={axisClickHandler}
                    metric1Handler={metric1Handler}
                    metric2Handler={metric2Handler}
                    modaltype={'metric1'}
                    setModalType={setModalType}
                    modalView={modaltype}
                />
                <FabBox
                    buttonText={"Select Metric 2"}
                    theme={theme}
                    disabled={typeDisabled}
                    setmodalVisible={setmodalVisible}
                    handleModalButton={handleModalButton}
                    chartFilterVisible={chartFilterVisible}
                    chartClickHandler={chartClickHandler}
                    axisClickHandler={axisClickHandler}
                    metric1Handler={metric1Handler}
                    metric2Handler={metric2Handler}
                    modaltype={'metric2'}
                    setModalType={setModalType}
                    modalView={modaltype}
                />


            </View>

        </View>
    )
}

const FabBox = (props) => {

    const buttonText = props.buttonText
    const theme = props.theme
    const setmodalVisible = props.setmodalVisible
    const chartFilterVisible = props.chartFilterVisible
    const handleModalButton = props.handleModalButton
    const chartClickHandler = props.chartClickHandler
    const axisClickHandler = props.axisClickHandler
    const metric1Handler = props.metric1Handler
    const metric2Handler = props.metric2Handler
    const modalType = props.modaltype
    const setModalType = props.setModalType
    const modalView = props.modalView
    const disabledProp = props.disabled

    return (
        <View
            style={{
                padding: '10%',
                paddingLeft: '20%',
                paddingRight: '20%',
                borderWidth: 0.1,
                borderColor: theme.color,
                height: '25%',
                alignItems: 'center',
                justifyContent: 'center'
            }}
        >
            <Fab variant="extended"
                onClick={() => { setmodalVisible(true); setModalType(modalType) }}
                modalType={modalType}
                disabled={disabledProp}
                sx={{
                    color: 'white',
                    backgroundColor: theme.backgroundColor2,
                    width: '100%',
                    height: '50%',
                    fontSize: responsiveText,
                }}>
                <AddchartTwoToneIcon sx={{
                    mr: 1,
                    color: 'white',
                    fontSize: responsiveImage
                }} />
                {buttonText}
            </Fab>
            <ModalChartType
                handleModalButton={handleModalButton}
                chartFilterVisible={chartFilterVisible}
                chartClickHandler={chartClickHandler}
                axisClickHandler={axisClickHandler}
                metric1Handler={metric1Handler}
                metric2Handler={metric2Handler}
                modalType={modalView}
                theme={theme}
            />
        </View>
    )
}


const styles = StyleSheet.create({
    container: {
        borderWidth: 0,
        borderColor: 'yellow',
        paddingTop: 0,
        flexDirection: 'column',
        width: '100%',
        height: windowHeight * 0.931,
        
    },
    topPanelParent: {
        width: '100%',
        height: '90%',
        borderWidth: 0,
        paddingTop: '1.5%',
        paddingBottom: '1%',
        flexDirection: 'row',
    },
    topPanelChildLarge: {
        width: '80%',
        height: '100%',
        borderWidth: 0,
        borderColor: 'yellow',
    },
    topPanelChildSmall: {
        width: '20%',
        height: '100%',
        borderWidth: 0,
        borderColor: 'yellow',
        flexDirection: 'column',
        padding: '1%'
    }
})

export default ChartPanel