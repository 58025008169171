export default function AttributionCubeQuery (chartConfig , cubeFilters, cubeTable, start, end ) {

    const xAxis = chartConfig.xAxis

    const xAxisLower = xAxis.toLowerCase().replace(/ /g, '_');

        return {
            "measures": [
                `${cubeTable}.ftd_custom`,
                `${cubeTable}.ftd_ft`,
                `${cubeTable}.ftd_lt`,
            ],
            "order": [
                [
                  `${cubeTable}.ftd_custom`,
                  "desc"
                ]
              ],
            "dimensions": [
                `${cubeTable}.${xAxisLower}`
            ],
            "timeDimensions": [
                {
                  "dimension": `${cubeTable}.timestamp`,
                  "dateRange": [
                    start,
                    end
                  ]
                }
              ],
            "filters": cubeFilters
        }
    }