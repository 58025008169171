import React, { useState } from 'react';
import { Modal, 
    View, 
    StyleSheet, 
    Dimensions, 
    Text,
    Pressable } from 'react-native';
import DashGraphType from './DashGraphModal/DashGraphType';
import DashGraphAxis from './DashGraphModal/DashGraphAxis';
import DashGraphMetric1 from './DashGraphModal/DashGraphMetric1';
import DashGraphMetric2 from './DashGraphModal/DashGraphMetric2';

const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;
const responsiveTextSmall = windowWidth * 0.02

const DashGraphModal = (props) => {

    const theme = props.theme
    const visibleModal = props.visible
    const setVisible = props.setVisible
    const mainGraphHandler = props.mainGraphHandler
    const uniqueMetrics = props.uniqueMetrics
    const uniqueDimensions = props.uniqueDimensions
    const currentReport = props.currentReport
    const reportIndex = props.reportIndex
    const dashConfig = props.dashConfig
    const currentType = dashConfig[reportIndex][currentReport].type
    const currentAxis = dashConfig[reportIndex][currentReport].xAxis
    const currentMetric1 = dashConfig[reportIndex][currentReport].metric1
    const currentMetric2 = dashConfig[reportIndex][currentReport].metric2
    const metricVisible = currentType === 'mixed' || currentType === 'table';

    return (
        <Modal
            visible={visibleModal}
            style={styles.modalParent}
            transparent={true}
            >
        <Pressable 
            style={styles.modalContainer}
            onPress={() => setVisible(false)}
            >
            <View style={[styles.modalWindow , {
                backgroundColor : theme.backgroundColor,
                borderColor: theme.color
            }]}>

            <DashGraphType
                theme={theme}
                uniqueMetrics={uniqueMetrics}
                uniqueDimensions={uniqueDimensions}
                mainGraphHandler={mainGraphHandler}
                currentType={currentType}
                setVisible={setVisible}
                />
            <DashGraphAxis
                theme={theme}
                uniqueMetrics={uniqueMetrics}
                uniqueDimensions={uniqueDimensions}
                mainGraphHandler={mainGraphHandler}
                currentType={currentAxis}
                setVisible={setVisible}
                
                />
            <DashGraphMetric1
                currentMetric1={currentMetric1}
                currentMetric2={currentMetric2}
                theme={theme}
                uniqueMetrics={uniqueMetrics}
                uniqueDimensions={uniqueDimensions}
                mainGraphHandler={mainGraphHandler}
                setVisible={setVisible}
                />
                {metricVisible && 
            <DashGraphMetric2
                currentMetric1={currentMetric1}
                currentMetric2={currentMetric2}
                theme={theme}
                uniqueMetrics={uniqueMetrics}
                uniqueDimensions={uniqueDimensions}
                mainGraphHandler={mainGraphHandler}
                setVisible={setVisible}
                metricVisible={metricVisible}
                />}

            </View>


        </Pressable>


        </Modal>
    )
}


const styles = StyleSheet.create({
    modalParent: {
        padding: 20,
        borderRadius: 20,
        
    },
    modalContainer: {

        width: windowWidth * 1,
        height: windowHeight * 1,
        backgroundColor: 'rgba(0, 0, 0, 0.7)',
        alignItems: 'center',
        justifyContent: 'center',
    },
    modalWindow: {
        margin: 0,
        height: '46%',
        width: '66.5%',
        borderWidth: 2,
        borderRadius: 15,
        padding: '1%',
        alignItems: 'center',
        justifyContent: 'center',

    }
});


export default DashGraphModal