import React, { useState , useEffect} from 'react';
import { View } from 'react-native'
import { themes } from '../themes.js'
import axios from 'axios';
import TextBox from '../Components/TextBox';
import ChatComponent from '../Components/ChatComponent.js'


const ChatContainer = (props) => {

    const [conversationHistory, setConversationHistory] = useState([]);
    const [apiResponses, setApiResponses] = useState([]);
    const [inputText, setinputText] = useState('');
    const [convoHistory, setConvoHistory] = useState([]);
    const theme = props.theme

    function questionHandler(prop) {
        setConvoHistory(prevHistory => [...prevHistory, { question: prop }]);
    }

    function answerHandler(prop) {
        setConvoHistory(prevHistory => [...prevHistory, { answer: prop }]);
    }

    const dataDefault = [
        { 'type': 'test' },
        { 'question': 'what is my overall cost per ftd for channel PPC?' },
        { 'data': [{ 'test': 'test' }] },
        { 'keys': [] }
    ]

    const [data, setData] = useState(dataDefault);

    const fetchData = async (prop) => {


        const defaultValue = 'What is my overall cost per FTD?';
        try {
            const body = {
                // Your request body data
                question: prop !== null ? prop : defaultValue,
            };

            const headers = {
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Headers': 'Content-Type',
                'Access-Control-Allow-Methods': 'POST',
                'Access-Control-Max-Age': '86400',
                'Content-Type': 'application/json',
                'Accept': '*/*'
            };


            const response = await axios.post('https://bekrhmbunirsg3heqsefgivypu0qaclb.lambda-url.eu-central-1.on.aws/', body, {headers});
            console.log(response)
            setData(response.data);
        } catch (error) {
            console.error('Error:', error);
        }
    };

    function inputHandler(prop) {
        setinputText(prop)
        fetchData(prop)
        questionHandler({'question' : prop})
    }

    const response_type = data[0]['type']

    let output;

    useEffect(() => {
        if (data.length > 0) {
          const response_type = data[0]['type'];
          if (response_type === 'single_value') {
            var response_question = data[1]['question'];
            var response_data = data[2]['data'][0];
            var response_obj_keys_raw = Object.keys(response_data);
            var response_obj_value_raw = Object.values(response_data);
            var response_obj_keys = response_obj_keys_raw[0];
            var response_obj_value = response_obj_value_raw[0].toFixed(2);
            var response_keys = data[0]['keys'];
            var output = { response_type, response_obj_value, response_obj_keys, response_question };
            answerHandler(output);
          }

          if (response_type === 'line_chart') {
            var response_question = data[1]['question']
            var response_data = data[2]['data']
            const replacedData = response_data.replace(/'/g, '"');
            const preprocessedString = replacedData.replace(/None/g, 0);
            const parsedJSON = JSON.parse(preprocessedString)
        
            const keyNames = Object.keys(parsedJSON[0]);
            const separatedValues = {};
        
            keyNames.forEach(key => {
              separatedValues[key] = parsedJSON.map(item => item[key]);
            });
        
            const key1 = keyNames[0]
            const values1 = keyNames[1]
        
            const dateArray = (separatedValues[key1])
            const value1Array = (separatedValues[values1])
        
            var output = {response_type , dateArray , value1Array}
            answerHandler(output);
         }

         if (response_type === 'bar_chart') {
            var response_question = data[1]['question']
            var response_data = data[2]['data']
            const replacedData = response_data.replace(/'/g, '"');
            const preprocessedString = replacedData.replace(/None/g, 0);
          
            const parsedJSON = JSON.parse(preprocessedString)
        
            const keyNames = Object.keys(parsedJSON[0]);
            const separatedValues = {};
        
            keyNames.forEach(key => {
              separatedValues[key] = parsedJSON.map(item => item[key]);
            });
        
            const key1 = keyNames[0]
            const values1 = keyNames[1]
        
            const dateArray = (separatedValues[key1])
            const value1Array = (separatedValues[values1])
        
            var output = {response_type, dateArray , value1Array}
            answerHandler(output);
        }

        if (response_type === 'table') {
            var response_question = data[1]['question']
            var response_data = data[2]['data']
            
            const replacedData = response_data.replace(/'/g, '"');
            const preprocessedString = replacedData.replace(/None/g, 0);
          
            const parsedJSON = JSON.parse(preprocessedString)
            const keyNames = Object.keys(parsedJSON[0]);
        
            var output = {response_type, parsedJSON}
            answerHandler(output);
          }
        }


      }, [data]);
    
    return (
        <View>
            <TextBox
                inputHandler={inputHandler}
                theme={theme}
            />
            <ChatComponent 
                convoHistory={convoHistory}
                output={output}
                theme={theme}

            />

        </View>
    )


}

export default ChatContainer