export default function parseDailySumFunction (prop, filters) {

    var dataset = prop
    

    if (!dataset || dataset.length === undefined) {
      return null;
    }
    
    dataset = dataset.filter((obj) => {
        const date = new Date(obj.date);
        const startDate = new Date(filters.start);
        const endDate = new Date(filters.end);
        return (
          date >= startDate &&
          date <= endDate &&
          Object.entries(filters)
    
            .filter(([key, value]) => key !== 'start' && key !== 'end')
            .every(([key, value]) => {
              if (value === 'Total') {
                return true;
              }
              if (key === 'brand') {
                return obj[key] === value;
              }
              if (key === 'channel') {
                return obj[key] === value;
              }
              if (key === 'kw_type') {
                return obj[key] === value;
              }
    
              return false;
            })
        );
      });

      const  agg_result = dataset

      const getUniqueValues = (arr, key) => {
        const uniqueSet = new Set(arr.map(item => item[key]));
        return [...uniqueSet];
      };

      const barBrands = getUniqueValues(dataset, 'brand');
      const barChannels = getUniqueValues(dataset, 'channel');
      const barTypes = getUniqueValues(dataset, 'kw_type');
        
      var agg_daily_result = [];
      dataset.reduce(function (res, value) {
        if (!res[value.date]) {
          res[value.date] = {
            date: value.date,
            impressions: 0,
            clicks: 0,
            spend: 0,
            regs: 0,
            ftds: 0,
          };
          agg_daily_result.push(res[value.date]);
        }
        res[value.date].impressions += parseInt(value.impressions);
        res[value.date].clicks += parseInt(value.clicks);
        res[value.date].spend += parseInt(value.spend);
        res[value.date].regs += parseInt(value.regs);
        res[value.date].ftds += parseInt(value.ftds);
    
        return res;
      }, {});
         
        agg_daily_result = agg_daily_result.map((obj) => {
          const spend = obj.spend;
          const ftds = obj.ftds;
          const cpa = spend === 0 || ftds === 0 || isNaN(ftds) ? 0 : spend / ftds;
          return {
            ...obj,
            cpa: parseInt(cpa),
          };
        });
      
        if (!agg_daily_result) {
          return null;
        }
      
        var agg_type_result = [];
      
        dataset.reduce(function (res, value) {
          if (!res[value.kw_type]) {
            res[value.kw_type] = {
              kw_type: value.kw_type,
              impressions: 0,
              clicks: 0,
              spend: 0,
              regs: 0,
              ftds: 0,
            };
            agg_type_result.push(res[value.kw_type]);
          }
          res[value.kw_type].impressions += parseInt(value.impressions);
          res[value.kw_type].clicks += parseInt(value.clicks);
          res[value.kw_type].spend += parseInt(value.spend);
          res[value.kw_type].regs += parseInt(value.regs);
          res[value.kw_type].ftds += parseInt(value.ftds);
          return res;
        }, {});
      
        agg_type_result = agg_type_result.map((obj) => {
          const spend = obj.spend;
          const ftds = obj.ftds;
          const cpa = spend === 0 || ftds === 0 || isNaN(ftds) ? 0 : spend / ftds;
          return {
            ...obj,
            cpa: parseInt(cpa),
          };
        });
  
        var agg_channel_result = [];
  
        dataset.reduce(function (res, value) {
          if (!res[value.channel]) {
            res[value.channel] = {
              channel: value.channel,
              impressions: 0,
              clicks: 0,
              spend: 0,
              regs: 0,
              ftds: 0,
            };
            agg_channel_result.push(res[value.channel]);
          }
          res[value.channel].impressions += parseInt(value.impressions);
          res[value.channel].clicks += parseInt(value.clicks);
          res[value.channel].spend += parseInt(value.spend);
          res[value.channel].regs += parseInt(value.regs);
          res[value.channel].ftds += parseInt(value.ftds);
      
          return res;
        }, {});
  
  
    agg_channel_result = agg_channel_result.map((obj) => {
      const spend = obj.spend;
      const ftd = obj.ftds;
      const cpa = spend === 0 || ftd === 0 || isNaN(ftd) ? 0 : spend / ftd;
      return {
        ...obj,
        cpa: parseInt(cpa),
      };
    });
  
    var agg_brand_result = [];
  
    dataset.reduce(function (res, value) {
      if (!res[value.brand]) {
        res[value.brand] = {
          brand: value.brand,
          impressions: 0,
          clicks: 0,
          spend: 0,
          regs: 0,
          ftds: 0,
        };
        agg_brand_result.push(res[value.brand]);
      }
      res[value.brand].impressions += parseInt(value.impressions);
      res[value.brand].clicks += parseInt(value.clicks);
      res[value.brand].spend += parseInt(value.spend);
      res[value.brand].regs += parseInt(value.regs);
      res[value.brand].ftds += parseInt(value.ftds);
  
      return res;
    }, {});
  
    agg_brand_result = agg_brand_result.map((obj) => {
      const spend = obj.spend;
      const ftd = obj.ftds;
      const cpa = spend === 0 || ftd === 0 || isNaN(ftd) ? 0 : spend / ftd;
      return {
        ...obj,
        cpa: parseInt(cpa),
      };
    });
    
  var agg_monthly_result = [];
  dataset.reduce(function (res, value) {
    // Extract year and month from the date string
    var dateParts = value.date.split('-');
    var year = dateParts[0];
    var month = dateParts[1];
  
    var monthYear = year + '-' + month;
  
    if (!res[monthYear]) {
      res[monthYear] = {
        date: monthYear, // Store the year and month as the date
        impressions: 0,
        clicks: 0,
        spend: 0,
        regs: 0,
        ftds: 0,
      };
      agg_monthly_result.push(res[monthYear]);
    }
  
    res[monthYear].impressions += parseInt(value.impressions);
    res[monthYear].clicks += parseInt(value.clicks);
    res[monthYear].spend += parseInt(value.spend);
    res[monthYear].regs += parseInt(value.regs);
    res[monthYear].ftds += parseInt(value.ftds);
  
    return res;
    }, {});

    agg_monthly_result = agg_monthly_result.map((obj) => {
      const spend = obj.spend;
      const ftd = obj.ftds;
      const cpa = spend === 0 || ftd === 0 || isNaN(ftd) ? 0 : spend / ftd;
      return {
        ...obj,
        cpa: parseInt(cpa),
      };
    });
      
        return {
          agg_daily_result,
          agg_type_result,
          agg_brand_result,
          agg_channel_result,
          agg_monthly_result,
          barBrands,
          barChannels,
          barTypes,
          agg_result
        }
      
      }