export default function parseDailyFunction (prop, filters) {

    var dataset = prop;
  
    if (!dataset || dataset.length === undefined) {
      return null;
    }
  
        dataset = dataset.filter((obj) => {
          const date = new Date(obj.date);
          const startDate = new Date(filters.start);
          const endDate = new Date(filters.end);
          return (
            date >= startDate &&
            date <= endDate &&
            Object.entries(filters)
      
              .filter(([key, value]) => key !== 'start' && key !== 'end')
              .every(([key, value]) => {
                if (value === 'Total') {
                  return true;
                }
                if (key === 'brand') {
                  return obj[key] === value;
                }
                if (key === 'channel') {
                  return obj[key] === value;
                }
                if (key === 'kw_type') {
                  return obj[key] === value;
                }
      
                return false;
              })
          );
        });
  
        const  agg_result = dataset
        
     
        var agg_monthly_result = [];
        dataset.reduce(function (res, value) {
          if (!res[value.date]) {
            res[value.date] = {
              date: value.date,
              month: value.month,
              year: value.year,
              impressions: 0,
              clicks: 0,
              spend: 0,
              regs: 0,
              ftds: 0,
              ngr: 0
            };
            agg_monthly_result.push(res[value.date]);
          }
          res[value.date].impressions += parseInt(value.impressions);
          res[value.date].clicks += parseInt(value.clicks);
          res[value.date].spend += parseInt(value.spend);
          res[value.date].regs += parseInt(value.regs);
          res[value.date].ftds += parseInt(value.ftds);
          res[value.date].ngr += parseInt(value.ngr);
          return res;
        }, {});
          
      
          agg_monthly_result = agg_monthly_result.map((obj) => {
          const spend = obj.spend;
          const ftds = obj.ftds;
          const ngr = obj.ngr
          const cpa = spend === 0 || ftds === 0 || isNaN(ftds) ? 0 : spend / ftds;
          const roas = spend === 0 || ngr === 0 || isNaN(ngr) ? 0 : ngr / spend;
          return {
            ...obj,
            cpa: cpa,
            roas: parseFloat(roas).toFixed(2),
          };
        });

        
      
        if (!agg_monthly_result) {
          return null;
        }

  
  var agg_yearly_result = [];
  dataset.reduce(function (res, value) {
    if (!res[value.year]) {
      res[value.year] = {
        year: value.year,
        impressions: 0,
        clicks: 0,
        spend: 0,
        regs: 0,
        ftds: 0,
        ngr: 0
      };
      agg_yearly_result.push(res[value.year]);
    }
    res[value.year].impressions += parseInt(value.impressions);
    res[value.year].clicks += parseInt(value.clicks);
    res[value.year].spend += parseInt(value.spend);
    res[value.year].regs += parseInt(value.regs);
    res[value.year].ftds += parseInt(value.ftds);
    res[value.year].ngr += parseInt(value.ngr);
    return res;
  }, {});
  
    agg_yearly_result = agg_yearly_result.map((obj) => {
      const spend = obj.spend;
      const ftd = obj.ftds;
      const cpa = spend === 0 || ftd === 0 || isNaN(ftd) ? 0 : spend / ftd;
      return {
        ...obj,
        cpa: parseInt(cpa),
      };
    }); 
  
      
        return {
            agg_yearly_result,
          agg_monthly_result
        }
      
      }