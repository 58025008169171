import React, { useState } from 'react';
import { View, 
    StyleSheet, 
    Dimensions, 
    Text,
    Pressable,
    ScrollView
 } from 'react-native'
import FilterPanel from '../Components/FilterPanel';
import { useCubeQuery } from '@cubejs-client/react';
import LoadingComponent from '../Components/Loader';
import AttributionCubeQuery from './AttributionCubeQuery';
import AttributionPanelLarge from './AtttributionPanelLarge';

const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;

const AttributionPanel = (props) => {

    const theme = props.theme
    const windowHeight = props.windowHeight
    const dataset = props.dataProp
    const handleStateChange = props.handleStateChange
    const propObject = props.propObject
    const barBrands = props.barBrands
    const barChannels = props.barChannels
    const barTypes = props.barTypes
    const filters = props.filters
    const cubeDimensions = props.uniqueDimensions
    const chartType = props.chartType
    const cubeTable = props.cubeTable

    const start = filters.start
    const end = filters.end
    const brand = (filters.brand === "Total") ? "null" : filters.brand;
    const channel = (filters.channel === "Total") ? "null" : filters.channel;
    const type = (filters.kw_type === "Total") ? "null" : filters.kw_type;

    const brandOp = (filters.brand === "Total") ? "notEquals" : "equals";
    const channelOp = (filters.channel === "Total") ? "notEquals" : "equals";
    const typeOp = (filters.kw_type === "Total") ? "notEquals" : "equals";

    const [chartClick, setChartClick] = useState('Grouped Bar')
    const [axisClick, setAxisClick] = useState('Brand')
    const [metric1Click, setMetric1] = useState('FTDs')
    const [metric2Click, setMetric2] = useState('NGR')


    const chartConfig = {
        type: chartClick,
        xAxis: axisClick,
        metric1: metric1Click,
        metric2: metric2Click
    }

    const cubeFilters = [
        {
            "member": `${cubeTable}.brand`,
            "operator": `${brandOp}`,
            "values": [
                `${brand}`
            ]
        },
        {
            "member": `${cubeTable}.channel`,
            "operator": `${channelOp}`,
            "values": [
                `${channel}`
            ]
        },
        {
            "member": `${cubeTable}.kw_type`,
            "operator": `${typeOp}`,
            "values": [
                `${type}`
            ]
        }
    ]

    const cubeQuery = AttributionCubeQuery(chartConfig , cubeFilters, cubeTable, start, end )
    const { resultSet, isLoading, error, progress } = useCubeQuery(cubeQuery);
    console.log(cubeQuery)

    if (!resultSet) {
        return (
            <LoadingComponent theme={theme} />
        );
    }

    const cubeResponse = resultSet.loadResponse.results[0].data

    const uniqueBrands = barBrands;
    const uniqueChannels = barChannels;
    const uniqueTypes = barTypes;

    function axisClickHandler(props) {
        setAxisClick(props)
    }

    function metric1Handler(props) {
        setMetric1(props)
    }

    return (
        <View style={[styles.container, { backgroundColor: theme.backgroundColor }]}>
            <FilterPanel
                style={{
                    height: '10%',
                    borderColor: theme.color,
                    borderWidth: 1,
                    width: '100%',
                    fontFamily: 'Electrolize'
                }}
                uniqueBrands={barBrands}
                uniqueChannels={barChannels}
                uniqueTypes={barTypes}
                handleStateChange={handleStateChange}
                propObject={propObject}
                theme={theme}
            />
            <TopPanel
                theme={theme}
                axisClickHandler={axisClickHandler}
                metric1Handler={metric1Handler}
                cubeDimensions={cubeDimensions}
                cubeResponse={cubeResponse}
                chartType={chartType}
                cubeTable={cubeTable}

            />
        </View>
    )

}

const DimensionBox = ({cubeDimensions, axisClickHandler, theme}) => {
    
    return (
        <ScrollView style={{ flex: 1 }}>
          {cubeDimensions.map((item, index) => (
            <Pressable
              key={index}
              style={[[styles.pressable, 
                        index !== 0 && styles.marginTop , 
                            {borderColor : theme.color,
                            backgroundColor: theme.backgroundColor2
                            }]]} // Apply margin to all but the first Pressable
              onPress={() => axisClickHandler(item)}
            >
              <Text
                style={{color: theme.backgroundColor}}
              >{item}</Text>
            </Pressable>
          ))}
        </ScrollView>
      );
    };



const TopPanel = (props) => {

    const theme = props.theme
    const chartConfig = props.chartConfig
    const cubeDimensions = props.cubeDimensions
    const axisClickHandler = props.axisClickHandler
    const metric1Handler = props.metric1Handler
    const cubeResponse = props.cubeResponse
    const chartType = props.chartType
    const cubeTable = props.cubeTable


    return (
        <View style={[styles.topPanelParent, { borderColor: theme.color }]}>
            <View style={styles.topPanelChildLarge}>
            <AttributionPanelLarge
                theme={theme}
                cubeResponse={cubeResponse}
                chartType={chartType}
                cubeTable={cubeTable}
            />

            </View>
            <View style={styles.topPanelChildSmall}>
            <DimensionBox
                cubeDimensions={cubeDimensions}
                axisClickHandler={axisClickHandler}
                theme={theme}
                />



            </View>

        </View>
    )
}



const styles = StyleSheet.create({
    container: {
        borderWidth: 0,
        borderColor: 'yellow',
        paddingTop: 0,
        flexDirection: 'column',
        width: '100%',
        height: windowHeight * 0.931,

    },
    topPanelParent: {
        width: '100%',
        height: '90%',
        borderWidth: 0,
        paddingTop: '1.5%',
        paddingBottom: '1%',
        flexDirection: 'row',
    },
    topPanelChildLarge: {
        width: '81%',
        height: '100%',
        borderWidth: 0,
        borderColor: 'yellow',
    },
    topPanelChildSmall: {
        width: '19%',
        height: '100%',
        borderWidth: 0,
        borderColor: 'yellow',
        flexDirection: 'column',
        padding: '1%'
    },
    dimensionBox: {
        width: '100%', 
        height: '40%',
        alignItems: 'center',
        justifyContent: 'center',
        borderWidth: 1,
        borderRadius: 1,
        paddingBottom: '1%'

    },
    pressable: {
        width: '100%',
        padding: 10, // Add padding for space around text
        backgroundColor: 'white', // Optional background color
        alignItems: 'center',
        justifyContent: 'center',
        borderColor: 'red',
        borderWidth: 1,
        borderRadius: 5,
      },
      marginTop: {
        marginTop: 10, // Adjust the margin as needed
      },
    
})

export default AttributionPanel