
import React, { useState, useEffect } from 'react';
import { View, StyleSheet, Dimensions, Text, Pressable } from 'react-native'
import WebFont from 'webfontloader';
import { themes } from '../themes'


const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;

const OptionsClient = (props) => {

    const theme = props.theme
    const clientHandler = props.clientHandler
    const whiteMode = themes.white.backgroundColor
    const [pressedElement, setPressedElement] = useState(null);

    const handlePress = (client) => {
        setPressedElement(client);
        clientHandler(client)
        // Add any other logic you need when an element is pressed
      };
    

    useEffect(() => {
        WebFont.load({
            google: {
                families: ['Droid Sans', 'Electrolize']
            }
        });
    }, []);

    const selectedChild = {
        borderWidth: 5,
        borderColor: theme.backgroundColor,
        backgroundColor: theme.backgroundColor2
      }
    const selectedText = {
        color: theme.color, // Change this to your desired text color
      }


    return (
        <View style={styles.container}>
            <View style={styles.textContainer}>

                <Text style={[styles.text, { color: theme.color }]}>
                    Client Mode:
                </Text>

            </View>
            <View style={styles.themeContainer}>
                <View style={styles.themeRow}>
                <View style={[[styles.themeRowChild, {backgroundColor: whiteMode}], pressedElement === "All_Clients" && selectedChild]}>
                        <Pressable
                            onPress={() => handlePress("All_Clients")}
                        >
                            <Text style={[styles.text, pressedElement === "All_Clients" && selectedText]}> All Clients</Text>
                        </Pressable>

                    </View>
                    <View style={[[styles.themeRowChild, {backgroundColor: whiteMode}], pressedElement === "AFUN" && selectedChild]}>
                        <Pressable
                            onPress={() => handlePress("AFUN")}
                        >
                            <Text style={[styles.text, pressedElement === "AFUN" && selectedText]}> AFUN</Text>
                        </Pressable>

                    </View>

                </View>
                <View style={styles.themeRow}>
                <View style={[[styles.themeRowChild, {backgroundColor: whiteMode}], pressedElement === "FM" && selectedChild]}>
                        <Pressable
                            onPress={() => handlePress("FM")}
                        >
                            <Text style={[styles.text, pressedElement === "FM" && selectedText]}> Fortunate Media</Text>
                        </Pressable>

                    </View>
                    <View style={[[styles.themeRowChild, {backgroundColor: whiteMode}], pressedElement === "Rank" && selectedChild]}>
                        <Pressable
                            onPress={() => handlePress("Rank")}
                        >
                            <Text style={[styles.text, pressedElement === "Rank" && selectedText]}> Rank International</Text>
                        </Pressable>

                    </View>

                </View>


            </View>

        </View>
    )

}

export default OptionsClient

const styles = StyleSheet.create({
    container: {
        borderWidth: 1,
        borderColor: 'grey',
        paddingTop: 0,
        flexDirection: 'row',
        justifyContent: 'center',
        width: windowWidth * 0.9,
        height: windowHeight * 0.2,

    },
    text: {
        fontFamily: 'Electrolize',
        fontSize: 40
    },
    textContainer: {
        width: '40%',
        height: '100%',
        borderWidth: 0,
        borderColor: 'yellow',
        paddingLeft: '1.5%',
        justifyContent: 'center'
    },
    themeContainer: {
        width: '60%',
        height: '100%',
        borderWidth: 0,
        borderColor: 'yellow',
        flexDirection: 'column',
    },
    themeRow: {
        width: '100%',
        height: '50%',
        borderWidth: 0,
        borderColor: 'yellow',
        flexDirection: 'row',
    },
    themeRowChild: {
        width: '50%',
        height: '100%',
        borderWidth: 1,
        borderColor: 'grey',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 0
    },
    
})