import React, { useEffect } from 'react';
import { StyleSheet, View , Text} from 'react-native';
import { TextInput , IconButton } from "@react-native-material/core";
import LineChart from '../Charts/LineChart';
import BarChart from '../Charts/BarChart';
import Table from '../Charts/Table'
import WebFont from 'webfontloader';


const ChatComponent = (props) => {

    const dataArray = props.convoHistory
    const textView = JSON.stringify(dataArray)
    const theme = props.theme

    useEffect(() => {
      WebFont.load({
          google: {
              families: ['Droid Sans', 'Electrolize']
          }
      });
  }, []);

  const container = { flex: 1, paddingLeft: 30, paddingRight : 30, justifyContent: 'flex-start', backgroundColor: theme.backgroundColor , borderColor : 'blue' , borderWidth : 2 , backgroundColor: theme.backgroundColor}

      return (
        <div style={container}>
          {dataArray.map((item, index) => {
            if (item.question) {
              return <TextComponent key={index} text={item.question.question} theme={theme}/>;
            } else if (item.answer.response_type === 'line_chart') {
              return <AnswerLine key={index} dataProp={item.answer.value1Array} labels={item.answer.dateArray} theme={theme}/>;
            } else if (item.answer.response_type === 'bar_chart') {
              return <AnswerBar key={index} dataProp={item.answer.value1Array} labels={item.answer.dateArray} theme={theme}/>;            
            } else if (item.answer.response_type === 'single_value') {
              return <ViewComponent key={index} answer={item.answer.response_obj_value} question={item.answer.response_question} theme={theme}/>
            } else if (item.answer.response_type === 'table') {
              return <AnswerTable key={index} dataProp={item.answer.parsedJSON} theme={theme}/>
            }
            
            return null; // If the object doesn't have 'question' or 'answer' property
          })}
        </div>
      );
    };

    
    const TextComponent = ({ text , theme}) => {

      const message = {width : '100%' , color : theme.color, borderWidth : 1 , flexDirection : 'column' , alignItems :  'center' , fontSize: 30 , textAlign : 'left', fontFamily: 'Electrolize', borderColor: 'blue' , borderWidth: 1, borderRadius: 10};

      return <p style={message}>{text}</p>;
    };
    
    const ViewComponent = ({ answer , question , theme }) => {

      const answerStyle = {width : '100%' , color : theme.backgroundColor2, borderWidth : 1 , flexDirection : 'column' , alignItems :  'center' , fontSize: 50 , textAlign : 'right', fontFamily: 'Electrolize'}

      let outputtext = '';

      if (question.includes('cost')) {
        return <div style={answerStyle}>Є{answer}</div>;
      }

      if (question.includes('rate')) {
        return <div style={answerStyle}>{answer}%</div>;
      }

      return <div style={answerStyle}>{answer}</div>;
    };

    const AnswerLine = ({ dataProp, labels , theme }) => {

      return (
      <View style={styles.lineChart}>
        <LineChart
          dataProp={dataProp}
          labels={labels}          
          theme={theme}
        />
        </View>)
      };

    const AnswerBar = ({ dataProp, labels , theme }) => {

        return (
        <View style={styles.lineChart}>
          <BarChart
            dataProp={dataProp}
            labels={labels}
            theme={theme}
          />
          </View>)
        };

    const AnswerTable = ({ dataProp , theme }) => {

      const labels = Object.keys(dataProp[0]);

      return (
        <View>

          <Table 
            dataProp={dataProp}
            labels={labels}  
            theme={theme}
          />
        </View>
      )

    }
    
    const styles = StyleSheet.create({
        container: { flex: 1, paddingLeft: 30, paddingRight : 30, justifyContent: 'flex-start', borderColor : 'blue' , borderWidth : 2 },
        textBox : {width : '100%' , borderColor : 'red', borderWidth : 1 , flexDirection : 'column' , alignItems :  'center'},
        head: { height: 44, backgroundColor: 'darkblue' },
        message : {width : '100%' , color : 'black', borderWidth : 1 , flexDirection : 'column' , alignItems :  'center' , fontSize: 30 , textAlign : 'left', fontFamily: 'Electrolize'},
        answer : {width : '100%' , color : 'blue', borderWidth : 1 , flexDirection : 'column' , alignItems :  'center' , fontSize: 50 , textAlign : 'right', fontFamily: 'Electrolize'},
        headText: { fontSize: 10, fontWeight: 'bold' , textAlign: 'center', color: 'white' },
        text: { margin: 6, fontSize: 25, fontWeight: 'bold' , textAlign: 'center' },
        lineChart: { margin: 6, fontSize: 10, width: 1000, height : 500 , marginLeft : 'auto' , marginRight : 0 },
        table : {fontSize: 10 , flex : 1}
    })

export default ChatComponent