import React, { useState, useEffect } from 'react';
import { View, StyleSheet, Dimensions, Text, Image } from 'react-native'
import AppBarRN from '../Components/AppBarRN';
import TemporaryDrawer from "../Components/Drawer";
import LoadingComponent from '../Components/Loader';
import s3DailySummary from '../Data/s3DailySummary';
import ChartPanel from '../ChartBuilder/ChartPanel';
import moment from 'moment-timezone';
import parseDailySumFunction from '../Data/parseDailySumFunction';


const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;

const ChartBuilder = (props) => {

  const configArray = props.configArray
  const configHandler = configArray.configHandler
  const clientHandler = configArray.clientHandler
  const clientID = configArray.clientID
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const theme = props.theme
  const homeTitle = "Chart Builder";
  const currentDate2 = moment.tz('Europe/Malta');
  const yesterday2 = currentDate2.clone().subtract(1, 'day');
  const yesterdayProp = yesterday2.format('YYYY-MM-DD');

  const currentDate = new Date();
  currentDate.setMinutes(0);
  const previousDate = new Date(currentDate);
  previousDate.setDate(currentDate.getDate() - 30);
  previousDate.setDate(1)

  // Get the start date (e.g., the beginning of the current month)
  const startDate = new Date(previousDate.getFullYear(), previousDate.getMonth(), 1);

  // Get the end date (e.g., the end of the current month)
  const endDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);

  // Convert the start and end dates to strings in YYYY-MM-DD format
  const startDateString = previousDate.toISOString().slice(0, 10);
  const endDateString = endDate.toISOString().slice(0, 10);

  const stateBase = {
    top: false,
    left: false,
    bottom: false,
    right: false,
  };

  const [state, setState] = useState(stateBase);
  const anchor = 'left'

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });

  };

  function handleDrawer(anchor) {

    const stateChange = {
      top: false,
      left: true,
      bottom: false,
      right: false,
    };
    setState(stateChange)

  }

  const filterBase = {
    start: startDateString,
    end: yesterdayProp,
    channel: "Total",
    brand: 'Total',
    kw_type: "Total",
  };

  const [filters, setFilters] = useState(filterBase);

  var dataProp = []

  const propObjectBase = {
    data: dataProp,
    start: startDateString,
    end: yesterdayProp,
    theme: theme,
    brand: "Total",
    channel: "Total",
    kw_type: "Total",
  };

  const [propObject, setpropObject] = useState(propObjectBase);

  const handleStateChange = (prop) => {

    setpropObject(prop);

    const filterUpdate = {
      start: prop.start,
      end: prop.end,
      channel: prop.channel,
      brand: prop.brand,
      kw_type: prop.kw_type,
    };

    setFilters(filterUpdate)

  };

  async function fetchS3Data() {
    try {
      const data = await s3DailySummary(configArray);

      return data;
    } catch (error) {
      console.error('Error fetching data:', error);
      return null;
    }
  }

  useEffect(() => {
    fetchS3Data().then((result) => {
      setData(result);
      setLoading(false);
    });
  }, [clientID]);

  if (loading) {
    return (
      <LoadingComponent theme={theme} />
    );
  }

  dataProp = parseDailySumFunction(data.daily, filters)

  const getUniqueValues = (arr, key) => {
    const uniqueSet = new Set(arr.map(item => item[key]));
    return [...uniqueSet];
};

const barBrands = getUniqueValues(data.daily, 'brand');
const barChannels = getUniqueValues(data.daily, 'channel');
const barTypes = getUniqueValues(data.daily, 'kw_type');

  return (
   
    <View style={[styles.container, { backgroundColor: theme.backgroundColor }]}>
      <AppBarRN
        handleDrawer={handleDrawer}
        homeTitle={homeTitle}
        theme={theme}
        configHandler={configHandler}
        clientHandler={clientHandler}
      />
      <TemporaryDrawer
        toggleDrawer={toggleDrawer}
        state={state}
        anchor={anchor}
        theme={theme}
      />
      <ChartPanel
        theme={theme}
        windowHeight={windowHeight}
        dataProp={dataProp}
        propObject={propObject}
        handleStateChange={handleStateChange}
        barBrands={barBrands}
        barChannels={barChannels}
        barTypes={barTypes}
      >
      </ChartPanel>

    </View>


  )
}



const styles = StyleSheet.create({
  container: {
    borderWidth: 0,
    borderColor: 'yellow',
    paddingTop: 0,
    flexDirection: 'column',
    justifyContent: 'flex-start',
    width: '100%',
    height: '100%',
    flex: 1,
  },
  panel: {
    width: '100%'
  }
})

export default ChartBuilder