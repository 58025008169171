import React, { useEffect, useRef } from 'react';
import { Bar , Line} from 'react-chartjs-2';
import Chart from 'chart.js/auto';
import WebFont from 'webfontloader';


const MixedChart = (props) => {
  const chartRef = useRef(null);
  const dataProp = props.dataProp
  const labels = props.labels
  const theme = props.theme
  const metric1 = props.metric1
  const metric2 = props.metric2
  const agg_daily_result = props.agg_daily_result
  const dateLabels = props.dateLabels


  useEffect(() => {
    WebFont.load({
      google: {
        families: ['Electrolize']
      }
    });
   }, []);

  const filteredData = agg_daily_result.filter(item => item.brand !== "");

  const values1 = [metric1];  
  const values2 = [metric2];

  const filteredValuesArray1 = filteredData.flatMap((item) =>
  values1.map((field) => item[field])
);

const filteredValuesArray2 = filteredData.flatMap((item) =>
values2.map((field) => item[field])
);

  const data = {
    labels: dateLabels,
    datasets: [
      {
        type : 'line',
        label: metric1,
        data: filteredValuesArray1,
        yAxisID : 'A',
        borderColor: theme.color,
        lineTension: 0.5,
        radius: 0
      },
      {
        type : 'bar',
        label: metric2,
        data: filteredValuesArray2,
        yAxisID : 'B',
        borderColor: theme.color,
        backgroundColor: theme.backgroundColor2,
        lineTension: 0.5,
        radius: 0
      },
    ],
  };

  
const options = {
  maintainAspectRatio: false,
  scales: {
    A: {
      beginAtZero: true,
      type: 'linear',
      position: 'left',
      grid: {
        display: false, // Remove the gridlines on the y-axis
      },
      ticks: {
        color : theme.color,
        font: {
          size: 10, // Set the font size for y-axis labels
          weight: 'bold', // Set the font weight for y-axis labels
          family : 'Electrolize',
        },
      },
    },
    B: {
        beginAtZero: true,
        type: 'linear',
        position: 'right',
        grid: {
          display: false, // Remove the gridlines on the y-axis
        },
        ticks: {
          color : theme.color,
          font: {
            size: 10, // Set the font size for y-axis labels
            weight: 'bold', // Set the font weight for y-axis labels
            family : 'Electrolize',
          },
        },
      },
    x: {
      grid: {
        display: false, // Remove the gridlines on the x-axis
      },
      ticks: {
        color : theme.color,
        font: {
          size: 12, // Set the font size for x-axis labels
          family : 'Electrolize'
        },
      },
    },
  },
  plugins: {
    legend: {
      display: true,
      text: 'Chart Title',
      labels: {
        color : theme.color,
        // This more specific font property overrides the global property
        font: {
            size: 12,
            family : 'Electrolize'
        }
    }
    },
    tooltip: {
      enabled: false,
    },
    title: {
      display: false,
      text: 'Chart Title',
      font: {
        size: 24, // Set the font size for the chart title
        weight: 'bold', // Set the font weight for the chart title

      },
    },
  },
};

  return <Bar ref={chartRef} data={data} options={options} style={{fontFamily : 'Electrolize'}} />;
};

export default MixedChart;
