export default function parseAdRgoupFunction (prop, filters) {

    var dataset = prop;

    if (!dataset || dataset.length === undefined) {
      return null;
    }
  
        dataset = dataset.filter((obj) => {
          const date = new Date(obj.date);
          const startDate = new Date(filters.start);
          const endDate = new Date(filters.end);
          return (
            date >= startDate &&
            date <= endDate &&
            Object.entries(filters)
      
              .filter(([key, value]) => key !== 'start' && key !== 'end')
              .every(([key, value]) => {
                if (value === 'Total') {
                  return true;
                }
                if (key === 'brand') {
                  return obj[key] === value;
                }
                if (key === 'channel') {
                  return obj[key] === value;
                }
                if (key === 'kw_type') {
                  return obj[key] === value;
                }
      
                return false;
              })
          );
        });

        const  agg_result = dataset
        
     
        var agg_campaign_result = [];
        dataset.reduce(function (res, value) {
          if (!res[value.campaign]) {
            res[value.campaign] = {
              campaign: value.campaign,
              channel: value.channel,
              impressions: 0,
              clicks: 0,
              spend: 0,
              regs: 0,
              ftds: 0,
            };
            agg_campaign_result.push(res[value.campaign]);
          }
          res[value.campaign].impressions += parseInt(value.impressions);
          res[value.campaign].clicks += parseInt(value.clicks);
          res[value.campaign].spend += parseInt(value.spend);
          res[value.campaign].regs += parseInt(value.regs);
          res[value.campaign].ftds += parseInt(value.ftds);
      
          return res;
        }, {});
      
        agg_campaign_result = agg_campaign_result.map((obj) => {
          const spend = obj.spend;
          const ftds = obj.ftds;
          const cpa = spend === 0 || ftds === 0 || isNaN(ftds) ? 0 : spend / ftds;
          return {
            ...obj,
            cpa: parseInt(cpa),
          };
        });
      
        if (!agg_campaign_result) {
          return null;
        }
      
        var agg_adg_result = [];
        dataset.reduce(function (res, value) {
          if (!res[value.adgroup]) {
            res[value.adgroup] = {
                adgroup: value.adgroup,
                channel: value.channel,
              impressions: 0,
              clicks: 0,
              spend: 0,
              regs: 0,
              ftds: 0,
            };
            agg_adg_result.push(res[value.adgroup]);
          }
          res[value.adgroup].impressions += parseInt(value.impressions);
          res[value.adgroup].clicks += parseInt(value.clicks);
          res[value.adgroup].spend += parseInt(value.spend);
          res[value.adgroup].regs += parseInt(value.regs);
          res[value.adgroup].ftds += parseInt(value.ftds);
      
          return res;
        }, {});
      
        agg_adg_result = agg_adg_result.map((obj) => {
          const spend = obj.spend;
          const ftds = obj.ftds;
          const cpa = spend === 0 || ftds === 0 || isNaN(ftds) ? 0 : spend / ftds;
          return {
            ...obj,
            cpa: parseInt(cpa),
          };
        });

        var agg_channel_result = [];

      dataset.reduce(function (res, value) {
        if (!res[value.channel]) {
          res[value.channel] = {
            channel: value.channel,
            impressions: 0,
            clicks: 0,
            spend: 0,
            regs: 0,
            ftds: 0,
          };
          agg_channel_result.push(res[value.channel]);
        }
        res[value.channel].impressions += parseInt(value.impressions);
        res[value.channel].clicks += parseInt(value.clicks);
        res[value.channel].spend += parseInt(value.spend);
        res[value.channel].regs += parseInt(value.regs);
        res[value.channel].ftds += parseInt(value.ftds);
    
        return res;
      }, {});


  agg_channel_result = agg_channel_result.map((obj) => {
    const spend = obj.spend;
    const ftd = obj.ftds;
    const cpa = spend === 0 || ftd === 0 || isNaN(ftd) ? 0 : spend / ftd;
    return {
      ...obj,
      cpa: parseInt(cpa),
    };
  });

  var agg_brand_result = [];

  dataset.reduce(function (res, value) {
    if (!res[value.brand]) {
      res[value.brand] = {
        brand: value.brand,
        impressions: 0,
        clicks: 0,
        spend: 0,
        regs: 0,
        ftds: 0,
      };
      agg_brand_result.push(res[value.brand]);
    }
    res[value.brand].impressions += parseInt(value.impressions);
    res[value.brand].clicks += parseInt(value.clicks);
    res[value.brand].spend += parseInt(value.spend);
    res[value.brand].regs += parseInt(value.regs);
    res[value.brand].ftds += parseInt(value.ftds);

    return res;
  }, {});

  agg_brand_result = agg_brand_result.map((obj) => {
    const spend = obj.spend;
    const ftd = obj.ftds;
    const cpa = spend === 0 || ftd === 0 || isNaN(ftd) ? 0 : spend / ftd;
    return {
      ...obj,
      cpa: parseInt(cpa),
    };
  });

  var agg_type_result = [];
    
      dataset.reduce(function (res, value) {
        if (!res[value.kw_type]) {
          res[value.kw_type] = {
            kw_type: value.kw_type,
            impressions: 0,
            clicks: 0,
            spend: 0,
            regs: 0,
            ftds: 0,
          };
          agg_type_result.push(res[value.kw_type]);
        }
        res[value.kw_type].impressions += parseInt(value.impressions);
        res[value.kw_type].clicks += parseInt(value.clicks);
        res[value.kw_type].spend += parseInt(value.spend);
        res[value.kw_type].regs += parseInt(value.regs);
        res[value.kw_type].ftds += parseInt(value.ftds);
        return res;
      }, {});
    
      agg_type_result = agg_type_result.map((obj) => {
        const spend = obj.spend;
        const ftds = obj.ftds;
        const cpa = spend === 0 || ftds === 0 || isNaN(ftds) ? 0 : spend / ftds;
        return {
          ...obj,
          cpa: parseInt(cpa),
        };
      });
  
      
        return {
            agg_campaign_result,
            agg_adg_result,
            agg_result,
            agg_brand_result,
            agg_type_result,
            agg_channel_result
        }
      
      }