import React, { useState, useEffect } from 'react';
import {
    View, StyleSheet, Dimensions, Text, Pressable, ScrollView
} from 'react-native'
import WebFont from 'webfontloader';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import PPTviewer from './PPTviewer';

const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;


const PPTpanels = (props) => {

    const theme = props.theme
    const data = props.data.docs
    const [urlPass, seturlPass] = useState(null);
    const iconWidth = windowWidth * 0.03
    data.sort((a, b) => {
        const dateA = new Date(a.creation_date);
        const dateB = new Date(b.creation_date);
        return dateB - dateA;
      });

    const handlePress = (prop) => {

        seturlPass(prop)
    
      };


    useEffect(() => {
        WebFont.load({
            google: {
                families: ['Droid Sans', 'Electrolize']
            }
        });
    }, []);


    return (
        <View style={styles.container}>
            <View style={[styles.mainPanel , {borderWidth: 1, borderColor: theme.color}]}>
            <PPTviewer
                url={urlPass}
                theme={theme}
                />
            </View>
            <ScrollView style={styles.childPanel}>
            <View >
            
                <FileArray 
                    data={data} 
                    handlePress={handlePress}
                    theme={theme}
                    iconWidth={iconWidth}

                />
                
            </View>
            </ScrollView>

        </View>
    )


}

const FileArray = (props) => {

    const data = props.data
    const handlePress = props.handlePress
    const theme = props.theme
    const iconWidth = props.iconWidth

    return (
        <View>
        {data.map((item, index) => (            
            <Pressable style={{
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'row',
                borderWidth: 0.1,
                borderRadius: 5,
                borderColor: theme.color,
                width: '100%',
                height: '5%',
                }}
                key={index}
                onPress={() => handlePress(item.url)}
                >
                <View style={{
                    padding: '2%',
                    alignItems: 'center',
                    justifyContent: 'center',
                    
                }}>
                    <PictureAsPdfIcon
                        sx={{ color: theme.color, width: iconWidth, fontSize: 50 }}
                    />
                </View>
                <Text style={{
                    flex: 1,
                    fontSize: 20,
                    color: theme.color
                }}>
                    {item.filename}
                    </Text>
            </Pressable>
            ))}
        </View>

    )

}



const styles = StyleSheet.create({
    container: {
        borderWidth: 0,
        borderColor: 'blue',
        height: windowHeight * 0.931,
        width: '100%',
        flexDirection: 'row',
        fontFamily: 'Electrolize',
    },
    mainPanel: {
        borderWidth: 0,
        borderColor: 'yellow',
        height: '100%',
        width: '75%'
    },
    childPanel: {
        borderWidth: 0,
        borderColor: 'yellow',
        height: '100%',
        width: '25%',
    },
    fileIcons: {
        borderWidth: 0,
        borderColor: 'green',
        width: '100%',

        flexDirection: 'row',
        alignItems: 'center'
    },

})

export default PPTpanels