import React, { useState, useEffect } from 'react';
import {
    View,
    StyleSheet,
    Dimensions,
} from 'react-native'
import FilterPanel from '../Components/FilterPanel';
import WebFont from 'webfontloader';
import BudgetDataGrid from '../Budgets/BudgetDataGrid'

const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;

const BudgetPanelTable = (props) => {

    const dataProp = props.dataProp
    const agg_daily_result = dataProp.agg_daily_result
    const agg_type_result = dataProp.agg_type_result
    const agg_brand_result = dataProp.agg_brand_result
    const uniqueBrands = dataProp.uniqueBrands
    const uniqueChannels = dataProp.uniqueChannels
    const uniqueTypes = dataProp.uniqueTypes
    const theme = props.theme
    const handleStateChange = props.handleStateChange
    const propObject = props.propObject
    const currentLevel = dataProp.currentData
    console.log(props)
    


    useEffect(() => {
        WebFont.load({
            google: {
                families: ['Droid Sans', 'Electrolize']
            }
        });
    }, []);


    const panelMain = {

        width: windowWidth,
        height: '100%',
        borderWidth: 0.1,
        borderColor: 'grey',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        fontSize: 40,
        fontFamily: 'Electrolize',
        borderRadius: 5
    }

    return (
        <View style={styles.container}>
            <View style={{ flexDirection: 'row' }}>
                <View style={panelMain}>
                    <View style={{ width: '100%', flexDirection: 'flex-start', fontFamily: 'Electrolize' }}>
                        <FilterPanel
                            style={{
                                height: '10%',
                                borderColor: 'red',
                                borderWidth: 0,
                                width: '100%',
                                fontFamily: 'Electrolize'
                            }}
                            uniqueBrands={uniqueBrands}
                            uniqueChannels={uniqueChannels}
                            uniqueTypes={uniqueTypes}
                            handleStateChange={handleStateChange}
                            propObject={propObject}
                            theme={theme}
                        />
                    </View>
                        <BudgetDataGrid
                            dataProp={currentLevel}
                            theme={theme}
                        />


                </View>
            </View>

        </View>

    )

}

export default BudgetPanelTable

const styles = StyleSheet.create({
    container: {
        borderWidth: 0,
        borderColor: 'blue',
        height: '100%',
        width: '100%',
        flexDirection: 'column',
        fontFamily: 'Electrolize',


    }
})