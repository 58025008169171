import React, { useState, useEffect } from 'react';
import {
    View,
    StyleSheet,
    Dimensions,
    Text,
} from 'react-native'
import ProgTable from './ProgTable.js';
import LoadingComponent from '../Components/Loader.js';
import FilterPanel from '../Components/FilterPanel';
import { useCubeQuery } from '@cubejs-client/react';
import ProgCubeQuery from './ProgCubeQuery.js';
import WebFont from 'webfontloader';

const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;

const ProgPanels = (props) => {

    const uniqueBrands = props.barBrands
    const uniqueChannels = props.barChannels
    const uniqueTypes = props.barTypes
    const theme = props.theme
    const handleStateChange = props.handleStateChange
    const filters = props.filters
    const cubeTable = props.cubeTable
    const propObject = props.propObject
    const currentChannel = "Programmatic"
    const chartType = props.chartType

    const start = filters.start
    const end = filters.end
    const brand = (filters.brand === "Total") ? "null" : filters.brand;
    const channel = (filters.channel === "Total") ? "null" : filters.channel;
    const type = (filters.kw_type === "Total") ? "null" : filters.kw_type;

    const brandOp = (filters.brand === "Total") ? "notEquals" : "equals";
    const channelOp = (filters.channel === "Total") ? "notEquals" : "equals";
    const typeOp = (filters.kw_type === "Total") ? "notEquals" : "equals";

    useEffect(() => {
        WebFont.load({
            google: {
                families: ['Droid Sans', 'Electrolize']
            }
        });
    }, []);

    const cubeFilters = [
        {
            "member": `${cubeTable}.brand`,
            "operator": `${brandOp}`,
            "values": [
                `${brand}`
            ]
        },
        {
            "member": `${cubeTable}.channel`,
            "operator": `${channelOp}`,
            "values": [
                `${channel}`
            ]
        },
        {
            "member": `${cubeTable}.kw_type`,
            "operator": `${typeOp}`,
            "values": [
                `${type}`
            ]
        }
    ]

    const cubeQuery = ProgCubeQuery(cubeFilters , cubeTable, start, end, chartType)
    const { resultSet, isLoading, error, progress } = useCubeQuery(cubeQuery);

    if (!resultSet) {
        return (
            <LoadingComponent theme={theme} />
        );
    }

    const cubeResponse = resultSet.loadResponse.results[0].data

    const panelMain = {

        width: '100%',
        height: '100%',
        minHeight: windowHeight * 0.931,
        borderWidth: 0.1,
        borderColor: 'grey',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        fontSize: 40,
        fontFamily: 'Electrolize',
        borderRadius: 5
    }

    return (
        <View style={styles.container}>
            <View style={{ flexDirection: 'row' }}>
                <View style={panelMain}>
                    <View style={{ width: '100%', flexDirection: 'flex-start', fontFamily: 'Electrolize' }}>
                        <FilterPanel
                            style={{
                                height: '10%',
                                borderColor: 'red',
                                borderWidth: 0,
                                width: '100%',
                                fontFamily: 'Electrolize'
                            }}
                            uniqueBrands={uniqueBrands}
                            uniqueChannels={uniqueChannels}
                            uniqueTypes={uniqueTypes}
                            handleStateChange={handleStateChange}
                            propObject={propObject}
                            theme={theme}
                        />
                    </View>
                    <View style={{ flexDirection: 'row', flex: 1, width: '98%', borderColor: 'red', borderWidth: 0 }}>
                        <ProgTable
                            theme={theme}                                
                            dataProp={cubeResponse}
                            currentChannel={currentChannel}
                            cubeTable={cubeTable}
                            chartType={chartType}
                            />


                    </View>
                </View>

            </View>
        </View>
    )
}

export default ProgPanels

const styles = StyleSheet.create({
    container: {
        borderWidth: 0,
        borderColor: 'blue',
        height: '100%',
        width: '100%',
        flexDirection: 'column',
        fontFamily: 'Electrolize',


    },
    lineChart: {
        flex: 1,
        width: '100%',
        alignItems: 'center',
        padding: '1%',
        fontFamily: 'Electrolize'
    }
})