import React, { useState, useEffect } from 'react';
import { View, StyleSheet, Dimensions, Text, Image } from 'react-native'
import AppBarRN from '../Components/AppBarRN';
import TemporaryDrawer from "../Components/Drawer";
import LoadingComponent from '../Components/Loader'
import AttributionPanel from '../Attribution/AttributionPanel';
import moment from 'moment-timezone';
import { useCubeMeta } from '@cubejs-client/react';
import { useCubeQuery } from '@cubejs-client/react';
import AttributionCubeDimensions from '../Attribution/AttributionCubeDimensions';

const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;

const AttributionComparison = (props) => {

  const configArray = props.configArray
  const configHandler = configArray.configHandler
  const clientHandler = configArray.clientHandler
  const clientUpper = configArray.clientID
  const client = clientUpper.toLowerCase();
  const cubeTable = client + "_attribution_export"
  const theme = props.theme
  const homeTitle = "Attribution Comparison";
  const currentDate2 = moment.tz('Europe/Malta');
  const yesterday2 = currentDate2.clone().subtract(1, 'day');
  const yesterdayProp = yesterday2.format('YYYY-MM-DD');
  const cubeMeta = useCubeMeta();
  const chartType = "comparison"

  const currentDate = new Date();
  currentDate.setMinutes(0);
  const previousDate = new Date(currentDate);
  previousDate.setDate(currentDate.getDate() - 30);
  previousDate.setDate(1)

  // Get the start date (e.g., the beginning of the current month)
  const startDate = new Date(previousDate.getFullYear(), previousDate.getMonth(), 1);

  // Get the end date (e.g., the end of the current month)
  const endDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);

  // Convert the start and end dates to strings in YYYY-MM-DD format
  const startDateString = previousDate.toISOString().slice(0, 10);
  const endDateString = endDate.toISOString().slice(0, 10);

  const stateBase = {
    top: false,
    left: false,
    bottom: false,
    right: false,
  };

  const [state, setState] = useState(stateBase);
  const anchor = 'left'

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });

  };

  function handleDrawer(anchor) {

    const stateChange = {
      top: false,
      left: true,
      bottom: false,
      right: false,
    };
    setState(stateChange)

  }

  const filterBase = {
    start: startDateString,
    end: yesterdayProp,
    channel: "Total",
    brand: 'Total',
    kw_type: "Total",
  };

  const [filters, setFilters] = useState(filterBase);

  var dataProp = []

  const propObjectBase = {
    data: dataProp,
    start: startDateString,
    end: yesterdayProp,
    theme: theme,
    brand: "Total",
    channel: "Total",
    kw_type: "Total",
  };

  const [propObject, setpropObject] = useState(propObjectBase);

  const handleStateChange = (prop) => {

    setpropObject(prop);

    const filterUpdate = {
      start: prop.start,
      end: prop.end,
      channel: prop.channel,
      brand: prop.brand,
      kw_type: prop.kw_type,
    };

    setFilters(filterUpdate)

  };


  const uniqueDimensionQuery = AttributionCubeDimensions(cubeTable, startDateString, endDateString);
  const { resultSet, isLoading, error, progress } = useCubeQuery(uniqueDimensionQuery);

  if (!resultSet) {
    return (
      <LoadingComponent theme={theme} />
    );
  }

  if (!cubeMeta.response) {
    return (
      <LoadingComponent theme={theme} />
    );
  }

  const cubeMetaData = cubeMeta.response.cubesMap.rank_attribution_export
  const cubeDims = Object.keys(cubeMetaData.dimensions)
  const cubeMeasures = Object.keys(cubeMetaData.measures)
  const cubeResponse = resultSet.loadResponse.results[0].data

  const allowedDimensions = ["channel", "kw_type", "campaign", "adgroup"]; // Add the dimension names you want to allow

  const uniqueDimensions = cubeDims.map((dimensionName) => {
    const parts = dimensionName.split(".");
    if (parts.length === 2) {
      const dimensionValue = parts[1]; // Get the value after the dot
      if (allowedDimensions.includes(dimensionValue)) {
        return dimensionValue; // Return the dimension if it's in the allowed list
      } else {
        return null; // Return null if the dimension is not in the allowed list
      }
    } else {
      return dimensionName; // Keep the original value if there's no dot
    }
  }).filter((dimension) => dimension !== null);

  const uniqueMeasures = cubeMeasures.map((dimensionName) => {
    const parts = dimensionName.split(".");
    if (parts.length === 2) {
      return parts[1]; // Get the value after the dot
    } else {
      return dimensionName; // Keep the original value if there's no dot
    }
  });


  const getUniqueValues = (arr, key) => {
    const uniqueSet = new Set(arr.map(item => item[key]));
    return [...uniqueSet];
  };

  const barBrands = getUniqueValues(cubeResponse, 'rank_attribution_export.brand');
  const barChannels = getUniqueValues(cubeResponse, 'rank_attribution_export.channel');
  const barTypes = getUniqueValues(cubeResponse, 'rank_attribution_export.kw_type');

  return (
    <View style={[styles.container, { backgroundColor: theme.backgroundColor }]}>
      <AppBarRN
        handleDrawer={handleDrawer}
        homeTitle={homeTitle}
        theme={theme}
        configHandler={configHandler}
        clientHandler={clientHandler}
      />
      <TemporaryDrawer
        toggleDrawer={toggleDrawer}
        state={state}
        anchor={anchor}
        theme={theme}
      />
      <AttributionPanel
        theme={theme}
        windowHeight={windowHeight}
        dataProp={dataProp}
        propObject={propObject}
        handleStateChange={handleStateChange}
        barBrands={barBrands}
        barChannels={barChannels}
        barTypes={barTypes}
        filters={filters}
        uniqueDimensions={uniqueDimensions}
        chartType={chartType}
        cubeTable={cubeTable}
      >
      </AttributionPanel>

    </View>

  )
}



const styles = StyleSheet.create({
  container: {
    borderWidth: 0,
    borderColor: 'yellow',
    paddingTop: 0,
    flexDirection: 'column',
    justifyContent: 'flex-start',
    width: '100%',
    height: '100%',
    flex: 1,
  },
  panel: {
    width: '100%'
  }
})

export default AttributionComparison