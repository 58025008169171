export default function AttributionCubeDimensions (cubeTable, startDateString, endDateString) {

    return {
      "order": [
        [
          `${cubeTable}.ftd_custom`,
          "desc"
        ]
      ],
        "limit": 100,
        "dimensions": [
          `${cubeTable}.brand`,
          `${cubeTable}.channel`,
          `${cubeTable}.kw_type`,
      ],
      "timeDimensions": [
        {
          "dimension": `${cubeTable}.timestamp`,
          "dateRange": [
            startDateString,
            endDateString
          ]
        }
      ]
      }
}