import React, { useState } from 'react';
import {
    Modal,
    View,
    StyleSheet,
    Dimensions,
    Text,
    Pressable
} from 'react-native';
import Template1 from '../DashIcons/Template1';
import Template2 from '../DashIcons/Template2';
import Template3 from '../DashIcons/Template3';
import Template4 from '../DashIcons/Template4';
import TimelineIcon from '@mui/icons-material/Timeline';
import NotesIcon from '@mui/icons-material/Notes';

const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;

const DashTemplateModal = (props) => {

    const theme = props.theme
    const visible = props.visible
    const setTemplateVisible = props.setTemplateVisible
    const templateHandler = props.templateHandler
    const currentTemplate = props.currentTemplate
    const [pressedElement, setPressedElement] = useState(currentTemplate);
    const responsiveText = windowWidth * 0.07
    const responsiveTextSmall = windowWidth * 0.01
    const responsiveIcon = windowWidth * 0.01
    
    const templateArray = [1,2,3,4,5,6]

    function pressHandler (template) {
        templateHandler(template)
        setTemplateVisible(false)
    }

    const text = {
        fontFamily: 'Electrolize',
        fontSize: responsiveText,
        textAlign: 'center',
        color: theme.color,

    }

    const textSub = {
        fontFamily: 'Electrolize',
        fontSize: responsiveTextSmall,
        textAlign: 'center',
        color: theme.color,

    }

    const row = {
        height: '50%',
        width: '100%',
        flexDirection: 'row',
        borderColor: theme.color,
        borderWidth: 0,
        padding: '1%',        
    }

    const rowChildContainer = {
        height: '100%',
        width: '33.3333%',
        alignItems: 'center',
        justifyContent: 'center',
        alignItems: 'center',
        justifyContent: 'center'
    }

    const rowChild = {
        height: '90%',
        width: '90%',
        borderColor: theme.color2,
        borderWidth: 3,
        borderRadius: 10,
        backgroundColor: theme.panelBackground,
        borderColor: theme.color,
        borderWidth: 2
    }
    

    return (
        <Modal
            visible={visible}
            style={styles.modalParent}
            transparent={true}
        >
            <Pressable
                style={styles.modalContainer}
                onPress={() => setTemplateVisible(false)}
            >
                <View style={styles.modalMargin}/>
                <View style={[styles.modalWindow, {
                    backgroundColor: theme.backgroundColor,
                    borderColor: theme.backgroundColor2,
                    borderWidth: 2
                }]}>
            
            
                <View style={row}>
                    <Pressable style={rowChildContainer}
                    onPress={() => pressHandler(1)}>
                        <View style={rowChild}>
                            <Template1 theme={theme}/>
                        </View>

                    </Pressable>
                    <Pressable style={rowChildContainer}
                    onPress={() => pressHandler(2)}>
                        <View style={rowChild}>
                            <Template2 theme={theme}/>
                        </View>

                    </Pressable>
                    <Pressable style={rowChildContainer}
                    onPress={() => pressHandler(3)}>
                        <View style={rowChild}>
                        <Template3 theme={theme}/>
                            </View>
                    </Pressable>
                </View>
                <View style={row}>
                    <Pressable style={rowChildContainer}
                    onPress={() => pressHandler(4)}>
                        <View style={rowChild}>
                        <Template4 theme={theme}/>
                        </View>

                    </Pressable>
                    <Pressable style={rowChildContainer}>
                        <View style={rowChild}>
                        
                        </View>
                    </Pressable>
                    <Pressable style={rowChildContainer}>
                        <View style={rowChild}>
                        
                        </View>

                    </Pressable>
                </View>
                </View>


            </Pressable>


        </Modal>
    )

}



const styles = StyleSheet.create({
    modalParent: {
        padding: 20,
        borderRadius: 20,
    },
    modalContainer: {
        width: windowWidth * 1,
        height: windowHeight * 1,
        backgroundColor: 'rgba(0, 0, 0, 0.3)',
    },
    modalWindow: {
        marginTop: '3.3%',
        height: '95%',
        width: '100%',
        borderColor: 'white',
        borderWidth: 1,
        flexDirection: 'column',
        padding: '2%'

    },
    modalMargin: {
        height: '0%',
        width: '100%'
    }
});


export default DashTemplateModal