import React, { useEffect, useRef } from 'react';
import {
    View, StyleSheet, Dimensions, Text, Pressable
} from 'react-native'


const PPTviewer = (props) => {

    const url = props.url
    const theme = props.theme

    if (url === null) {
        return <SelectText theme={theme}/>;
      }

    return (
        <iframe src={url} title="PDF Viewer" width="100%" height="100%" />
    );
};

const SelectText = (props) => {

    const theme = props.theme

    return (
        <View style={styles.selectText}>
            <Text style={[styles.text , {color: theme.color}]}>
                Select a Presentation Document to View
            </Text>
        </View>
    )
}



const styles = StyleSheet.create({
    container: {
        borderWidth: 1,
        borderColor: 'blue',
        height: '100%',
        width: '100%',

        fontFamily: 'Electrolize',
    },
    selectText:{
        height: '100%',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center'
    },
    text: {
        fontSize: 40,
    }
})

export default PPTviewer