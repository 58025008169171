import React, { useState, useEffect } from 'react';
import {
    View,
    StyleSheet,
    Dimensions,
    Text,
} from 'react-native'
import MetricList from '../Components/Checkbox';
import TableMain from '../ChartsDesktop/TableMain.js'
import FilterPanel from './FilterPanel';
import PanelsText from './PanelsText';
import WebFont from 'webfontloader';

const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;

const PanelsTable = (props) => {

    const dataProp = props.dataProp
    const agg_result = dataProp.agg_result
    const agg_type_result = dataProp.agg_type_result
    const agg_daily_result = dataProp.agg_daily_result
    const uniqueBrands = dataProp.uniqueBrands
    const uniqueChannels = dataProp.uniqueChannels
    const uniqueTypes = dataProp.uniqueTypes
    const theme = props.theme
    const handleStateChange = props.handleStateChange
    const propObject = props.propObject
    console.log(props)


    const [currentSettings, setcurrentSettings] = useState(['brand', 'spend', 'ftds', 'cpa']);
    const [metricSettings, setMetricSettings] = useState([]);

    const handleCheckBoxChange = (event) => {
        setcurrentSettings(event)
      };

    useEffect(() => {
        WebFont.load({
            google: {
                families: ['Droid Sans', 'Electrolize']
            }
        });
    }, []);

    const panelMain = {

        width: windowWidth * 0.85,
        height: '100%',
        borderWidth: 0.1,
        borderColor: 'grey',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        fontSize: 40,
        fontFamily: 'Electrolize',
        borderRadius: 5
    }

    const largeRows = ((windowHeight / 50).toFixed(0))

    const inputArray = ['date']; // Replace with your input array

    const metricList = [
        {'key' : 'brand'},
        {'key' :  'channel'},
        {'key' :  'kw_type'},
        {'key' :  'impressions'},
        {'key' :  'clicks'},
        {'key' :  'spend'},
        {'key' :  'regs'},
        {'key' :  'ftds'},
        {'key' :  'cpa'},
        {'key' :  'cpr'},
        {'key' :  'ctr'},
        {'key' :  'cpc'}]

    

    return (
        <View style={styles.container}>
            <View style={{ flexDirection: 'row' }}>
                <View style={panelMain}>
                    <View style={{ width: '100%', flexDirection: 'flex-start', fontFamily: 'Electrolize' }}>
                        <FilterPanel
                            style={{
                                height: '10%',
                                borderColor: 'red',
                                borderWidth: 0,
                                width: '100%',
                                fontFamily: 'Electrolize'
                            }}
                            uniqueBrands={uniqueBrands}
                            uniqueChannels={uniqueChannels}
                            uniqueTypes={uniqueTypes}
                            handleStateChange={handleStateChange}
                            propObject={propObject}
                            theme={theme}
                        />
                    </View>
                    <View style={{ flexDirection: 'row', flex: 1, width: '98%', borderColor: 'red', borderWidth: 0 }}>
                        <TableMain
                            dataProp={agg_result}
                            columnWidth={0.03}
                            colNum={largeRows}
                            theme={theme}
                            metricList={metricList}
                            currentSettings={currentSettings}

                        />
                    </View>
                </View>
                <View style={{ width: '14.9%' , alignItems : 'center' }}>
                
                <MetricList
                    style={{ width: '100%'}}
                    theme={theme}
                    dataProp={dataProp}
                    metricList={metricList}
                    handleCheckBoxChange={handleCheckBoxChange}
                    currentSettings={currentSettings}
                />
                </View>
            </View>
        </View>

    )

}

export default PanelsTable

const styles = StyleSheet.create({
    container: {
        borderWidth: 0,
        borderColor: 'blue',
        height: '100%',
        minHeight: windowHeight * 0.931,
        width: windowWidth,
        flexDirection: 'column',
        fontFamily: 'Electrolize',


    },
    lineChart: {
        flex: 1,
        width: '100%',
        alignItems: 'center',
        padding: '1%',
        fontFamily: 'Electrolize'
    }
})