import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import clsx from 'clsx';
import { DataGrid } from '@mui/x-data-grid';
import { Dimensions } from 'react-native'
import WebFont from 'webfontloader';
const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;



export default function TableLarge(props) {

  const dataProp = props.dataProp
  const colNum = props.colNum
  const columnWidth = props.columnWidth
  const theme = props.theme

  useEffect(() => {
    WebFont.load({
      google: {
        families: ['Electrolize']
      }
    });
   }, []);

  const idRow = [{
    field: 'id', 
    headerName: 'ID', 
    width: 5, 
    flex: 1, 
    headerAlign: 'center', 
    headerClassName: 'table-header', 
    align: 'center' , 
    cellClassName : 'super-app-theme--cell'}]

    const minMaxValues = {};
    Object.keys(dataProp[0]).forEach((key) => {
      const values = dataProp.map((row) => row[key]);
      minMaxValues[key] = { min: Math.min(...values), max: Math.max(...values) };
    });

  const labels = Object.keys(dataProp[0]).map((key) => {
    return { 
    field: key, 
    headerName: key, 
    minWidth: windowWidth * columnWidth * 1.25, 
    flex: 1, 
    headerAlign: 'center', 
    headerClassName: 'table-header', 
    align: 'center' , 
    cellClassName: (params) => {
      if (params.value == null) {
        return '';
      }
      const { min, max } = minMaxValues[key];
      return clsx('super-app', {
        c10: params.value >= (max - min) * 0.9,
        c20: params.value >= (max - min) * 0.8 && params.value < (max - min) * 0.9,
        c30: params.value >= (max - min) * 0.7 && params.value < (max - min) * 0.8,
        c40: params.value >= (max - min) * 0.6 && params.value < (max - min) * 0.7,
        c50: params.value >= (max - min) * 0.5 && params.value < (max - min) * 0.6,
        c60: params.value >= (max - min) * 0.4 && params.value < (max - min) * 0.5,
        c70: params.value >= (max - min) * 0.3 && params.value < (max - min) * 0.4,
        c80: params.value >= (max - min) * 0.2 && params.value < (max - min) * 0.3,
        c90: params.value >= (max - min) * 0.1 && params.value < (max - min) * 0.2,
        c100: params.value >= (max - min) * 0 && params.value < (max - min) * 0.1,
        
      });
    },};
  });  
  const columnFix = [...idRow, ...labels];

  const rows = props.dataProp.map((row, index) => ({ id: index + 1, ...row, cellClassName: 'table-cells' }));

  const columns = columnFix
  
  return (
    <Box
    style={{
      width: '98.5%',
      fontSize: 50,
      color: 'black',
      fontFamily : 'Electrolize'
      
    }}
      sx={{
        width: '100%',
        
        '& .table-header': { headerAlign: 'center', align : 'center' , fontFamily : 'Electrolize' },
        '& .table-cells': { align: 'center' , fontFamily : 'Electrolize'},
        '& .super-app': { align: 'center' , fontFamily : 'Electrolize'},
        '& .super-app.c10': {
          backgroundColor: theme.gradc10,
          color: 'white',
          fontWeight: '600',
          fontFamily : 'Electrolize'
          
        },
        '& .super-app.c20': {
          backgroundColor: theme.gradc20,
          color: 'white',
          fontWeight: '100',
          fontFamily : 'Electrolize'
        },
        '& .super-app.c30': {
          backgroundColor: theme.gradc30,
          color: 'white',
          fontWeight: '100',
          fontFamily : 'Electrolize'
        },
        '& .super-app.c40': {
          backgroundColor: theme.gradc40,
          color: 'white',
          fontWeight: '100',
          fontFamily : 'Electrolize'
        },
        '& .super-app.c50': {
          backgroundColor: theme.gradc50,
          color: 'white',
          fontWeight: '100',
          fontFamily : 'Electrolize'
        },
        '& .super-app.c60': {
          backgroundColor: theme.gradc60,
          color: 'white',
          fontWeight: '100',
          fontFamily : 'Electrolize'
        },
        '& .super-app.c70': {
          backgroundColor: theme.gradc70,
          color: 'white',
          fontWeight: '100',
          fontFamily : 'Electrolize'
        },
        '& .super-app.c80': {
          backgroundColor: theme.gradc80,
          color: 'white',
          fontWeight: '100',
          fontFamily : 'Electrolize'
        },
        '& .super-app.c90': {
          backgroundColor: theme.gradc90,
          color: 'white',
          fontWeight: '100',
          fontFamily : 'Electrolize'
        },
        '& .super-app.c100': {
          backgroundColor: theme.gradc100,
          fontFamily : 'Electrolize',
          fontWeight: '100',
          color: 'white',
        },


      }}
    >
      <DataGrid 
        rows={rows}
        columns={columns} 
        initialState={{
          pagination: {
            paginationModel: { pageSize: parseInt(colNum) },
          },
        }}
        />
    </Box>
  );
}