export default function parseAnalyticsFunction(prop, filters) {

    var dataset = prop;



    if (!dataset || dataset.length === undefined) {
        return null;
    }

    dataset = dataset.filter((obj) => {
        const date = new Date(obj.month);
        const startDate = new Date(filters.start);
        const endDate = new Date(filters.end);
        return (
            date >= startDate &&
            date <= endDate &&
            Object.entries(filters)

                .filter(([key, value]) => key !== 'start' && key !== 'end')
                .every(([key, value]) => {
                    if (value === 'Total') {
                        return true;
                    }
                    if (key === 'brand') {
                        return obj[key] === value;
                    }
                    if (key === 'channel') {
                        return obj[key] === value;
                    }
                    if (key === 'kw_type') {
                        return obj[key] === value;
                    }

                    return false;
                })
        );
    });

    const agg_result = dataset

    var agg_daily_result = [];
    dataset.reduce(function (res, value) {
        if (!res[value.month]) {
            res[value.month] = {
                month: value.month,
                new_users: 0,
                sessions: 0,
                pageviews: 0,
                reg_start: 0,
                regs: 0,
                ftds: 0,
                ngr: 0,
                spend: 0
            };
            agg_daily_result.push(res[value.month]);
        }
        res[value.month].new_users += parseInt(value.new_users);
        res[value.month].sessions += parseInt(value.sessions);
        res[value.month].pageviews += parseInt(value.pageviews);
        res[value.month].reg_start += parseInt(value.reg_start);
        res[value.month].regs += parseInt(value.regs);
        res[value.month].ftds += parseInt(value.ftds);
        res[value.month].ngr += parseInt(value.ngr);
        res[value.month].spend += parseInt(value.spend);

        return res;
    }, {});

    agg_daily_result = agg_daily_result.map((obj) => {
        const spend = obj.spend;
        const ftds = obj.ftds;
        const cpa = spend === 0 || ftds === 0 || isNaN(ftds) ? 0 : spend / ftds;
        return {
            ...obj,
            cpa: parseInt(cpa),
        };
    });

    if (!agg_daily_result) {
        return null;
    }


    var agg_type_result = [];

    dataset.reduce(function (res, value) {
        if (!res[value.kw_type]) {
            res[value.kw_type] = {
                kw_type: value.kw_type,
                new_users: 0,
                sessions: 0,
                pageviews: 0,
                reg_start: 0,
                regs: 0,
                ftds: 0,
                ngr: 0,
                spend: 0
            };
            agg_type_result.push(res[value.kw_type]);
        }
        res[value.kw_type].new_users += parseInt(value.new_users);
        res[value.kw_type].sessions += parseInt(value.sessions);
        res[value.kw_type].pageviews += parseInt(value.pageviews);
        res[value.kw_type].reg_start += parseInt(value.reg_start);
        res[value.kw_type].regs += parseInt(value.regs);
        res[value.kw_type].ftds += parseInt(value.ftds);
        res[value.kw_type].ngr += parseInt(value.ngr);
        res[value.kw_type].spend += parseInt(value.spend);
        return res;
    }, {});

    agg_type_result = agg_type_result.map((obj) => {
        const spend = obj.spend;
        const ftds = obj.ftds;
        const cpa = spend === 0 || ftds === 0 || isNaN(ftds) ? 0 : spend / ftds;
        return {
            ...obj,
            cpa: parseInt(cpa),
        };
    });

    var agg_channel_result = [];

    dataset.reduce(function (res, value) {
        if (!res[value.channel]) {
            res[value.channel] = {
                channel: value.channel,
                new_users: 0,
                sessions: 0,
                pageviews: 0,
                reg_start: 0,
                regs: 0,
                ftds: 0,
                ngr: 0,
                spend: 0
            };
            agg_channel_result.push(res[value.channel]);
        }
        res[value.channel].new_users += parseInt(value.new_users);
        res[value.channel].sessions += parseInt(value.sessions);
        res[value.channel].pageviews += parseInt(value.pageviews);
        res[value.channel].reg_start += parseInt(value.reg_start);
        res[value.channel].regs += parseInt(value.regs);
        res[value.channel].ftds += parseInt(value.ftds);
        res[value.channel].ngr += parseInt(value.ngr);
        res[value.channel].spend += parseInt(value.spend);

        return res;
    }, {});


    agg_channel_result = agg_channel_result.map((obj) => {
        const spend = obj.spend;
        const ftd = obj.ftds;
        const cpa = spend === 0 || ftd === 0 || isNaN(ftd) ? 0 : spend / ftd;
        return {
            ...obj,
            cpa: parseInt(cpa),
        };
    });

    var agg_brand_result = [];

    dataset.reduce(function (res, value) {
        if (!res[value.brand]) {
            res[value.brand] = {
                brand: value.brand,
                new_users: 0,
                sessions: 0,
                pageviews: 0,
                reg_start: 0,
                regs: 0,
                ftds: 0,
                ngr: 0,
                spend: 0
            };
            agg_brand_result.push(res[value.brand]);
        }
        res[value.brand].new_users += parseInt(value.new_users);
        res[value.brand].sessions += parseInt(value.sessions);
        res[value.brand].pageviews += parseInt(value.pageviews);
        res[value.brand].reg_start += parseInt(value.reg_start);
        res[value.brand].regs += parseInt(value.regs);
        res[value.brand].ftds += parseInt(value.ftds);
        res[value.brand].ngr += parseInt(value.ngr);
        res[value.brand].spend += parseInt(value.spend);

        return res;
    }, {});

    agg_brand_result = agg_brand_result.map((obj) => {
        const spend = obj.spend;
        const ftd = obj.ftds;
        const cpa = spend === 0 || ftd === 0 || isNaN(ftd) ? 0 : spend / ftd;
        return {
            ...obj,
            cpa: parseInt(cpa),
        };
    });

    return {
        agg_daily_result,
        agg_type_result,
        agg_brand_result,
        agg_channel_result,
        agg_result,
    }
}