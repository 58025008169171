import React, {useEffect} from 'react';
import { Dimensions } from 'react-native'
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import WebFont from 'webfontloader';

const windowWidth = Dimensions.get('window').width;


export default function FilterBrand(props) {
  const [age, setAge] = React.useState('');
  const uniqueBrandRaw = props.uniqueBrands
  const total = "Total"
  const uniqueBrands = [total, ...uniqueBrandRaw];
  const filterWidth = windowWidth * 0.666 * 0.20
  const handleStateChange = props.handleStateChange
  const propObject = props.propObject
  const theme = props.theme

  useEffect(() => {
    WebFont.load({
      google: {
        families: ['Droid Sans', 'Electrolize']
      }
    });
   }, []);


  const handleChange = (event) => {
    setAge(event.target.value);
    handleUpdate(event.target.value)
  };

  const handleUpdate = (prop) =>{
  
  const propUpdate = {
    start: propObject.start,
    end: propObject.end,
    channel: propObject.channel,
    brand: prop,
    kw_type: propObject.kw_type,
  }

  handleStateChange(propUpdate)

}

const customStyles = {
  '& .css-6hp17o-MuiList-root-MuiMenu-list': {
    listStyle: 'none',
    margin: 0,
    padding: 0,
    position: 'relative',
    paddingTop: '0px',
    paddingBottom: '0px',
    outline: 0,
  },
};

  return (
    <Box sx={{ 
        width: '100%' , 
        height : '100%' , 
        fontFamily : 'Electrolize' , 
        color : theme.color,
        borderWidth : 0.1,
        borderColor: theme.backgroundColor2,
        }}>
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label" 
          style={{
            fontFamily : 'Electrolize', 
            color : theme.color,
            borderWidth : 1,
            borderColor: theme.backgroundColor2,
            
            }}>
            Brand
            </InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={age}          
          onChange={handleChange}
          MenuProps={{
            MenuListProps: {
              disablePadding: true
          }}}
            style={{
            width : '100%' , 
            left : 0, 
            fontFamily : 'Electrolize', 
            color : theme.backgroundColor2 , 
            backgroundColor : theme.backgroundColor,
            border: `1px solid ${theme.backgroundColor2}`,
            }}
        >
          {uniqueBrands.map((option, index) => (
          <MenuItem 
            key={index}
            style={{
              width : '100%', 
              fontFamily : 'Electrolize', 
              color : theme.color , 
              backgroundColor : theme.backgroundColor,
              borderWidth : 1,
              border: `1px solid grey`,
              }}
            value={option} >
            {option}
          </MenuItem>
        ))}
        </Select>
      </FormControl>
    </Box>
  );
}