import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import GoogleIcon from '@mui/icons-material/Google';
import FacebookIcon from '@mui/icons-material/Facebook';
import SavingsIcon from '@mui/icons-material/Savings';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import TableChartTwoToneIcon from '@mui/icons-material/TableChartTwoTone';
import PivotTableChartTwoToneIcon from '@mui/icons-material/PivotTableChartTwoTone';
import CalendarMonthTwoToneIcon from '@mui/icons-material/CalendarMonthTwoTone';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import SettingsTwoToneIcon from '@mui/icons-material/SettingsTwoTone';
import LogoutButton from '../Auth0/AuthLogout';
import AutoGraphTwoToneIcon from '@mui/icons-material/AutoGraphTwoTone';
import CampaignTwoToneIcon from '@mui/icons-material/CampaignTwoTone';
import DashboardCustomizeIcon from '@mui/icons-material/DashboardCustomize';

export default function TemporaryDrawer(props) {

  const anchor = props.anchor
  const state = props.state
  const toggleDrawer = props.toggleDrawer
  const theme = props.theme

  const client = (localStorage.getItem('client')) ?? 'Rank'

  const summaryList = [
    {
      parent: 'Summaries',
      text: 'Daily Summary',
      icon: <CalendarMonthTwoToneIcon
        sx={{ color: theme.backgroundColor2 }}
      />,
      link: '/daily',
    },
    {
      parent: 'Summaries',
      text: 'Monthly Summary',
      icon: <CalendarMonthTwoToneIcon
        sx={{ color: theme.backgroundColor2 }}
      />,
      link: '/monthly',
    },
    {
      parent: 'Summaries',
      text: 'YoY Summary',
      icon: <CalendarMonthTwoToneIcon
        sx={{ color: theme.backgroundColor2 }}
      />,
      link: '/yearly',
    },
    {
      parent: 'Summaries',
      text: 'Table Builder',
      icon: <TableChartTwoToneIcon
        sx={{ color: theme.backgroundColor2 }}
      />,
      link: '/table',
    },
    {
      parent: 'Summaries',
      text: 'Chart Builder',
      icon: <AutoGraphTwoToneIcon
        sx={{ color: theme.backgroundColor2 }}
      />,
      link: '/chart',
    },
    {
      parent: 'Summaries',
      text: 'Dash Builder',
      icon: <DashboardCustomizeIcon
        sx={{ color: theme.backgroundColor2 }}
      />,
      link: '/dash_custom',
    },
    {
      parent: 'Summaries',
      text: 'Cohorts ROAS',
      icon: <PivotTableChartTwoToneIcon
        sx={{ color: theme.backgroundColor2 }}
      />,
      link: '/cohorts',
    },
    {
      parent: 'Summaries',
      text: 'Chat',
      icon: <PivotTableChartTwoToneIcon
        sx={{ color: theme.backgroundColor2 }}
      />,
      link: '/chat',
    }
  ];

  const ppcList = [
    {
      parent: 'PPC',
      text: 'PPC Campaigns',
      icon: <GoogleIcon
        sx={{ color: theme.backgroundColor2 }}
      />,
      link: '/ppc_campaign',
    },
    {
      parent: 'PPC',
      text: 'AdGroups',
      icon: <GoogleIcon
        sx={{ color: theme.backgroundColor2 }}
      />,
      link: '/ppc_adgroup',
    },
    {
      parent: 'PPC',
      text: 'KW Density',
      icon: <GoogleIcon
        sx={{ color: theme.backgroundColor2 }}
      />,
      link: '/ppc_bubble',
    },
    {
      parent: 'PPC',
      text: 'KW Tree (WIP)',
      icon: <GoogleIcon
        sx={{ color: theme.backgroundColor2 }}
      />,
      link: '/ppc_tree',
    },
  ]

  const analyticsList = [
    {
      parent: 'GA4',
      text: 'GA4 Summary',
      icon: <AnalyticsIcon
        sx={{ color: theme.backgroundColor2 }}
      />,
      link: '/analytics',
    },
    {
      parent: 'GA4',
      text: 'GA4 Channels',
      icon: <AnalyticsIcon
        sx={{ color: theme.backgroundColor2 }}
      />,
      link: '/analytics_table',
    },
    {
      parent: 'GA4',
      text: 'GA4 Attribution',
      icon: <AnalyticsIcon
        sx={{ color: theme.backgroundColor2 }}
      />,
      link: '/attribution_comparison',
    },
    {
      parent: 'GA4',
      text: 'GA4 Dimensions',
      icon: <AnalyticsIcon
        sx={{ color: theme.backgroundColor2 }}
      />,
      link: '/attribution_dimensions',
    },
  ]


  const budgetList = [
    {
      parent: 'Budget',
      text: 'Budget Summary',
      icon: <SavingsIcon
        sx={{ color: theme.backgroundColor2 }}
      />,
      link: '/budget',
    },
    {
      parent: 'Budget',
      text: 'Budget Types',
      icon: <SavingsIcon
        sx={{ color: theme.backgroundColor2 }}
      />,
      link: '/budget_table',
    },
  ]

  const socialList = [
    {
      parent: 'Paid Social',
      text: 'Campaigns',
      icon: <FacebookIcon
        sx={{ color: theme.backgroundColor2 }}
      />,
      link: '/social_campaign',
    },
    {
      parent: 'Paid Social',
      text: 'AdSets',
      icon: <FacebookIcon
        sx={{ color: theme.backgroundColor2 }}
      />,
      link: '/social_adgroup',
    },
  ]

  const docList = [
    {
      parent: 'Docs Hub',
      text: 'Performance PPT',
      icon: <PictureAsPdfIcon
        sx={{ color: theme.backgroundColor2 }}
      />,
      link: '/ppt',
    },
  ]
  const progList = [
    {
      parent: 'Programmatic',
      text: 'DSPs',
      icon: <CampaignTwoToneIcon
        sx={{ color: theme.backgroundColor2 }}
      />,
      link: '/prog_channel',
    },
    {
      parent: 'Programmatic',
      text: 'Strategies',
      icon: <CampaignTwoToneIcon
        sx={{ color: theme.backgroundColor2 }}
      />,
      link: '/prog_type',
    },
    {
      parent: 'Programmatic',
      text: 'Publishers',
      icon: <CampaignTwoToneIcon
        sx={{ color: theme.backgroundColor2 }}
      />,
      link: '/prog_url',
    }
  ]

  const optionsList = [
    {
      parent: 'Options',
      text: 'User',
      icon: <SettingsTwoToneIcon
        sx={{ color: theme.backgroundColor2 }}
      />,
      link: '/options',
    },
  ]

  const dashList = [
    {
      parent: 'Custom Dashboards',
      text: 'Config',
      icon: <DashboardCustomizeIcon
        sx={{ color: theme.backgroundColor2 }}
      />,
      link: '/dash_custom',
    },
  ]

  if (client === 'AFUN') {
    var combinedList = [...summaryList, ...budgetList, ...progList, ...analyticsList, ...docList, ...optionsList];
  }

  if (client === 'Rank') {
  
    var combinedList = [...summaryList, ...budgetList, ...ppcList, ...socialList, ...analyticsList, ...docList, ...optionsList]
  }

  if (client === 'FM') {
    var combinedList = [...summaryList, ...budgetList, ...progList, ...analyticsList, ...docList, ...optionsList];
  }

  if (client === 'All_Clients') {
    var combinedList = [...summaryList, ...budgetList, ...optionsList];
  }


    
  const list = (anchor) => (
    <Box
      sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250, backgroundColor: theme.backgroundColor, color: theme.color , height : '100%' }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        {combinedList.map((item, index) => (
          <React.Fragment key={item.text}>
            {index === 0 || item.parent !== combinedList[index - 1].parent ? (
              <ListItem key={`parent-${item.parent}`} sx={{ paddingLeft: '1%' }}>
                <ListItemText primary={item.parent} />
              </ListItem>
            ) : null}
            <ListItem
              disablePadding
              button
              component="a"
              href={item.link}
            >
              <ListItemButton>
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText primary={item.text} />
              </ListItemButton>
            </ListItem>
            {index < combinedList.length - 1 &&
              item.parent !== combinedList[index + 1].parent ? (
              <Divider />
            ) : null}
          </React.Fragment>
        ))}
      </List>
      <Box sx={{ alignItems: 'center', justifyContent: 'center', width: '100%' }}>

        <LogoutButton
          sx={{ width: '100%', alignItems: 'center', justifyContent: 'center', padding: '2%' }}
        />

      </Box>
    </Box>
  );

  return (
    <div>

      <React.Fragment key={anchor}>
        <Drawer
          anchor={anchor}
          open={state[anchor]}
          onClose={toggleDrawer(anchor, false)}
        >
          {list(anchor)}
        </Drawer>
      </React.Fragment>

    </div>
  );
}