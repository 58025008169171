import React, { useState, useEffect } from 'react';
import {
    View,
    StyleSheet,
    Dimensions,
    Text,
    Pressable
} from 'react-native'
import TuneOutlinedIcon from '@mui/icons-material/TuneOutlined';
import DashTemplateModal from './DashTemplateModal';

const windowWidth = Dimensions.get('window').width;
const responsiveTextSmall = windowWidth * 0.009

const DashSelect = (props) => {

    const theme = props.theme
    const setCurrentDash = props.setCurrentDash
    const currentDash = props.currentDash
    const templateHandler = props.templateHandler
    const currentTemplate = props.currentTemplate
    const [pressedElement, setPressedElement] = useState(currentDash);
    const [templateVisible, setTemplateVisible] = useState(false)
    const panelWidth = 100/6
    const responsiveText = windowWidth * 0.015

    function dashHandler (prop) {
        setPressedElement(prop)
        setCurrentDash(prop)
    }

    const text = {
        fontFamily: 'Electrolize',
        fontSize: responsiveText,
        textAlign: 'center',
        color: theme.color,

    }

    const panel = {
        height: '100%',
        width: panelWidth+'%',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: theme.panelBackground,
        borderColor: 'white',
        borderWidth: 1
    }

    const selectedText = {
        fontFamily: 'Electrolize',
        fontSize: responsiveText,
        color: 'white'
    }

    const selectedPanel = {
        height: '100%',
        width: panelWidth+'%',
        alignItems: 'center',
        justifyContent: 'center',
        borderColor: theme.color,
        backgroundColor: theme.backgroundColor2,
        borderWidth: 5
    }

    const optionsContainer = {
        width: '5%',
        height: '10%',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'absolute',
        left: '93%',
        top: '10%',
    }

    const optionsButton = {
        color: 'white',
        fontSize: responsiveTextSmall,
    }


    return (
        <View style={styles.container}>
        
            <Pressable style={[panel, pressedElement === 'Dashboard 1' && selectedPanel]}
            onPress={() => dashHandler("Dashboard 1")}>
                <Text style={[text, pressedElement === 'Dashboard 1' && selectedText]}>Dash 1</Text>
                <Pressable style={optionsContainer}
                    onPress={() => setTemplateVisible(true)}>
                    <TuneOutlinedIcon style={optionsButton}/>
                </Pressable>
            </Pressable>

            <Pressable style={[panel, pressedElement === 'Dashboard 2' && selectedPanel]}
            onPress={() => dashHandler("Dashboard 2")}>
                <Text style={[text, pressedElement === 'Dashboard 2' && selectedText]}>Dash 2</Text>
                <Pressable style={optionsContainer}
                    onPress={() => setTemplateVisible(true)}>
                    <TuneOutlinedIcon style={optionsButton}/>
                </Pressable>
            </Pressable>

            <Pressable style={[panel, pressedElement === 'Dashboard 3' && selectedPanel]}
            onPress={() => dashHandler("Dashboard 3")}>
                <Text style={[text, pressedElement === 'Dashboard 3' && selectedText]}>Dash 3</Text>
                <Pressable style={optionsContainer}
                    onPress={() => setTemplateVisible(true)}>
                    <TuneOutlinedIcon style={optionsButton}/>
                </Pressable>
            </Pressable>

            <Pressable style={[panel, pressedElement === 'Dashboard 4' && selectedPanel]}
            onPress={() => dashHandler("Dashboard 4")}>
                <Text style={[text, pressedElement === 'Dashboard 4' && selectedText]}>Dash 4</Text>
                <Pressable style={optionsContainer}
                    onPress={() => setTemplateVisible(true)}>
                    <TuneOutlinedIcon style={optionsButton}/>
                </Pressable>
            </Pressable>

            <Pressable style={[panel, pressedElement === 'Dashboard 5' && selectedPanel]}
            onPress={() => dashHandler("Dashboard 5")}>
                <Text style={[text, pressedElement === 'Dashboard 5' && selectedText]}>Dash 5</Text>
                <Pressable style={optionsContainer}
                    onPress={() => setTemplateVisible(true)}>
                    <TuneOutlinedIcon style={optionsButton}/>
                </Pressable>
            </Pressable>

            <Pressable style={[panel, pressedElement === 'Dashboard 6' && selectedPanel]}
            onPress={() => dashHandler("Dashboard 6")}>
                <Text style={[text, pressedElement === 'Dashboard 6' && selectedText]}>Dash 6</Text>
                <Pressable style={optionsContainer}
                    onPress={() => setTemplateVisible(true)}>
                    <TuneOutlinedIcon style={optionsButton}/>
                </Pressable>
            </Pressable>
            <DashTemplateModal 
                visible={templateVisible}
                setTemplateVisible={setTemplateVisible}
                theme={theme}
                templateHandler={templateHandler}
                currentTemplate={currentTemplate}
            />
        </View>
    )

}

export default DashSelect

const styles = StyleSheet.create({
    container: {
        borderWidth: 0,
        borderColor: 'yellow',
        height: '7.5%',
        width: '100%',
        fontFamily: 'Electrolize',
        flexDirection: 'row',
        padding: 1,
    }
})