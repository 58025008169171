
import React, { useState, useEffect } from 'react';
import { View, StyleSheet, Dimensions, Text, Image } from 'react-native'
import WebFont from 'webfontloader';

const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;

const OptionsProfile = (props) => {

    const theme = props.theme
    const userProfile = props.userProfile
    const userRole = userProfile?.stack_user_metadata?.role || 'guest'
    const userClient = userProfile?.stack_user_metadata?.client || 'guest'

    useEffect(() => {
        WebFont.load({
            google: {
                families: ['Droid Sans', 'Electrolize']
            }
        });
    }, []);

    return (
        <View style={styles.container}>
            <View style={styles.textContainer}>
            <View style={styles.textSub}>
            <Text style={[styles.text , { color: theme.color }]}>Email</Text>
                <Text style={[styles.textHeader , { color: theme.color }]}>
                {userProfile.email}
                </Text>
                </View>
                <View style={styles.textSub}>
                <Text style={[styles.text , { color: theme.color }]}>Nickname</Text>
                <Text style={[styles.textHeader , { color: theme.color }]}>
                {userProfile.nickname}
                </Text>
                </View>
            </View>
            <View style={styles.textContainer}>
            <View style={styles.textSub}>
            <Text style={[styles.text , { color: theme.color }]}>Company</Text>
                <Text style={[styles.textHeader , { color: theme.color }]}>
                {userClient}
                </Text>
                </View>
                <View style={styles.textSub}>
                <Text style={[styles.text , { color: theme.color }]}>System Role</Text>
                <Text style={[styles.textHeader , { color: theme.color }]}>
                {userRole}
                </Text>
                </View>
            </View>
            <View style={styles.imageContainer}>
                <Image 
                    source={{uri : userProfile.picture }}
                    resizeMode='cover'
                    style={{width: '50%', height: '50%', borderRadius: 20}}
                    />
            </View>
        </View>
    )


}

const styles = StyleSheet.create({
    container: {
        borderWidth: 1,
        borderColor: 'grey',
        paddingTop: 0,
        flexDirection: 'row',
        justifyContent: 'center',
        width: windowWidth * 0.9,
        height: windowHeight * 0.3,

    },
    textContainer: {
        width: '37.5%',
        height: '100%',
        borderWidth: 0,
        borderColor: 'blue',
        justifyContent: 'center',
        alignItems: 'left',

        fontFamily : 'Electrolize',
        
        
    },
    imageContainer: {
        width: '25%',
        height: '100%',
        borderWidth: 0,
        borderColor: 'blue',
        justifyContent: 'center',
        alignItems: 'center'
    },
    text: {
        fontFamily : 'Electrolize',
        fontSize: 20
    },
    textHeader: {
        fontFamily : 'Electrolize',
        fontSize: 40
    },
    textSub: {
        height: '50%',
        width: '100%',
        borderWidth: 0,
        borderColor: 'blue',
        padding: '5%'
    }
})

export default OptionsProfile