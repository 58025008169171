export const themes = {
  white: {
    backgroundGradient : ['#ffffff', '#ffffff'],
    backgroundColor: 'white',
    backgroundColor2: '#006dee',
    color: '#0a2342',
    color2: 'white',
    borderColor : '#0a2342',
    borderColor2 : '#0a2342',
    panelBackground : 'rgba(0, 109, 238, 0.5)',
    panelBackground2 : 'rgba(0, 109, 238, 0.9)',
    panelTextColor: '#0a2342',
    panelTextShadow : '#fff',
    comboStopColor1 : 'rgb(5, 65, 244)',
    comboStopColor2 : 'rgb(66, 194, 244)',
    comboStopColor1ARRAY : [5, 65, 244],
    comboStopColor2ARRAY : [66, 194, 244],
    comboStroke : '#0a2342',
    buttonColor : 'rgba(255, 255, 255, 0.15)',
    buttonTextColor : '#0a2342',
    areaStopColor1 : 'rgb(5, 65, 244)',
    areaStopColor2 : 'rgb(66, 194, 244)',
    blockColor : '#0a2342',
    pieArray : ['#0541f4' , '#0069ff' , '#0087ff' , '#00acfe' , '#42c2f4'],
    selectedBackground : '#0a2342',
    selectedColor : 'white',
    selectedBorder : 'white',
    gradc10 : '#2253f5',
    gradc20 : '#0069fc',
    gradc30 : '#007bff',
    gradc40 : '#008cff',
    gradc50 : '#009cfe',
    gradc60 : '#3cabfa',
    gradc70 : '#61b9f6',
    gradc80 : '#83c6f1',
    gradc90 : '#a3d2ed',
    gradc100 : '#a3d2ed'

  },
    light: {
      backgroundGradient : ['#00d2ff', '#3a47d5'],
      backgroundColor: '#3F5EFB', 
      backgroundColor2: '#FC466B',
      color: 'white',
      color2: '#00d2ff',
      borderColor : 'white',
      borderColor2 : '#0a2342',
      panelBackground : 'rgba(255, 255, 255, 0.15)',
      panelBackground2 : 'rgba(255, 255, 255, 0.7)',
      panelTextColor: 'white',
      panelTextShadow : '#000000',
      comboStopColor1 : 'rgb(252, 70, 107)',
      comboStopColor2 : 'rgb(63, 94, 251)',
      comboStopColor1ARRAY : [252, 70, 107],
      comboStopColor2ARRAY : [63, 94, 251],
      comboStroke : 'white',
      buttonColor : 'rgba(255, 255, 255, 0.5)',
      buttonTextColor : '#0a2342',
      areaStopColor1 : 'rgb(252, 70, 107)',
      areaStopColor2 : 'rgb(63, 94, 251)',
      blockColor : 'rgba(255, 255, 255, 0.70)',
      pieArray : ['#fc466b' , '#f6308a' , '#e32dad' , '#a349e0' , '#3f5efb'],
      selectedBackground : '#0a2342',
      selectedColor : 'white',
      selectedBorder : 'white',
      gradc10 : '#fc466b',
      gradc20 : '#ff4278',
      gradc30 : '#ff4086',
      gradc40 : '#ff3e95',
      gradc50 : '#ff3fa5',
      gradc60 : '#ff41b5',
      gradc70 : '#ff46c6',
      gradc80 : '#ff4dd7',
      gradc90 : '#ff55e9',
      gradc100 : '#ff5efb'
      },
    dark: {
      backgroundGradient : ['#121212', '#121212'],
      backgroundColor: '#1E1E1F',
      backgroundColor2: '#0389ff',
      color: 'white',
      color2: 'white', 
      borderColor : 'white',
      borderColor2 : '#0a2342',
      panelBackground : 'rgba(255, 255, 255, 0.15)',
      panelBackground2 : 'rgba(255, 255, 255, 0.9)',
      panelTextColor: 'white',
      panelTextShadow : '#000000',
      comboStopColor1 : 'rgb(66, 194, 244)',
      comboStopColor2 : 'rgb(134, 65, 244)',
      comboStopColor1ARRAY : [66, 194, 244],
      comboStopColor2ARRAY : [134, 65, 244],
      comboStroke : '#3ad59f',
      buttonColor : '#0a2342',
      buttonTextColor : '#fff',
      areaStopColor1 : 'rgb(134, 65, 244)',
      areaStopColor2 : 'rgb(66, 194, 244)',
      blockColor : 'rgba(255, 255, 255, 0.15)',
      pieArray : ['#8641f4' , '#416fff' , '#008eff' , '#00a6ff' , '#42c2f4'],
      selectedBackground : 'rgba(255, 255, 255, 0.15)',
      selectedColor : 'white',
      selectedBorder : 'white',
      gradc10 : '#732dd9',
      gradc20 : '#6132c6',
      gradc30 : '#5134b1',
      gradc40 : '#44349d',
      gradc50 : '#393288',
      gradc60 : '#312f73',
      gradc70 : '#2b2c5d',
      gradc80 : '#272848',
      gradc90 : '#232333',
      gradc100 : '#1e1e1f'
  
    },
    
    black: {
      backgroundGradient : ['#17181c', '#17181c'],
      backgroundColor: 'black',
      backgroundColor2: '#FF194D',
      color: '#EAEBED',
      color2: '#EAEBED',
      borderColor : '#EAEBED',
      borderColor2 : '#EAEBED',
      panelBackground : 'rgba(255, 255, 255, 0.15)',
      panelBackground2 : 'rgba(0, 0, 0, 0.7)',
      panelTextColor: 'white',
      panelTextShadow : '#000000',
      comboStopColor1 : 'rgb(252, 70, 107)',
      comboStopColor2 : 'rgb(63, 94, 251)',
      comboStopColor1ARRAY : [252, 70, 107],
      comboStopColor2ARRAY : [63, 94, 251],
      comboStroke : '#3ad59f',
      buttonColor : 'rgba(255, 255, 255, 0.15)',
      buttonTextColor : '#fff',
      areaStopColor1 : 'rgb(252, 70, 107)',
      areaStopColor2 : 'rgb(63, 94, 251)',
      blockColor : 'rgb(252, 70, 107)',
      pieArray : ['#8641f4' , '#416fff' , '#008eff' , '#00a6ff' , '#42c2f4'],
      selectedBackground :  'rgba(255, 255, 255, 0.15)',
      selectedColor : 'white',
      selectedBorder : 'white',
      gradc10 : '#fc466b',
      gradc20 : '#ff4278',
      gradc30 : '#ff4086',
      gradc40 : '#ff3e95',
      gradc50 : '#ff3fa5',
      gradc60 : '#ff41b5',
      gradc70 : '#ff46c6',
      gradc80 : '#ff4dd7',
      gradc90 : '#ff55e9',
      gradc100 : '#ff5efb'
    },
    riot: {
      backgroundGradient : ['#17181C', '#17181C'],
      backgroundColor: '#17181C',
      backgroundColor2: '#17181C',
      color: '#FA4753',
      color2: '#FA4753',
      borderColor : '#EAEBED',
      borderColor2 : '#FA4753',
      panelBackground : 'rgba(255, 255, 255, 0.15)',
      panelBackground2 : 'rgba(255, 255, 255, 0.7)',
      panelTextColor: '#FA4753',
      panelTextShadow : '#000000',
      comboStopColor1 : 'rgb(197, 55, 107)',
      comboStopColor2 : 'rgb(226, 59, 97)',
      comboStopColor1ARRAY : [197, 55, 107],
      comboStopColor2ARRAY : [226, 59, 97],
      comboStroke : '#3ad59f',
      buttonColor : 'rgba(255, 255, 255, 0.15)',
      buttonTextColor : '#fff',
      areaStopColor1 : 'rgb(252, 70, 107)',
      areaStopColor2 : 'rgb(63, 94, 251)',
      blockColor : 'rgb(252, 70, 107)',
      pieArray : ['#fa4753' , '#e23b61' , '#a6366e' , '#86366c' , '#4b3057'],
      selectedBackground :  'rgba(255, 255, 255, 0.15)',
      selectedColor : '#FA4753',
      selectedBorder : '#FA4753',
      gradc10 : '#2253f5',
      gradc20 : '#0069fc',
      gradc30 : '#007bff',
      gradc40 : '#008cff',
      gradc50 : '#009cfe',
      gradc60 : '#3cabfa',
      gradc70 : '#61b9f6',
      gradc80 : '#83c6f1',
      gradc90 : '#a3d2ed',
      gradc100 : '#c3ddea'
    },
    gotham: {
      backgroundGradient : ['#243B67', '#4a549c'],
      backgroundColor: '#806bce',
      backgroundColor2: '#4a549c',
      color: 'white',
      color2: 'white',
      borderColor : 'white',
      borderColor2 : '#C27DFC',
      panelBackground : 'rgba(255, 255, 255, 0.15)',
      panelBackground2 : 'rgba(255, 255, 255, 0.7)',
      panelTextColor: '#C27DFC',
      panelTextShadow : '#000000',
      comboStopColor1 : 'rgb(36, 59, 103)',
      comboStopColor2 : 'rgb(194, 125, 252)',
      comboStopColor1ARRAY : [36, 59, 103],
      comboStopColor2ARRAY : [194, 125, 252],
      comboStroke : '#3ad59f',
      buttonColor : 'rgba(255, 255, 255, 0.15)',
      buttonTextColor : '#fff',
      areaStopColor1 : 'rgb(36, 59, 103)',
      areaStopColor2 : 'rgb(194, 125, 252)',
      blockColor : 'rgb(252, 70, 107)',
      pieArray : ['#8641f4' , '#416fff' , '#008eff' , '#00a6ff' , '#42c2f4'],
      selectedBackground :  'rgba(255, 255, 255, 0.15)',
      selectedColor : '#C27DFC',
      selectedBorder : '#C27DFC',
    gradc10 : '#2253f5',
    gradc20 : '#0069fc',
    gradc30 : '#007bff',
    gradc40 : '#008cff',
    gradc50 : '#009cfe',
    gradc60 : '#3cabfa',
    gradc70 : '#61b9f6',
    gradc80 : '#83c6f1',
    gradc90 : '#a3d2ed',
    gradc100 : 'black'
    },
  };