import React, { useState , useEffect } from 'react';
import { View , 
    StyleSheet , 
    Dimensions , 
    Text,
    Pressable
} from 'react-native'
import LineChart from '../ChartsDesktop/LineChart';
import PanelsText from '../Components/PanelsText';
import FilterPanel from '../Components/FilterPanel';
import TableLarge from '../Charts/TableLarge.js'
import BarChart from '../ChartsDesktop/BarChart';
import MixedChart from '../ChartsDesktop/MixedChart';
import WebFont from 'webfontloader';

const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;

const DailyPanels = (props) => {

    const dataProp = props.dataProp
    const agg_daily_result = dataProp.agg_daily_result
    const agg_type_result = dataProp.agg_type_result
    const agg_brand_result = dataProp.agg_brand_result
    const uniqueBrands = dataProp.uniqueBrands
    const uniqueChannels = dataProp.uniqueChannels
    const uniqueTypes = dataProp.uniqueTypes
    const dateLabels = props.dateLabels
    const currentData = agg_daily_result
    const timeFrame = props.timeFrame
    const [selectedComponent, setSelectedComponent] = useState("Row1_Line1");
    const theme = props.theme
    const handleStateChange = props.handleStateChange
    const propObject = props.propObject
    const barBrands = props.barBrands



    const handleComponentClick = (componentName) => {
        setSelectedComponent(componentName);
    };

    useEffect(() => {
        WebFont.load({
          google: {
            families: ['Droid Sans', 'Electrolize']
          }
        });
       }, []);

    const renderComponentContent = () => {
        if (selectedComponent === 'Row1_Line1') {
        return <Row1_Line1 style={panelMain}/>;
        } else if (selectedComponent === 'Row1_Line2') {
        return <Row1_Line2 style={panelMain}/>;
        } else if (selectedComponent === 'Row1_Line3') {
        return <Row1_Line3 style={panelMain}/>;
        } else if (selectedComponent === 'Row2_Bar1') {
        return <Row2_Bar1 style={panelMain}/>;
        } else if (selectedComponent === 'Row2_Mixed1') {
        return <Row2_Mixed1 style={panelMain}/>;
        } else if (selectedComponent === 'Row2_Bar2') {
        return <Row2_Bar2 style={panelMain}/>;
        } else {
        return null;
        }
    };


    const panelMain = {

        width : '66.666666666%',
        height : windowHeight * 0.65,
        borderWidth: 0.1,
        borderColor: 'grey',
        alignItems : 'center',
        justifyContent : 'center',
        textAlign : 'center',
        fontSize : 40,
        fontFamily : 'Electrolize',
        borderRadius : 5
    }
    
    const panelChild = {
        flex : 1,
        height : '100%',
        borderWidth: 0.1,
        borderColor: 'grey',
        alignItems : 'center',
        justifyContent : 'center',
        textAlign : 'center',
        fontSize : 20,
        fontFamily : 'Electrolize',
        borderRadius : 5,
    }


    const Row1_Line1 = () => {

        return (
        <View style={styles.lineChart}>
            <LineChart
                agg_daily_result={currentData}
                dateLabels={dateLabels}
                theme={theme}
                metric={"spend"}
                timeFrame={timeFrame}
            />
        </View>)
    }

    const Row1_Line2 = () => {

        return (
        <View style={styles.lineChart}>
            <LineChart
                agg_daily_result={currentData}
                dateLabels={dateLabels}
                theme={theme}
                metric={"ftds"}
                timeFrame={timeFrame}
            />
        </View>)
    }


    const Row1_Line3 = () => {

        return (
        <View style={styles.lineChart}>
            <LineChart
                agg_daily_result={currentData}
                dateLabels={dateLabels}
                theme={theme}
                metric={"cpa"}
                timeFrame={timeFrame}
            />
        </View>)
    }

    const Row2_Bar1 = () => {
        return(
        <View style={{flex : 1 , width : '100%', alignItems : 'center' , justifyContent : 'center'}}>
            <BarChart 
            dataProp={agg_brand_result}
            labels={barBrands}
            theme={theme}
            metric={'cpa'}
            timeFrame={timeFrame}
        />
        </View>)
    }

    const Row2_Mixed1 = () => {
        return(
        <View style={{flex : 1 , width : '100%', alignItems : 'center' , justifyContent : 'center'}}>
            <MixedChart 
            dataProp={agg_brand_result}
            labels={barBrands}
            theme={theme}
            agg_daily_result={currentData}
            dateLabels={dateLabels}
            metric1={'spend'}
            metric2={'ftds'}
            timeFrame={timeFrame}
        />
        </View>)
    }

    const Row2_Bar2 = () => {
        return(
            <View style={{flex : 1 , width : '100%', alignItems : 'center' , justifyContent : 'center'}}>
                <BarChart 
                dataProp={agg_brand_result}
                labels={barBrands}
                theme={theme}
                metric={'ftds'}
                timeFrame={timeFrame}
            />
            </View>)
        }

    return (
        <View style={styles.container}>
        <View style={{flexDirection : 'row' }}>
        <View style={panelMain}>
        <View style={{width : '100%', flexDirection : 'flex-start' , fontFamily : 'Electrolize'}}>
        <FilterPanel 
            style={{
                height : '10%', 
                borderColor : 'red', 
                borderWidth : 0, 
                width : '100%',
                fontFamily : 'Electrolize'}}
                uniqueBrands={uniqueBrands}
                uniqueChannels={uniqueChannels}
                uniqueTypes={uniqueTypes}
                handleStateChange={handleStateChange}
                propObject={propObject}
                theme={theme}
                />
                </View>
                
        {renderComponentContent()}        
        </View>
        <PanelsText 
            style={{width : '100%'}}
            theme={theme}
            dataProp={dataProp}
        />
        </View>
        <View style={{flexDirection : 'row', flex : 1}}>
        <Pressable style={panelChild} onPress={() => handleComponentClick('Row1_Line1')}>
        <View style={styles.lineChart}>
        <LineChart
            agg_daily_result={currentData}
            dateLabels={dateLabels}
            theme={theme}
            metric={"spend"}
            timeFrame={timeFrame}
        />
        </View>
        </Pressable>
        <Pressable style={panelChild} onPress={() => handleComponentClick('Row1_Line2')}>
        <View style={styles.lineChart}>
        <LineChart
            agg_daily_result={currentData}
            dateLabels={dateLabels}
            theme={theme}
            metric={"ftds"}
            timeFrame={timeFrame}
        />
        </View>
        </Pressable>
        <Pressable style={panelChild} onPress={() => handleComponentClick('Row1_Line3')}>
        <View style={styles.lineChart}>
        <LineChart
            agg_daily_result={currentData}
            dateLabels={dateLabels}
            theme={theme}
            metric={"cpa"}
            timeFrame={timeFrame}
        />
        </View>
        </Pressable>

        </View>
        
        <View style={{flexDirection : 'row', flex : 1}}>
        <Pressable style={panelChild} onPress={() => handleComponentClick('Row2_Bar1')}>
        <BarChart 
            dataProp={agg_brand_result}
            labels={barBrands}
            theme={theme}
            metric={'cpa'}
            timeFrame={timeFrame}
        />
        </Pressable>
        <Pressable style={panelChild} onPress={() => handleComponentClick('Row2_Mixed1')}>
        <MixedChart 
            dataProp={agg_brand_result}
            labels={barBrands}
            theme={theme}
            agg_daily_result={currentData}
            dateLabels={dateLabels}
            metric1={'spend'}
            metric2={'ftds'}
            timeFrame={timeFrame}
        />
        </Pressable>
        <Pressable style={panelChild} onPress={() => handleComponentClick('Row2_Bar2')}>
        <BarChart 
            dataProp={agg_brand_result}
            labels={barBrands}
            theme={theme}
            metric={'ftds'}
            timeFrame={timeFrame}
        />
        </Pressable>

        </View>
        
        </View>
        
    )

}

export default DailyPanels

const styles = StyleSheet.create({
    container: {
      borderWidth: 0,
      borderColor: 'blue',
      height : (windowHeight * 0.93) * 1.3,
      width : '100%',
      flexDirection : 'column',
      fontFamily : 'Electrolize',
      

    },
    lineChart : {
        flex : 1,
        width : '100%',
        alignItems : 'center',
        padding : '1%',
        fontFamily : 'Electrolize'
    }
})