import React, {useEffect} from 'react';
import { View, StyleSheet, Pressable, Text, Dimensions } from 'react-native'
import WebFont from 'webfontloader';

const windowWidth = Dimensions.get('window').width;
const responsiveText = windowWidth * 0.015

const ModalPanelChart = (props) => {

    const theme = props.theme
    const chartClickHandler = props.chartClickHandler
    const axisClickHandler = props.axisClickHandler
    const chartArray = ['Daily Line', 'Daily Bar', 'Monthly Line', 'Monthly Bar', 'Grouped Bar' , 'Mixed']

    useEffect(() => {
      WebFont.load({
          google: {
              families: ['Droid Sans', 'Electrolize']
          }
      });
  }, []);

    const containerRows = [];
    const rowItemsPerContainer = 2;

    for (let i = 0; i < chartArray.length; i += rowItemsPerContainer) {
        const rowItems = chartArray.slice(i, i + rowItemsPerContainer).map((item, index) => (
          <Pressable style={{flex: 1}}
            onPress={() => chartClickHandler(item)}
            key={index}
            >
          <View
            key={index}
            style={[styles.rowItem, { backgroundColor: theme.backgroundColor2,
                                      borderRadius: 10, 
                                      borderWidth: 5,
                                      borderColor: theme.backgroundColor }]}
          >
            <Text style={styles.text}>{item}</Text>
          </View>
          </Pressable>
        ));
    
        containerRows.push(
          <View key={i} style={styles.containerRow}>
            {rowItems}
          </View>
        );
      }
    
      return <View style={styles.container}>{containerRows}</View>;
};



const styles = StyleSheet.create({
    container: {
        borderWidth: 0,
        borderColor: 'yellow',
        paddingTop: 0,
        flexDirection: 'column',
        width: '100%',
        height: '93%',
    },
    containerRow: {
        width: '100%',
        height: '33%',
        flexDirection: 'row',

    },
    rowItem: {
        width: '100%',
        height: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        borderWidth: 0.1,
        borderColor: 'grey',
    },
    text: {
        color: 'white',
        fontSize: responsiveText,
        fontFamily: 'Electrolize',
    }
})

export default ModalPanelChart