import LoadingComponent from "../Components/Loader";

export default function parseKwFunction (prop, filters) {

    var dataset = prop;
    dataset = dataset.map(item => ({
      ...item,
      kw_type: item.campaign, // Rename the key here
      // Add other properties as needed
    }));

    if (!dataset || dataset.length === undefined) {
      return <LoadingComponent/>;
    }
    
    dataset = dataset.filter((obj) => {
        return Object.entries(filters)
        .filter(([key, value]) => key !== 'start' && key !== 'end')
          .every(([key, value]) => {
            if (value === 'Total') {
              return true;
            }
            if (key === 'brand') {
              return obj[key] === value;
            }
            if (key === 'channel') {
              return obj[key] === value;
            }
            if (key === 'kw_type') {
              return obj[key] === value;
            }
      
            return false;
          });
      });
        const  agg_result = dataset
        

        var agg_kw_result = [];
        dataset.reduce(function (res, value) {
          if (!res[value.keyword]) {
            res[value.keyword] = {
              keyword: value.keyword,
              kw_type: value.campaign,
              channel: value.channel,
              clicks: 0,
              spend: 0,
              ftds: 0,
            };
            agg_kw_result.push(res[value.keyword]);
          }

          res[value.keyword].clicks += parseInt(value.clicks);
          res[value.keyword].spend += parseInt(value.spend);
          res[value.keyword].ftds += parseInt(value.ftds);
      
          return res;
        }, {});
 
        agg_kw_result = agg_kw_result.map((obj) => {
          const spend = obj.spend;
          const ftds = obj.ftds;
          const cpa = spend === 0 || ftds === 0 || isNaN(ftds) ? 0 : spend / ftds;
          return {
            ...obj,
            cpa: parseInt(cpa),
          };
        });
      
        if (!agg_kw_result) {
          return null;
        }
              
        return {
            agg_kw_result,
            agg_result

        }
      
      }