import React, { useState, useEffect } from 'react';
import {
    View,
    StyleSheet,
    Dimensions,
        } from 'react-native'
import FilterPanel from '../../Components/FilterPanel';
import DashGraph from './DashGraph';
import DashMetrics from './DashMetrics';
import DashGraphChild from './DashGraphChild';


const windowWidth = Dimensions.get('window').width;
const responsiveTextSmall = windowWidth * 0.01
const panelWidth = (100/6)+'%'


const DashTemplate2 = (props) => {

    const theme = props.theme
    const [metricVisible, setMetricVisible] = useState(false)
    const [updateState, setUpdateState] = useState(null)
    const dashConfig = props.dashConfig
    const uniqueBrands = props.uniqueBrands
    const uniqueChannels = props.uniqueChannels
    const uniqueDimensions = props.uniqueDimensions
    const uniqueMetrics = props.uniqueMetrics
    const uniqueTypes = props.uniqueTypes
    const propObject = props.propObject
    const handleStateChange = props.handleStateChange
    const currentSettings = props.currentSettings
    const childGraphHandler = props.childGraphHandler
    const mainGraphHandler = props.mainGraphHandler
    const currentDash = props.currentDash
    const reportIndex = props.reportIndex
    const mainAgg = props.mainAgg
    const childAgg1 = props.childAgg1
    const childAgg2 = props.childAgg2
    const childAgg3 = props.childAgg3
    const childAgg4 = props.childAgg4
    const childAgg5 = props.childAgg5
    const childAgg6 = props.childAgg6
    const panelAgg1 = props.panelAgg1
    const panelAgg2 = props.panelAgg2
    const panelAgg3 = props.panelAgg3
    const panelAgg4 = props.panelAgg4
    const panelAgg5 = props.panelAgg5
    const panelAgg6 = props.panelAgg6
    const panel1 = dashConfig[reportIndex][currentDash].panel1
    const panel2 = dashConfig[reportIndex][currentDash].panel2
    const panel3 = dashConfig[reportIndex][currentDash].panel3
    const panel4 = dashConfig[reportIndex][currentDash].panel4
    const panel5 = dashConfig[reportIndex][currentDash].panel5
    const panel6 = dashConfig[reportIndex][currentDash].panel6

    return (
        <View style={styles.container}>
            <View style={styles.topRow}>
            <View style={{ width: '100%', flexDirection: 'flex-start', fontFamily: 'Electrolize' }}>
                        <FilterPanel
                            style={{
                                height: '10%',
                                borderColor: 'red',
                                borderWidth: 0,
                                width: '100%',
                                fontFamily: 'Electrolize'
                            }}
                            uniqueBrands={uniqueBrands}
                            uniqueChannels={uniqueChannels}
                            uniqueTypes={uniqueTypes}
                            handleStateChange={handleStateChange}
                            propObject={propObject}
                            theme={theme}
                        />
                    </View>
            </View>
            <View style={styles.panel}>
                <View style={styles.panelRow}>
                    <View style={styles.panelRowChild}>
                    <DashGraphChild
                        theme={theme}
                        mainAgg={childAgg1}
                        currentChild={'1'}
                        currentSettings={currentSettings}
                        childGraphHandler={childGraphHandler}
                        mainGraphHandler={mainGraphHandler}
                        currentDash={currentDash}
                        reportIndex={reportIndex}
                        dashConfig={dashConfig}
                        uniqueDimensions={uniqueDimensions}
                        uniqueMetrics={uniqueMetrics}
                        />

                    </View>
                    <View style={styles.panelRowChild}>
                    <DashGraphChild
                        theme={theme}
                        mainAgg={childAgg2}
                        currentChild={'2'}
                        currentSettings={currentSettings}
                        childGraphHandler={childGraphHandler}
                        mainGraphHandler={mainGraphHandler}
                        currentDash={currentDash}
                        reportIndex={reportIndex}
                        dashConfig={dashConfig}
                        uniqueDimensions={uniqueDimensions}
                        uniqueMetrics={uniqueMetrics}
                        />
                        
                    </View>
                    <View style={styles.panelRowChild}>
                    <DashGraphChild
                        theme={theme}
                        mainAgg={childAgg3}
                        currentChild={'3'}
                        currentSettings={currentSettings}
                        childGraphHandler={childGraphHandler}
                        mainGraphHandler={mainGraphHandler}
                        currentDash={currentDash}
                        reportIndex={reportIndex}
                        dashConfig={dashConfig}
                        uniqueDimensions={uniqueDimensions}
                        uniqueMetrics={uniqueMetrics}
                        />
                        
                    </View>

                </View>
                <View style={styles.panelRow}>
                    <View style={styles.panelRowChild}>
                    <DashGraphChild
                        theme={theme}
                        mainAgg={childAgg4}
                        currentChild={'4'}
                        currentSettings={currentSettings}
                        childGraphHandler={childGraphHandler}
                        mainGraphHandler={mainGraphHandler}
                        currentDash={currentDash}
                        reportIndex={reportIndex}
                        dashConfig={dashConfig}
                        uniqueDimensions={uniqueDimensions}
                        uniqueMetrics={uniqueMetrics}
                        />

                    </View>
                    <View style={styles.panelRowChild}>
                    <DashGraphChild
                        theme={theme}
                        mainAgg={childAgg5}
                        currentChild={'5'}
                        currentSettings={currentSettings}
                        childGraphHandler={childGraphHandler}
                        mainGraphHandler={mainGraphHandler}
                        currentDash={currentDash}
                        reportIndex={reportIndex}
                        dashConfig={dashConfig}
                        uniqueDimensions={uniqueDimensions}
                        uniqueMetrics={uniqueMetrics}
                        />
                        
                    </View>
                    <View style={styles.panelRowChild}>
                    <DashGraphChild
                        theme={theme}
                        mainAgg={childAgg6}
                        currentChild={'6'}
                        currentSettings={currentSettings}
                        childGraphHandler={childGraphHandler}
                        mainGraphHandler={mainGraphHandler}
                        currentDash={currentDash}
                        reportIndex={reportIndex}
                        dashConfig={dashConfig}
                        uniqueDimensions={uniqueDimensions}
                        uniqueMetrics={uniqueMetrics}
                        />
                        
                    </View>

                </View>

            </View>
            <View style={styles.bottomRow}>
            <View style={styles.panelContainerRowItem}>
                            <DashMetrics
                                currentPanel={panel1}
                                panelNumber={'panel1'}
                                mainGraphHandler={mainGraphHandler}
                                uniqueMetrics={uniqueMetrics}
                                uniqueDimensions={uniqueDimensions}
                                currentReport={currentDash}
                                reportIndex={reportIndex}
                                setVisible={setMetricVisible}
                                visible={metricVisible}
                                dashConfig={dashConfig}
                                theme={theme}
                                currentValue={(panelAgg1[0][currentSettings.panel1])}
                                currentLabel={currentSettings.panel1}
                                
                            />
                        </View>
                        <View style={styles.panelContainerRowItem}>
                        <DashMetrics
                                currentPanel={panel2}
                                panelNumber={'panel2'}
                                mainGraphHandler={mainGraphHandler}
                                uniqueMetrics={uniqueMetrics}
                                uniqueDimensions={uniqueDimensions}
                                currentReport={currentDash}
                                reportIndex={reportIndex}
                                setVisible={setMetricVisible}
                                visible={metricVisible}
                                dashConfig={dashConfig}
                                theme={theme}
                                currentValue={(panelAgg2[0][currentSettings.panel2])}
                                currentLabel={currentSettings.panel2}
                                
                            />
                        </View>
                        <View style={styles.panelContainerRowItem}>
                        <DashMetrics
                                currentPanel={panel3}
                                panelNumber={'panel3'}
                                mainGraphHandler={mainGraphHandler}
                                uniqueMetrics={uniqueMetrics}
                                uniqueDimensions={uniqueDimensions}
                                currentReport={currentDash}
                                reportIndex={reportIndex}
                                setVisible={setMetricVisible}
                                visible={metricVisible}
                                dashConfig={dashConfig}
                                theme={theme}
                                currentValue={(panelAgg3[0][currentSettings.panel3])}
                                currentLabel={currentSettings.panel3}
                                
                            />
                        </View>
                        <View style={styles.panelContainerRowItem}>
                        <DashMetrics
                                currentPanel={panel4}
                                panelNumber={'panel4'}
                                mainGraphHandler={mainGraphHandler}
                                uniqueMetrics={uniqueMetrics}
                                uniqueDimensions={uniqueDimensions}
                                currentReport={currentDash}
                                reportIndex={reportIndex}
                                setVisible={setMetricVisible}
                                visible={metricVisible}
                                dashConfig={dashConfig}
                                theme={theme}
                                currentValue={(panelAgg4[0][currentSettings.panel4])}
                                currentLabel={currentSettings.panel4}
                                
                            />
                        </View>
                        <View style={styles.panelContainerRowItem}>
                        <DashMetrics
                                currentPanel={panel5}
                                panelNumber={'panel5'}
                                mainGraphHandler={mainGraphHandler}
                                uniqueMetrics={uniqueMetrics}
                                uniqueDimensions={uniqueDimensions}
                                currentReport={currentDash}
                                reportIndex={reportIndex}
                                setVisible={setMetricVisible}
                                visible={metricVisible}
                                dashConfig={dashConfig}
                                theme={theme}
                                currentValue={(panelAgg5[0][currentSettings.panel5])}
                                currentLabel={currentSettings.panel5}
                                
                            />
                        </View>
                        <View style={styles.panelContainerRowItem}>
                        <DashMetrics
                                currentPanel={panel6}
                                panelNumber={'panel6'}
                                mainGraphHandler={mainGraphHandler}
                                uniqueMetrics={uniqueMetrics}
                                uniqueDimensions={uniqueDimensions}
                                currentReport={currentDash}
                                reportIndex={reportIndex}
                                setVisible={setMetricVisible}
                                visible={metricVisible}
                                dashConfig={dashConfig}
                                theme={theme}
                                currentValue={(panelAgg6[0][currentSettings.panel6])}
                                currentLabel={currentSettings.panel6}
                                
                            />
                        </View>
            </View>



            
        </View>
    )

}

export default DashTemplate2

const styles = StyleSheet.create({
    container: {
        borderWidth: 0,
        borderColor: 'red',
        height: '92.6%',
        width: '100%',
        fontFamily: 'Electrolize',
    },
    topRow: {
        width: '100%',
        height: '11%',
        flexDirection: 'column',
        borderColor: 'blue',
        borderWidth: 0
    },
    panel: {
        height: '70%',
        width: '100%',
        borderColor: 'blue',
        borderWidth: 0,
        flexDirection: 'column'
    },
    panelRow: {
        height: '50%',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'row'
    },
    panelRowChild: {
        height: '100%',
        width: '33.3333333%',
        borderWidth: 1,
        borderColor: 'grey',

    },
    bottomRow: {
        width: '100%',
        height: '18.8%',
        flexDirection: 'row',
        borderColor: 'blue',
        borderWidth: 0,
    },
    panelContainerRowItem: {
        width: panelWidth,
        height: '100%',
        borderWidth: 0,
        borderColor: 'grey',
        alignItems: 'center',
        justifyContent: 'center',
    }
})