import React, { useState } from 'react';
import {View, 
    Dimensions, 
    Text,
    Pressable } from 'react-native';

const windowWidth = Dimensions.get('window').width;
const responsiveTextSmall = windowWidth * 0.015

const DashChildAxis = (props) => {

    const theme = props.theme
    const mainGraphHandler = props.mainGraphHandler
    const uniqueDimensions = props.uniqueDimensions
    const currentChildName = props.currentChildName
    const currentChildAxis = props.currentChildxAxis
    const currentType = props.currentType
    const setVisible = props.setVisible
    const [pressedElement, setPressedElement] = useState(currentType);
    
    const handlePress = (type) => {
        setPressedElement(type);
        mainGraphHandler('xAxis', type, currentChildName)

      };

    const container = {
      height: '12%',
      width: '100%',
      flexDirection: 'row',
      marginTop: '2%',
      borderWidth: 6,
      borderColor: theme.color,
  }
  const typeItem = {
      height: '100%',
      width: '25%',
      flexDirection: 'column',
      borderWidth: 1,
      borderColor: theme.color,
      alignItems: 'center',
      justifyContent: 'center'
  }

  const text = {
      fontSize : responsiveTextSmall,
      fontFamily: 'Electrolize',
      textAlign: 'center',
      color: theme.color
  }

  const selectedChild = {
      borderWidth: 1,
      borderColor: theme.color,
      backgroundColor: theme.backgroundColor2,
      alignItems: 'center',
      justifyContent: 'center'
    }
  const selectedText = {
      color: theme.color2, // Change this to your desired text color
      fontSize : responsiveTextSmall,
      fontFamily: 'Electrolize',
      textAlign: 'center',
    }

    return (
        <View style={container}>
      {uniqueDimensions.map((item) => (
        <Pressable
          key={item}
          style={[
            typeItem,
            pressedElement === item && selectedChild,
          ]}
          onPress={() => handlePress(item)}
        >
          <Text
            style={[
              text,
              pressedElement === item && selectedText,
            ]}
          >
            {item}
          </Text>
        </Pressable>
      ))}
    </View>
    )
}

export default DashChildAxis