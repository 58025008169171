import React, { useState } from 'react';
import { View, StyleSheet, Dimensions } from 'react-native'
import AttributionBar from './AttributionBar';

const AttributionPanelLarge = (props) => {

    const theme = props.theme
    const cubeResponse = props.cubeResponse
    const chartType = props.chartType
    const cubeTable = props.cubeTable

    return (
        <View style={styles.container}>
        <AttributionBar
            theme={theme}
            cubeResponse={cubeResponse}
            chartType={chartType}
            cubeTable={cubeTable}
            />
        
        </View>
    )

}

const styles = StyleSheet.create({
    container: {
        borderWidth: 0,
        borderColor: 'grey',
        paddingTop: 0,
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        padding: '2%',
        alignItems: 'center',
        justfiyContent: 'center',
    },
})

export default AttributionPanelLarge