
import React, { useContext , useEffect } from 'react';
import { View, StyleSheet, Dimensions, Text, Image, Pressable } from 'react-native'
import WebFont from 'webfontloader';
import { themes } from '../themes'


const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;

const OptionsTheme = (props) => {

    const theme = props.theme
    const configHandler = props.configHandler
    const lightMode = themes.light.backgroundColor
    const darkMode = themes.dark.backgroundColor
    const whiteMode = themes.white.backgroundColor
    const blackMode = themes.black.backgroundColor

    useEffect(() => {
        WebFont.load({
            google: {
                families: ['Droid Sans', 'Electrolize']
            }
        });
    }, []);


    return (
        <View style={styles.container}>
            <View style={styles.textContainer}>

            <Text style={[styles.text , {color: theme.color}]}>
                View Mode:
            </Text>

            </View>
            <View style={styles.themeContainer}>
                <View style={styles.themeRow}>
                    <View style={[styles.themeRowChild , {backgroundColor: lightMode}]}>
                        <Pressable
                        onPress={() => configHandler("light")}
                        >
                            <Text style={[styles.text , {color: 'white'}]}> Light Mode</Text>
                        </Pressable>

                    </View>
                    <View style={[styles.themeRowChild , {backgroundColor: darkMode}]}>
                        <Pressable
                        onPress={() => configHandler("dark")}
                        >
                            <Text style={[styles.text , {color: 'white'}]}> Night Mode</Text>
                        </Pressable>

                    </View>

                </View>
                <View style={styles.themeRow}>
                    <View style={[styles.themeRowChild , {backgroundColor: whiteMode}]}>
                        <Pressable
                        onPress={() => configHandler("white")}
                        >
                            <Text style={[styles.text , {color: 'black'}]}> Day Mode</Text>
                        </Pressable>

                    </View>
                    <View style={[styles.themeRowChild , {backgroundColor: blackMode}]}>
                        <Pressable
                        onPress={() => configHandler("black")}
                        >
                            <Text style={[styles.text , {color: 'white'}]}> Contrast Mode</Text>
                        </Pressable>

                    </View>

                </View>


            </View>

        </View>
    )

}

export default OptionsTheme

const styles = StyleSheet.create({
    container: {
        borderWidth: 1,
        borderColor: 'grey',
        paddingTop: 0,
        flexDirection: 'row',
        justifyContent: 'center',
        width: windowWidth * 0.9,
        height: windowHeight * 0.2,

    },
    text: {
        fontFamily : 'Electrolize',
        fontSize: 40
    },
    textContainer: {
        width: '40%',
        height: '100%',
        borderWidth: 0,
        borderColor: 'yellow',
        paddingLeft: '1.5%',
        justifyContent: 'center'
    },
    themeContainer: {
        width: '60%',
        height: '100%',
        borderWidth: 0,
        borderColor: 'yellow',
        flexDirection: 'column',
    },
    themeRow: {
        width: '100%',
        height: '50%',
        borderWidth: 0,
        borderColor: 'yellow',
        flexDirection: 'row',
    },
    themeRowChild: {
        width: '50%',
        height: '100%',
        borderWidth: 1,
        borderColor: 'grey',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 0
    }
})