export default function parseCohortsFunction (prop, filters) {

    var dataset = prop
    

    if (!dataset || dataset.length === undefined) {
      return null;
    }
  
   var cohort_dataset = prop
  
    cohort_dataset = cohort_dataset.filter((obj) => {
      const brandFilter = filters.brand;
      const channelFilter = filters.channel; // Add channel filter
      const typeFilter = filters.kw_type; // Add type filter
  
      return (
        (brandFilter === 'Total' || obj.brand === brandFilter) && // Brand filter
        (channelFilter === 'Total' || obj.channel === channelFilter) && // Channel filter
        (typeFilter === 'Total' || obj.kwtype === typeFilter)
      );
    });
  
    const aggregationResult = {};
  
  // Iterate over the JSON data and aggregate values based on "cohort_month" and "months_since_ftd" dimensions
  cohort_dataset.forEach((item) => {
    const { cohort_month, months_from_ftd, ngr, cost } = item;
    const key = `${cohort_month}_${months_from_ftd}`;
  
    if (!aggregationResult[key]) {
      aggregationResult[key] = {
        cohort_month,
        months_from_ftd,
        ngr: 0,
        cost: 0
      };
    }
  
    aggregationResult[key].ngr += parseInt(ngr);
    aggregationResult[key].cost += parseInt(cost);
  });
  
  // Convert the aggregation result object to an array
  const aggregatedData = Object.values(aggregationResult);
  
  aggregatedData.sort((a, b) => {
    if (a.cohort_month === b.cohort_month) {
      return a.months_since_ftd - b.months_since_ftd;
    }
    return a.cohort_month.localeCompare(b.cohort_month);
  });
  
  // Calculate running values within each cohort_month
  let running_ngr = 0;
let running_cost = 0;
let running_roas = 0;
const result = aggregatedData.map((row, index, array) => {
  if (index === 0 || row.cohort_month !== array[index - 1].cohort_month) {
    running_ngr = 0;
    running_cost = 0; // Reset running total for a new cohort_month
  }
  running_ngr += row.ngr;
  running_cost += row.cost;

  // Check if running_cost is zero and set a default value for ROAS
  running_roas = running_cost === 0 ? 0 : (parseFloat((running_ngr / running_cost)) * 100).toFixed(0);

  return { ...row, running_ngr, running_cost, running_roas };
});
  
  const filteredData = result.map(({ cohort_month, months_from_ftd, ngr, running_ngr, running_roas }) => ({
    cohort_month,
    months_from_ftd,
    ngr,
    running_ngr,
    running_roas
  }));
  
  const agg_cohorts = filteredData
  console.log(agg_cohorts)

        return {
            agg_cohorts,
            dataset
        }

 
      
      }