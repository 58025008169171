export default function AttributionCubeSingle (props , cubeFilters, cubeTable, start, end) {

    const xAxis = props.xAxis

    const xAxisLower = xAxis.toLowerCase().replace(/ /g, '_');

        return {
            "measures": [
                `${cubeTable}.ftd_custom`,
            ],
            "order": [
                [
                  `${cubeTable}.ftd_custom`,
                  "desc"
                ]
              ],
            "dimensions": [
                `${cubeTable}.${xAxisLower}`
            ],
            "limit": 100,
            "timeDimensions": [
                {
                  "dimension": `${cubeTable}.timestamp`,
                  "dateRange": [
                    start,
                    end
                  ]
                }
              ],
            "filters": cubeFilters
        }
    }