import React, { useState, useEffect } from 'react';
import  AWS  from 'aws-sdk';
import { setDailyData } from '../Redux/dataReducer';
import store from '../Redux/configureStore';

const s3Budget = async (props) => {


  AWS.config.update({
    accessKeyId: 'AKIA5VQVRAUKYCUSHUM3',
    secretAccessKey: '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',
    region: 'eu-central-1'
  });
  
  const s3 = new AWS.S3();
  const clientID = props.clientID
  const clientLower = clientID.toLowerCase();
  
  const inputArray = [
    { name: 'budget', key: clientID+'/budget.json' },  
  ];
    const output = {};
  
  for (const object of inputArray) {
    const params = {
      Bucket: 'stack-visualisation',
      Key: object.key
    };
  
    try {
      
      const s3Response = await s3.getObject(params).promise();
  
  
      const content = s3Response.Body.toString();
      const parsedContent = JSON.parse(content);
  
      // Store the parsed content in the output object using the object's name as the key
      output[object.name] = parsedContent;

  
  
      
    } catch (error) {
      console.log(`Error retrieving object ${object.name}:`, error);
    }
  }
   
  store.dispatch(setDailyData(output))

  return(output)
  
  
  }



export default s3Budget

